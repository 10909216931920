import React, {useState, useImperativeHandle, useCallback, useRef, useEffect} from 'react'
import {Button, Col, Descriptions, Divider, Drawer, Form, Input, Row, Select, Space, Table, Tabs} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import {RightOutlined} from '@ant-design/icons'
import {downloadBlob, getImg} from '@/utils/util'
import {lessorModel} from '@/store/models/Lessor'
import {stewardModel} from '@/store/models/Steward'
import {useCodeSync} from '@/store/models/Common'
import LookImage from '@/pages/client/components/LookImage'
import DeleteServiceBill from '@/pages/client/components/DeleteServiceBill'
import AuditBill from '@/pages/client/components/AuditBill'
import EditBill from '@/pages/client/components/EditBill'
import ConfirmBill from '@/pages/client/components/ConfirmBill'
import {usePermission} from '@/components/Permission'
import {useAppHistory} from '@/common/hooks/useRouter'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Album} from '@/components/Album'

function _ServiceBillInfo({onSuccess}, ref) {
  const history = useAppHistory()
  const [auth] = usePermission()
  const [visible, setVisible] = useState(false)
  const [propertyList, setPropertyList] = useState([] as LessorBillPropertyVO[])
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [teamList, setTeamList] = useState([] as PairModel[])
  const [districtList, setDistrictList] = useState([] as PairModel[])
  const [propertyTitle, setPropertyTitle] = useState('')
  const [infoActiveKey, setInfoActiveKey] = useState('1')
  const [billInfo, setBillInfo] = useState({} as LessorBillVO)
  const [billId, setBillId] = useState('')
  const billStatusCode = useCodeSync('lessor.bill')
  const [form] = Form.useForm()
  const propertyLoading = lessorModel.userLessorBillPropertyList.useLoading()
  const imgRef = useRef(null)
  const deleteRef = useRef(null)
  const auditRef = useRef(null)
  const editRef = useRef(null)
  const confirmRef = useRef(null)

  const queryPropertyList = useCallback(() => {
    const values = form.getFieldsValue(true)
    lessorModel.userLessorBillPropertyList({...values, billId}).then(({response: {data, total}}) => {
      setPropertyList(data)
      setPropertyTotal(total)
    })
  }, [form, billId])

  useEffect(() => {
    if (billId) queryPropertyList()
  }, [billId, queryPropertyList])

  const queryBillInfo = useCallback((id: string) => {
    lessorModel.userLessorBillInfo({billId: id}).then(({response: {data}}) => {
      setBillInfo(data)
    })
  }, [])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
      setInfoActiveKey('1')
    },
    onSetInfo: ({billType, stewardOrderId, billId}: LessorBillVO) => {
      setBillId(billId)
      if (billType === '2' && stewardOrderId) {
        stewardModel.stewardOrderDetail({orderId: stewardOrderId}).then(({response: {data}}) => {
          setPropertyTitle(data?.propertyAddr)
        })
      } else {
        lessorModel.userLessorBillPropertyTeamList(billId).then(({response: {data}}) => {
          setTeamList(data)
        })
        lessorModel.userLessorBillPropertyDistrictList(billId).then(({response: {data}}) => {
          setDistrictList(data)
        })
      }
      queryBillInfo(billId)
    },
  }))

  function operation(info: LessorBillVO, key: string) {
    const operate = [
      {
        when: ['1', '5'].includes(info.billStatus) && auth('002003002'),
        title: '编辑',
        onClick: () => {
          editRef.current?.onDisplay()
          editRef.current?.onSetInfo(info)
        },
      },
      {
        when: (info.billStatus === '1' || (info.billStatus === '4' && info.billType === '2')) && auth('008003002'),
        title: '确认收款',
        onClick: () => {
          confirmRef.current.onDisplay()
          confirmRef.current.onSetInfo(info)
        },
      },
      {
        when: info.billStatus === '2',
        title: '审核',
        onClick: () => {
          auditRef.current.onDisplay()
          auditRef.current.onSetInfo(info)
        },
      },
      {
        when: auth('004003002') && info.billStatus !== '3',
        title: '删除',
        onClick: () => {
          deleteRef.current.onDisplay()
          deleteRef.current.onSetInfo(billId, billName)
        },
      },
    ]

    const {billName, billId} = info
    if (key === '1') {
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {operate
            .filter(value => value.when)
            .map((item, index) => (
              <div
                key={`${item.title} - ${index}`}
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
              >
                {index !== 0 && <Divider type={'vertical'} />}
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={event => {
                    event.stopPropagation()
                    item.onClick()
                  }}
                >
                  {item.title}
                </Button>
              </div>
            ))}
        </div>
      )
    } else {
      return (
        <Button
          type={'link'}
          onClick={() => {
            downloadBlob({
              url: '/user/lessor/bill/property-list-excel',
              body: {...form.getFieldsValue(true), billId},
            })
          }}
        >
          导出
        </Button>
      )
    }
  }

  return (
    <Drawer
      open={visible}
      onClose={() => {
        setVisible(false)
        form.resetFields()
      }}
      destroyOnClose
      width={700}
    >
      <div>
        <span style={{fontSize: '16', fontWeight: 600, color: '#000000'}}>账单详情</span>
        <Tabs
          activeKey={infoActiveKey}
          onChange={key => setInfoActiveKey(key)}
          tabBarExtraContent={operation(billInfo, infoActiveKey)}
        >
          <Tabs.TabPane tab={'基本信息'} key={'1'}>
            <DividerLine title={'基本信息'} bottom={15} top={10} />
            <Descriptions column={2}>
              <Descriptions.Item label={'账单状态'}>{billStatusCode[billInfo.billStatus]}</Descriptions.Item>
              <Descriptions.Item label={'账单名称'}>{billInfo.billName}</Descriptions.Item>
              <Descriptions.Item label={'账单类型'}>
                {billInfo.billType === '2' ? '额外服务' : '基础服务'}
              </Descriptions.Item>
              {billInfo.billType === '2' && propertyTitle && (
                <>
                  <Descriptions.Item label={'房源地址'}>{propertyTitle}</Descriptions.Item>
                  <Descriptions.Item label={'服务单号'}>
                    <Space align={'start'}>
                      <Button
                        style={{padding: 0, height: 22}}
                        type={'link'}
                        onClick={() => {
                          history.push({pathname: '/steward/detail', state: {orderId: billInfo.stewardOrderId}})
                          setVisible(false)
                        }}
                      >
                        {`${billInfo.stewardOrderId}`}
                      </Button>
                      <RightOutlined style={{color: '#00B7AE', fontSize: 12}} />
                    </Space>
                  </Descriptions.Item>
                </>
              )}
              {['1', '3'].includes(billInfo.billType) && (
                <Descriptions.Item label={'账单周期'}>{`${billInfo.billBegin}至${billInfo.billEnd}`}</Descriptions.Item>
              )}
              <Descriptions.Item label={'账单生成日期'}>{billInfo.billCreateTime}</Descriptions.Item>
              <Descriptions.Item label={'应收金额'}>{`${billInfo.billAmtTotal}元`}</Descriptions.Item>
              <Descriptions.Item label={'应收款日'}>{billInfo.billDate}</Descriptions.Item>
              {/* todo: 查看新增最后付款日 */}
              <Descriptions.Item label={'最后付款日'}>{billInfo.lastPayDate ?? '/'}</Descriptions.Item>
              {billInfo.billType === '1' && (
                <Descriptions.Item label={'备注原因'}>{billInfo.remark ?? '/'}</Descriptions.Item>
              )}
              {['2', '3'].includes(billInfo.billStatus) && (
                <>
                  <Descriptions.Item label={'收款金额'}>{`${billInfo.billPayAmt}元`}</Descriptions.Item>
                  <Descriptions.Item label={'收款方式'}>{billInfo.billPayWayName ?? '/'}</Descriptions.Item>
                  <Descriptions.Item label={'收款日期'}>{billInfo.billPayTime ?? '/'}</Descriptions.Item>
                </>
              )}
            </Descriptions>
            {billInfo.billStatus === '3' && (
              <Descriptions column={1}>
                <Descriptions.Item label={'交易订单号'}>{billInfo.billPayBatchId ?? '/'}</Descriptions.Item>
              </Descriptions>
            )}
            {['2', '3'].includes(billInfo.billStatus) && (
              <Descriptions column={1}>
                <Descriptions.Item label={'备注'}>{billInfo.billDetailRemark ?? '/'}</Descriptions.Item>
                <Descriptions.Item label={'照片凭证'}>
                  {billInfo.imgUrl ? (
                    <Album disabled fileList={billInfo.imgUrl.split(',').map(item => ({url: getImg(item), uid: item}))}>
                      {null}
                    </Album>
                  ) : (
                    '/'
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Tabs.TabPane>
          {['1', '3'].includes(billInfo.billType) && (
            <Tabs.TabPane tab={'房源信息'} key={'2'} forceRender>
              <div style={{marginTop: 20}}>
                <Form
                  form={form}
                  onFinish={queryPropertyList}
                  onReset={queryPropertyList}
                  initialValues={{pageNum: 1, pageSize: 10}}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item name={'propertyCode'} label={'房源code'}>
                        <Input placeholder={'请输入'} style={{maxWidth: 200}} allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={'districtCode'} label={'所属区域'}>
                        <Select placeholder={'请选择'} style={{maxWidth: 200}} allowClear>
                          {districtList.map(item => (
                            <Select.Option key={item.key} value={item.key}>
                              {item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={'teamId'} label={'所属团队'}>
                        <Select placeholder={'请选择'} style={{maxWidth: 200}} allowClear>
                          {teamList.map(item => (
                            <Select.Option key={item.key} value={item.key}>
                              {item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name={'propertyTitle'} label={'房源地址'}>
                        <Input placeholder={'请输入'} style={{maxWidth: 200}} allowClear />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={'end'}>
                    <Space>
                      <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                        查询
                      </Button>
                      <Button htmlType={'reset'}>重置</Button>
                    </Space>
                  </Row>
                </Form>
                <DividerLine
                  title={'房源详情'}
                  bottom={15}
                  right={
                    <div style={{marginLeft: 20}}>
                      <span>房源总数：</span>
                      <span style={{color: '#00B7AE'}}>{propertyTotal}</span>
                    </div>
                  }
                />
                <Table
                  dataSource={propertyList}
                  rowKey={'propertyCode'}
                  loading={propertyLoading}
                  columns={[
                    {
                      title: '房源code',
                      dataIndex: 'propertyCode',
                      key: 'propertyCode',
                      width: 120,
                    },
                    {
                      title: '所属团队',
                      dataIndex: 'teamName',
                      key: 'teamName',
                      width: 120,
                    },
                    {
                      title: '所属区域',
                      dataIndex: 'districtName',
                      key: 'districtName',
                      width: 120,
                    },
                    {
                      title: '房源标题',
                      dataIndex: 'propertyTitle',
                      key: 'propertyTitle',
                    },
                  ]}
                  pagination={getPaginationProps(form, propertyTotal)}
                />
              </div>
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>

      {/* 查看图片 */}
      <LookImage ref={imgRef} />

      {/* 删除 */}
      <DeleteServiceBill
        ref={deleteRef}
        onSuccess={() => {
          setVisible(false)
          onSuccess()
        }}
      />

      {/* 审核 */}
      <AuditBill
        ref={auditRef}
        onSuccess={() => {
          queryBillInfo(billId)
          onSuccess()
        }}
      />

      {/* 编辑 */}
      <EditBill
        ref={editRef}
        onSuccess={() => {
          queryBillInfo(billId)
          onSuccess()
        }}
      />

      {/* 确认收款 */}
      <ConfirmBill
        ref={confirmRef}
        onSuccess={() => {
          queryBillInfo(billId)
          onSuccess()
        }}
      />
    </Drawer>
  )
}

const ServiceBillInfo = React.forwardRef(_ServiceBillInfo)
export default ServiceBillInfo
