import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Table, Typography} from 'antd'
import {PlusCircleTwoTone, CloseCircleTwoTone, PlusOutlined} from '@ant-design/icons'
import {storageModel} from '@/store/models/Storage'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {downloadBlob} from '@/utils/util'
import {Breadcrumb} from '@/components/Breadcrumb'
import {userModel} from '@/store/models/User'
import {Permission, usePermission} from '@/components/Permission'
import {AddAddress} from '@/pages/oa/component/AddAdress'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {Page} from '@/components/Page'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import PurchaseDetail from '@/pages/oa/PurchaseManagement/components/PurchaseDetail'

const {Option} = Select
const {RangePicker} = DatePicker
interface AddressProp {
  id: string
  label: string
}

export default function PurchaseManagement() {
  const location = useAppLocation<'/purchase'>()
  const history = useAppHistory()

  const purchaseStatus = location.state?.purchaseStatus
  const purchaseIdState = location.state?.purchaseId

  const [auth] = usePermission()
  const userInfo = userModel.useData(data => data.userInfo)
  const personalRight = auth('001061001')

  // 采购状态码表
  const purchaseStatusCode = useCodeSync('purchase.status')
  const purchaseStatusArray = useCodeArraySync('purchase.status')

  const addressTypeArr = useCodeArraySync('purchase.addressType')

  // 采购申请表单
  const [insert, setInsert] = useState(false)
  const [purForm] = Form.useForm()
  // 站点列表
  const [wareList, setWareList] = useState<StewardWarehouseListItemVO[]>([])
  // 耗材列表
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])
  // 模板列表
  const [templateList, setTemplateList] = useState<PurchaseTemplateVO[]>([])

  const [form] = Form.useForm()
  // 申请列表
  const [total, setTotal] = useState(0)
  const [tableList, setTableList] = useState<PurchaseVO[]>([])

  const addRef = useRef(null)
  const [addressList, setAddressList] = useState([] as PurchaseAddressVO[])
  const [selectAddressList, setSelectAddressList] = useState([] as AddressProp[])
  const [searchList, setSearchList] = useState([] as AddressProp[])

  // todo: loading
  const tableLoading = storageModel.purchaseList.useLoading()
  const materialLoading = storageModel.stewardWarehouseMaterialList.useLoading()
  const insertLoading = storageModel.purchaseInsert.useLoading()

  const [cityList, setCityList] = useState<CityDataProps[]>([])

  const [purchaseId, setPurchaseId] = useState<string>('')

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  // 采购申请列表
  const queryPurchaseList = useCallback(() => {
    const {_applyTime, ...params} = form.getFieldsValue(true)
    const [applyTimeBegin, applyTimeEnd] = _applyTime ?? []
    storageModel.purchaseList({...params, applyTimeBegin, applyTimeEnd}).then(({response: {data, total}}) => {
      setTotal(total)
      setTableList(data)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    // 进入页面才设置，可清除，不作为默认值
    if (!purchaseStatus && !purchaseIdState) {
      form.setFieldsValue({
        _applyTime: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
      })
    } else {
      form.setFieldsValue({purchaseStatus, purchaseId: purchaseIdState})
    }
    form.submit()
  }, [form, purchaseIdState, purchaseStatus])

  // 站点列表
  useEffect(function () {
    storageModel
      .stewardWarehouseList({pageable: false, pageNum: 1, pageSize: 1, status: '1'})
      .then(({response: {data}}) => {
        setWareList(data)
      })
  }, [])

  // 收货地址列表
  const queryAddressList = useCallback(() => {
    storageModel.purchaseAddressList({pageable: false} as PurchaseAddressQueryDTO).then(({response: {data}}) => {
      setAddressList(data)
    })
  }, [])

  useEffect(() => {
    queryAddressList()
  }, [queryAddressList])

  // 耗材列表
  function onMaterialSearch(value) {
    storageModel
      .stewardWarehouseMaterialList({pageable: false, pageNum: 1, pageSize: 100, name: value})
      .then(({response: {data}}) => {
        // console.log('material data =>', data)
        setMaterialList(data)
      })
  }

  useEffect(() => {
    storageModel.purchaseTemplateList().then(({response: {data}}) => setTemplateList(data))
  }, [])

  // todo: function
  // 采购申请
  async function onPurchaseInsert() {
    const insertParams = purForm.getFieldsValue(true) as PurchaseInsertDTO
    // console.log('insertParams', insertParams)
    await storageModel.purchaseInsert(insertParams)
    setInsert(false)
    form.submit()
    purForm.resetFields()
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('007025001') && (
                <Button
                  onClick={async () => {
                    await form.validateFields()
                    const {_applyTime, ...values} = form.getFieldsValue(true)
                    const [applyTimeBegin, applyTimeEnd] = _applyTime
                    downloadBlob({
                      url: '/purchase/list-export',
                      body: {
                        ...values,
                        applyTimeBegin,
                        applyTimeEnd,
                        pageable: false,
                      },
                    })
                  }}
                >
                  导出
                </Button>
              )}
              {auth('001079001') && (
                <Button type={'primary'} onClick={() => history.push({pathname: '/purchase/template-setting'})}>
                  模板设置
                </Button>
              )}
              {auth('003026001') && (
                <Button type={'primary'} onClick={() => setInsert(true)}>
                  采购申请
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      {/* 筛选表单 */}
      <div className={commonStyles.filterSection}>
        <Form
          labelCol={{flex: '100px'}}
          form={form}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            applicantId: personalRight ? userInfo.userId : undefined,
            // _applyTime: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
          }}
          onReset={queryPurchaseList}
          onFinish={queryPurchaseList}
        >
          <Row gutter={36}>
            <Col span={8}>
              <Form.Item name={'purchaseId'} label={'采购ID'}>
                <Input placeholder={'请输入采购ID'} allowClear />
              </Form.Item>
            </Col>
            {!personalRight && (
              <Col span={8}>
                <Form.Item name={'applicantName'} label={'申请人'}>
                  <Input placeholder={'请输入申请人姓名'} allowClear />
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item name={'purchaseDesc'} label={'采购物品'}>
                <Input placeholder={'请输入采购物品'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'purchaseStatus'} label={'采购状态'}>
                <Select placeholder={'请选择'} allowClear>
                  {purchaseStatusArray.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'_applyTime'}
                label={'申请时间'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [applyTimeBegin, applyTimeEnd] = dateStrings
                  if (applyTimeBegin && applyTimeEnd) {
                    return [
                      moment(applyTimeBegin).format(FORMAT_DATE_START),
                      moment(applyTimeEnd).format(FORMAT_DATE_END),
                    ]
                  }
                  return undefined
                }}
              >
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'sku'} label={'sku'}>
                <Input placeholder={'请输入sku'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8 * 2}>
              <Form.Item label={'采购地址'}>
                <Row gutter={12}>
                  <Col span={8}>
                    <Form.Item name={'addressType'} noStyle>
                      <Select
                        allowClear
                        placeholder={'请选择'}
                        onChange={value => {
                          if (value === '0') {
                            setSearchList(wareList.map(item => ({id: item.warehouseId, label: item.name})))
                          } else if (value === '4') {
                            setSearchList([])
                          } else {
                            setSearchList(
                              addressList
                                .filter(item => item.addressType === value)
                                .map(item => ({id: item.addressId, label: item.address}))
                            )
                          }
                          form.setFieldsValue({warehouseId: undefined})
                        }}
                      >
                        {addressTypeArr.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item name={'warehouseId'} noStyle>
                      <Select
                        placeholder={'请选择'}
                        showSearch
                        filterOption={(inputValue, option: any) => {
                          return option.children?.indexOf(inputValue) > -1
                        }}
                        allowClear
                      >
                        {searchList.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            {!personalRight && (
              <Col span={8}>
                <Form.Item
                  name={'numDiff'}
                  label={'物品采收差异'}
                  // normalize={value => (typeof value === 'boolean' ? (value ? '1' : '0') : value)}
                  // getValueFromEvent={event => (event ? event === '1' : event)}
                >
                  <Select placeholder={'请选择'} allowClear>
                    <Option value={true}>是</Option>
                    <Option value={false}>否</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col span={8}>
              <Form.Item name={'cityCodeList'} label={'采购城市'}>
                <Select
                  options={cityList.map(item => ({label: item.name, value: item.code}))}
                  placeholder={'请选择'}
                  showSearch
                  filterOption={(inputValue, option: any) => {
                    return option.label?.indexOf(inputValue) > -1
                  }}
                  mode={'multiple'}
                  maxTagCount={3}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'returnFlag'} label={'是否退货'}>
                <Select
                  allowClear
                  placeholder={'请选择'}
                  options={[
                    {value: 1, label: '是'},
                    {value: 0, label: '否'},
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'stewardOrderIdNotNull'} label={'是否关联服务单'}>
                <Select
                  allowClear
                  placeholder={'请选择'}
                  options={[
                    {value: true, label: '是'},
                    {value: false, label: '否'},
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      {/* 列表 */}
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'purchaseId'}
          loading={tableLoading}
          columns={[
            {title: '采购ID', dataIndex: 'purchaseId'},
            ...(!personalRight
              ? [
                  {
                    title: '订单金额',
                    dataIndex: 'purchaseAmtSum',
                    render: value => <Typography.Text>{value ? value?.toFixed(2) : '/'}</Typography.Text>,
                  },
                  {
                    title: '运费',
                    dataIndex: 'purchaseWaybillAmtSum',
                    render: value => <Typography.Text>{value ? value?.toFixed(2) : '/'}</Typography.Text>,
                  },
                  {
                    title: '退货金额',
                    dataIndex: 'purchaseAmtReturnSum',
                    render: value => <Typography.Text>{value ? value?.toFixed(2) : '/'}</Typography.Text>,
                  },
                  {
                    title: '采购数量',
                    dataIndex: 'itemNumAll',
                    render: value => <Typography.Text>{value ? value : '/'}</Typography.Text>,
                  },
                  {
                    title: '申请人',
                    dataIndex: 'applicantName',
                    render: (value, record) => `${value}（${record.applicantMobile}）`,
                  },
                ]
              : []),
            {title: '采购物品', dataIndex: 'purchaseTitle', render: value => value ?? '/'},
            {title: '申请时间', dataIndex: 'applyTime'},
            {title: '采购地址', dataIndex: 'warehouseName'},
            {title: '是否关联服务单', dataIndex: 'stewardOrderId', render: value => (value ? '是' : '否')},
            {title: '是否退货', dataIndex: 'returnFlag', render: value => (value === 1 ? '是' : '否')},
            {
              title: '采购状态',
              dataIndex: 'purchaseStatus',
              render: value => <span>{purchaseStatusCode[value]}</span>,
            },
            {
              title: '操作',
              fixed: 'right',
              render: (_, record) => (
                <Space>
                  <Permission code={'001025002'}>
                    <Button style={{padding: 0}} type={'link'} onClick={() => setPurchaseId(record.purchaseId)}>
                      查看
                    </Button>
                  </Permission>
                </Space>
              ),
            },
          ]}
          dataSource={tableList}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 采购申请 */}
      <Modal
        open={insert}
        width={680}
        title={'采购申请'}
        centered
        afterClose={() =>
          purForm.setFieldsValue({
            addressType: undefined,
            warehouseId: undefined,
            templateId: undefined,
            itemList: [{materialId: undefined, num: undefined}],
          })
        }
        footer={null}
        onCancel={() => setInsert(false)}
      >
        <Form
          form={purForm}
          labelAlign={'left'}
          onFinish={() => onPurchaseInsert()}
          initialValues={{itemList: [{materialId: undefined, num: undefined}]}}
        >
          <Space>
            <Form.Item label={'采购地址'} name={'addressType'} rules={[{required: true, message: '请选择采购类别'}]}>
              <Select
                placeholder={'请选择类别'}
                style={{width: 130}}
                onChange={value => {
                  if (value === '0') {
                    setSelectAddressList(wareList.map(item => ({id: item.warehouseId, label: item.name})))
                  } else if (value === '4') {
                    setSelectAddressList([])
                  } else {
                    setSelectAddressList(
                      addressList
                        .filter(item => item.addressType === value)
                        .map(item => ({id: item.addressId, label: item.address}))
                    )
                  }
                  purForm.setFieldsValue({warehouseId: undefined, address: null})
                }}
                options={addressTypeArr}
                fieldNames={{value: 'id'}}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.addressType !== next.addressType}>
              {f => {
                const addressType = f.getFieldValue('addressType')
                const placeholderCode = {'0': '请选择仓库', '4': '请选择公寓'}
                return (
                  <Space>
                    <Form.Item
                      name={'warehouseId'}
                      rules={[{required: true, message: `${placeholderCode[addressType] ?? '请选择采购地址'}`}]}
                    >
                      <Select
                        placeholder={placeholderCode[addressType] ?? '请选择地址'}
                        allowClear
                        style={{width: 300}}
                        onSelect={(value, option) => {
                          if (addressType === '0') {
                            const ware = wareList.find(item => item.warehouseId === value)
                            purForm.setFieldsValue({address: ware?.address})
                          }
                        }}
                        showSearch
                        filterOption={(inputValue, option: any) => option.label?.includes(inputValue)}
                        options={selectAddressList}
                        fieldNames={{value: 'id'}}
                      />
                    </Form.Item>
                    {['1', '2', '3'].includes(addressType) && (
                      <Form.Item>
                        <Button
                          type={'text'}
                          onClick={() => {
                            addRef.current?.edit()
                          }}
                        >
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <PlusOutlined style={{color: '#00B7AE', fontSize: 16}} />
                            <Typography.Text style={{color: '#00B7AE', fontSize: 14}}>新增地址</Typography.Text>
                          </div>
                        </Button>
                      </Form.Item>
                    )}
                  </Space>
                )
              }}
            </Form.Item>
          </Space>
          <Form.Item noStyle shouldUpdate>
            {f => {
              const {address, addressType} = f.getFieldsValue(['address', 'addressType'])
              return ['0', '4'].includes(addressType) && !!address && <Form.Item label={'地址'}>{address}</Form.Item>
            }}
          </Form.Item>
          <Form.Item name={'templateId'} label={'模版选择'} style={{marginRight: '32px'}}>
            <Select
              placeholder={'请选择'}
              onChange={value => {
                const template = templateList.find(item => item.templateId === value)
                purForm.setFieldsValue({
                  itemList: template?.itemList?.map(item => ({materialId: item.materialId, num: item.num})),
                })
                setMaterialList(template?.itemList?.map(item => ({...item} as StewardMaterialListVO)))
              }}
              allowClear
              showSearch
              filterOption={(inputValue, option: any) => option.children?.includes(inputValue)}
            >
              {templateList.map(item => (
                <Option key={item.templateId} value={item.templateId}>
                  {item.templateName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'采购物品'}>
            <Form.List name='itemList'>
              {(fields, {add, remove}) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key} gutter={12}>
                        <Col span={1}>
                          <PlusCircleTwoTone
                            twoToneColor='#00B7AE'
                            onClick={() => add()}
                            style={{marginTop: '9px', visibility: index === 0 ? 'visible' : 'hidden'}}
                          />
                        </Col>
                        <Col span={17}>
                          <Form.Item
                            name={[field.name, 'materialId']}
                            rules={[{required: true, message: '请选择采购物品'}]}
                            shouldUpdate
                            style={{minWidth: '340px'}}
                          >
                            <Select
                              placeholder='请选择物品'
                              showSearch
                              filterOption={false}
                              onSearch={onMaterialSearch}
                              loading={materialLoading}
                              allowClear
                            >
                              {materialList?.map(item => (
                                <Option value={item.materialId} key={item.materialId}>
                                  {`${item.name}${item.brand ? `-${item.brand}` : ''}${
                                    item.unit ? `-${item.unit}` : ''
                                  }${item.specification ? `-${item.specification}` : ''}`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={[field.name, 'num']} rules={[{required: true, message: '请输入数量'}]}>
                            <InputNumber placeholder='请输入数量' min={1} style={{width: '100%'}} />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          {purForm.getFieldValue('itemList').length > 1 && (
                            <CloseCircleTwoTone
                              className={styles.icon}
                              twoToneColor='#A8A7AA'
                              onClick={() => {
                                remove(field.name)
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                  </>
                )
              }}
            </Form.List>
          </Form.Item>
          <Row justify={'end'} style={{marginTop: '40px'}}>
            <Space>
              <Button htmlType={'reset'} onClick={() => setInsert(false)}>
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={insertLoading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 采购详情 */}
      <PurchaseDetail
        purchaseId={purchaseId}
        onCancel={() => setPurchaseId('')}
        personalRight={personalRight}
        updateList={() => form.submit()}
      />

      {/* 添加收货地址 */}
      <AddAddress ref={addRef} onSuccess={() => queryAddressList()} />
    </Page>
  )
}
