import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TreeSelect,
  Upload,
} from 'antd'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory} from '@/common/hooks/useRouter'
import {stewardModel} from '@/store/models/Steward'
import {arrayToObject, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {downloadBlob} from '@/utils/util'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment/moment'
import {uploadExcelData} from '@/components/UploadExcel'
import {sysModel} from '@/store/models/Sys'
import {APP_ID} from '@/common/constants'
import {useListener} from '@/common/hooks/listener'
import {usePermission} from '@/components/Permission'

const statusList = [
  {label: '启用', value: '1'},
  {label: '禁用', value: '2'},
  {label: '已失效', value: '3'},
]

const statusCode = arrayToObject(statusList.map(item => ({id: item.value, label: item.label})))

const dialogStatusCode = {'1': '停用', '2': '启用'}

export default function Beike() {
  const history = useAppHistory()

  const positionLevelCode = useCodeSync('sys.user.steward.position.level')

  const [auth] = usePermission()

  const [tabKey, setTabKey] = useState(() => {
    const obj = {'001185001': '1', '001186001': '2'}
    const key = Object.keys(obj).find(item => auth(item))
    return obj[key]
  })

  const [form] = Form.useForm()
  const [selectedList, setSelectedList] = useState<StewardSalaryBkVO[]>([])

  // 贝壳管家绩效
  const [form1] = Form.useForm()
  const [bkTotal, setBkTotal] = useState(0)
  const [bkList, setBkList] = useState<StewardSalaryBkVO[]>([])

  const querySalaryBkList = useCallback(async () => {
    const {pageNum = 1, pageSize = 10, pageable = true, ...rest} = form1.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardSalaryBkList({...rest, pageNum, pageSize, pageable})
    setBkList(data)
    setBkTotal(total)
  }, [form1])

  useEffect(() => {
    querySalaryBkList()
  }, [querySalaryBkList])

  useListener('BeikeStewardPerformanceDetail:Beike', () => {
    querySalaryBkList()
  })

  // 贝壳绩效方案
  const [form2] = Form.useForm()
  const [planTotal, setPlanTotal] = useState(0)
  const [planList, setPlanList] = useState<StewardSalaryPlanListVO[]>([])

  const queryPlanList = useCallback(async () => {
    const {pageNum = 1, pageSize = 10, pageable = true, ...rest} = form2.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardSalaryPlanList({...rest, pageNum, pageSize, pageable, appId: APP_ID.BEIKE})
    setPlanList(data)
    setPlanTotal(total)
  }, [form2])

  useEffect(() => {
    queryPlanList()
  }, [queryPlanList])

  useListener('BeikeAddPerformance:Beike', () => {
    queryPlanList()
  })

  // 城市列表
  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityList(data.city)
    })
  }, [])

  // 组织树
  const [tree, setTree] = useState<SysOrgTreeNodeVO[]>([])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: '00'} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [])

  // 绩效类型列表
  // 绩效类型列表
  const [typeList, setTypeList] = useState<StewardSalaryTypeVO[]>([])

  const querySalaryTypeList = useCallback(async () => {
    const {
      response: {data},
    } = await stewardModel.stewardSalaryTypeList({
      pageNum: 1,
      pageSize: 20,
      pageable: false,
      enable: 1,
      appId: APP_ID.BEIKE,
    } as StewardSalaryTypeQueryDTO)

    setTypeList(data)
  }, [])

  useEffect(() => {
    querySalaryTypeList()
  }, [querySalaryTypeList])

  // 导入贝壳管家数据
  const [isImport, setIsImport] = useState(false)
  const [upLoading, setUpLoading] = useState(false)

  return (
    <Page>
      <Tabs className={commonStyles.tabs} activeKey={tabKey} onChange={activeKey => setTabKey(activeKey)}>
        {auth('001185001') && (
          <Tabs.TabPane tab={'贝壳管家绩效'} key={'1'} forceRender={true}>
            <div className={commonStyles.filterSection}>
              <Form
                form={form1}
                initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                onFinish={querySalaryBkList}
                onReset={querySalaryBkList}
              >
                <Row gutter={20}>
                  <Col span={8}>
                    <Form.Item name={'stewardName'} label={'管家名称'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'stewardMobile'} label={'管家手机号'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={'salaryDateBegin'}
                      label={'绩效月份'}
                      getValueFromEvent={(_, formatString) => {
                        form1.setFieldsValue({salaryDateEnd: formatString?.[1]})
                        return formatString?.[0]
                      }}
                      getValueProps={value => ({
                        value: value ? [moment(value), moment(form1.getFieldValue('salaryDateEnd'))] : value,
                      })}
                    >
                      <DatePicker.RangePicker picker={'month'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'stewardOrgId'} label={'组织'}>
                      <TreeSelect placeholder={'请选择'} treeData={tree} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'planName'} label={'绩效名称'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'status'} label={'确认状态'}>
                      <Select
                        placeholder={'请选择'}
                        options={[
                          {label: '未确认', value: 0},
                          {label: '已确认', value: 1},
                        ]}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={'confirmTimeBegin'}
                      label={'确认时间'}
                      getValueFromEvent={value => {
                        form1.setFieldsValue({confirmTimeEnd: value?.[1]?.format(FORMAT_DATE_END)})
                        return value?.[0]?.format(FORMAT_DATE_START)
                      }}
                      getValueProps={value => ({
                        value: value ? [moment(value), moment(form1.getFieldValue('confirmTimeEnd'))] : value,
                      })}
                    >
                      <DatePicker.RangePicker allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => form1.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>

            <div className={commonStyles.tableSection}>
              <Space style={{marginBottom: 12}}>
                {auth('011185001') && (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      setIsImport(true)
                    }}
                  >
                    导入贝壳管家数据
                  </Button>
                )}

                {auth('006185001') && (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      Modal.confirm({
                        title: '重新生成绩效',
                        icon: null,
                        centered: true,
                        closable: true,
                        content: (
                          <Form form={form}>
                            <Form.Item
                              name={'month'}
                              label={'绩效月份选择'}
                              rules={[{required: true, message: '请选择月份'}]}
                            >
                              <DatePicker
                                picker={'month'}
                                placeholder={'请选择月份'}
                                locale={locale}
                                disabledDate={date => date.isBefore(moment().add(-1, 'month').toDate())}
                              />
                            </Form.Item>
                          </Form>
                        ),
                        afterClose: () => form.resetFields(),
                        onOk: async () => {
                          await form.validateFields()
                          const month = form.getFieldValue('month')
                          // 选人就是这个人+月份重新生成，不选人就是月份所有的人的重新生成
                          await stewardModel.stewardSalaryBkBatchRefresh({
                            month: month.format('YYYY-MM'),
                            idList: selectedList.map(item => item.bkId),
                          })
                          message.success('重新生成绩效成功！')
                          setSelectedList([])
                          form1.submit()
                        },
                      })
                    }}
                  >
                    重新生成绩效
                  </Button>
                )}

                {auth('027185001') && (
                  <Button
                    onClick={() => {
                      if (selectedList.length === 0) return message.success('请选择要批量操作的项')
                      if (selectedList.some(item => item.status === 1)) {
                        Modal.confirm({
                          title: '批量额外奖惩',
                          icon: null,
                          centered: true,
                          closable: true,
                          content: '当前操作数据中包含已确认的绩效，已确认绩效不能操作额外奖惩，请重新选择。',
                          okCancel: false,
                        })
                      } else {
                        Modal.confirm({
                          title: '额外奖惩',
                          icon: null,
                          centered: true,
                          closable: true,
                          content: (
                            <Form form={form}>
                              <Form.Item
                                name={'addOrDelete'}
                                label={'执行操作'}
                                rules={[{required: true, message: '请选择要执行的操作'}]}
                              >
                                <Radio.Group>
                                  <Radio value={1}>新增</Radio>
                                  <Radio value={0}>撤回</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item
                                name={'typeId'}
                                label={'额外奖惩方案'}
                                rules={[{required: true, message: '请选择额外奖惩方案'}]}
                              >
                                <Select
                                  placeholder={'请选择'}
                                  options={typeList}
                                  fieldNames={{label: 'typeName', value: 'typeId'}}
                                  showSearch
                                  filterOption={(inputValue, option) => {
                                    return option?.typeName?.indexOf(inputValue) > -1
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          ),
                          afterClose: () => form.resetFields(),
                          onOk: async () => {
                            await form.validateFields()
                            const addOrDelete = form.getFieldValue('addOrDelete')
                            const typeId = form.getFieldValue('typeId')
                            await stewardModel.stewardSalaryBkTypeUse({
                              idList: selectedList.map(item => item.bkId),
                              addOrDelete,
                              typeId,
                            })
                            message.success('额外奖惩操作成功！')
                            setSelectedList([])
                            form1.submit()
                          },
                        })
                      }
                    }}
                  >
                    批量额外奖惩
                  </Button>
                )}

                {auth('026185001') && (
                  <Button
                    onClick={() => {
                      if (selectedList.length === 0) return message.success('请选择要批量操作的项')
                      Modal.confirm({
                        title: '批量绩效操作',
                        icon: null,
                        centered: true,
                        closable: true,
                        content: (
                          <Form form={form}>
                            <Form.Item
                              name={'confirmOrCancel'}
                              label={'执行操作'}
                              rules={[{required: true, message: '请选择要执行的操作'}]}
                            >
                              <Radio.Group>
                                <Radio value={1}>绩效确认</Radio>
                                <Radio value={0}>绩效撤回</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Form>
                        ),
                        afterClose: () => form.resetFields(),
                        onOk: async () => {
                          await form.validateFields()
                          const confirmOrCancel = form.getFieldValue('confirmOrCancel')
                          await stewardModel.stewardSalaryBkBatchUpdate({
                            idList: selectedList.map(item => item.bkId),
                            confirmOrCancel,
                          })
                          message.success('批量绩效操作成功！')
                          setSelectedList([])
                          form1.submit()
                        },
                      })
                    }}
                  >
                    批量绩效操作
                  </Button>
                )}

                {auth('007185001') && (
                  <Button
                    onClick={() => {
                      const value = form1.getFieldsValue(true)
                      downloadBlob({url: '/steward/salary-bk/list-excel', body: value})
                    }}
                  >
                    导出
                  </Button>
                )}
              </Space>

              <Table
                rowKey={'bkId'}
                dataSource={bkList}
                loading={false}
                scroll={{x: 'max-content'}}
                rowSelection={{
                  selectedRowKeys: selectedList.map(item => item.bkId),
                  onChange: (_, selectedRows) => setSelectedList(selectedRows),
                }}
                columns={[
                  {title: '姓名', dataIndex: 'stewardName', fixed: 'left'},
                  {title: '手机号', dataIndex: 'stewardMobile', fixed: 'left'},
                  {title: '组织', dataIndex: 'stewardOrgName', fixed: 'left'},
                  {title: '用户组', dataIndex: 'stewardRoleName', fixed: 'left'},
                  {
                    title: '岗位等级',
                    dataIndex: 'stewardPositionLevel',
                    fixed: 'left',
                    render: value => positionLevelCode[value],
                  },
                  {title: '绩效名称', dataIndex: 'planName'},
                  {title: '绩效月份', dataIndex: 'salaryDate', render: value => moment(value).format('YYYY-MM')},
                  {title: '当月单量', dataIndex: 'orderNum'},
                  {title: '底薪', dataIndex: 'baseSalary'},
                  {title: '绩效', dataIndex: 'performance'},
                  {title: '城市经理评级', dataIndex: 'evaluationLevel'},
                  {title: '城市经理评级绩效', dataIndex: 'evaluationLevelValue'},
                  {title: '迟到率（%）', dataIndex: 'lateRate'},
                  {title: '迟到率绩效', dataIndex: 'lateRateValue'},
                  {title: '一次性解决率（%）', dataIndex: 'oneTimeResolutionRate'},
                  {title: '一次性解决率绩效', dataIndex: 'oneTimeResolutionRateValue'},
                  {title: '迟到单（单）', dataIndex: 'lateOrders'},
                  {title: '迟到单绩效', dataIndex: 'lateOrdersValue'},
                  {title: '参评率（%）', dataIndex: 'participationRate'},
                  {title: '参评率绩效', dataIndex: 'participationRateValue'},
                  {title: '差评单（单）', dataIndex: 'negativeReviews'},
                  {title: '差评单绩效', dataIndex: 'negativeReviewsValue'},
                  {title: '投诉单（单）', dataIndex: 'complaintOrders'},
                  {title: '投诉单绩效', dataIndex: 'complaintOrdersValue'},
                  {title: '额外奖惩', dataIndex: 'extraPenaltyReward'},
                  {title: '评定绩效', dataIndex: 'salaryTotal'},
                  {title: '餐补', dataIndex: 'extraMealSubsidy'},
                  {title: '薪资合计', dataIndex: 'allSalary'},
                  {
                    title: '确认状态',
                    dataIndex: 'status',
                    render: value => (value === 0 && '未确认') || (value === 1 && '已确认'),
                  },
                  {title: '确认人', dataIndex: 'confirmName'},
                  {title: '确认时间', dataIndex: 'confirmTime'},
                  {
                    title: '操作',
                    dataIndex: 'bkId',
                    fixed: 'right',
                    render: value => (
                      <Space>
                        {auth('001185002') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            style={{padding: 0}}
                            onClick={() => {
                              history.push('/performance-beike/steward-performance', {bkId: value})
                            }}
                          >
                            查看
                          </Button>
                        )}
                      </Space>
                    ),
                  },
                ]}
                pagination={getPaginationProps(form1, bkTotal)}
              />
            </div>
          </Tabs.TabPane>
        )}

        {auth('001186001') && (
          <Tabs.TabPane tab={'贝壳绩效方案'} key={'2'}>
            <div className={commonStyles.filterSection}>
              <Form
                form={form2}
                initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                onFinish={queryPlanList}
                onReset={queryPlanList}
              >
                <Row gutter={20}>
                  <Col span={8}>
                    <Form.Item name={'planName'} label={'绩效名称'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'cityCodeList'} label={'城市'}>
                      <Select
                        placeholder={'请选择'}
                        options={cityList}
                        fieldNames={{label: 'label', value: 'id'}}
                        showSearch
                        mode={'multiple'}
                        filterOption={(inputValue, option: any) => {
                          return option.label?.indexOf(inputValue) > -1
                        }}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'status'} label={'状态'}>
                      <Select placeholder={'请选择'} options={statusList} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'insertBy'} label={'创建人'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={'applyBeginDate'}
                      label={'适用日期'}
                      getValueFromEvent={(_, formatString) => {
                        form2.setFieldsValue({applyEndDate: formatString?.[1]})
                        return formatString?.[0]
                      }}
                      getValueProps={value => ({
                        value: value ? [moment(value), moment(form2.getFieldValue('applyEndDate'))] : value,
                      })}
                    >
                      <DatePicker.RangePicker picker={'month'} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => form2.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>

            <div className={commonStyles.tableSection}>
              <Space style={{marginBottom: 12}}>
                {auth('003186002') && (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      history.push('/performance-beike/add-performance', {mode: 'insert'})
                    }}
                  >
                    新增绩效方案
                  </Button>
                )}
              </Space>
              <Table
                rowKey={'planId'}
                dataSource={planList}
                loading={false}
                columns={[
                  {title: '绩效名称', dataIndex: 'planName'},
                  {
                    title: '城市',
                    dataIndex: 'cityName',
                    render: value => {
                      const cityList = value?.split(',') ?? []
                      return (
                        <Popover content={value}>
                          {cityList.length > 2 ? cityList.slice(0, 2).join(',') + '...' : cityList.join(',')}
                        </Popover>
                      )
                    },
                  },
                  {
                    title: '适用日期',
                    dataIndex: 'applyBeginDate',
                    render: (_, record) =>
                      [record.applyBeginDate, record.applyEndDate]
                        .filter(Boolean)
                        .map(item => moment(item).format('YYYY-MM'))
                        .join('至'),
                  },
                  {title: '状态', dataIndex: 'status', render: value => statusCode[value]},
                  {title: '创建人', dataIndex: 'insertBy'},
                  {title: '创建时间', dataIndex: 'insertTime'},
                  {
                    title: '操作',
                    dataIndex: 'planId',
                    render: (value, record) => (
                      <Space>
                        {auth('001186002') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            style={{padding: 0}}
                            onClick={() => {
                              history.push('/performance-beike/add-performance', {planId: value, mode: 'readonly'})
                            }}
                          >
                            查看
                          </Button>
                        )}
                        {['1', '2'].includes(record.status) && auth('023186001') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            style={{padding: 0}}
                            onClick={async () => {
                              let msg = `确定要${dialogStatusCode[record.status]}"${record.planName}"？`

                              if (record.status === '1') {
                                const {
                                  response: {data},
                                } = await stewardModel.stewardSalaryPlanUnableTest({planId: value})
                                if (data) msg = `当前绩效方交“${record.planName}”已绑定，确定要停用吗？`
                              }

                              Modal.confirm({
                                title: `绩效方案${dialogStatusCode[record.status]}`,
                                icon: null,
                                centered: true,
                                closable: true,
                                content: msg,
                                onOk: async () => {
                                  await stewardModel.stewardSalaryPlanEnable({
                                    planId: value,
                                    status: record.status === '1' ? '2' : '1',
                                  })
                                  message.success(`${dialogStatusCode[record.status]}成功！`)
                                  form2.submit()
                                },
                              })
                            }}
                          >
                            {(record.status === '1' && '禁用') || (record.status === '2' && '启用')}
                          </Button>
                        )}
                        {auth('002185001') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            style={{padding: 0}}
                            onClick={() =>
                              history.push('/performance-beike/add-performance', {planId: value, mode: 'edit'})
                            }
                          >
                            编辑
                          </Button>
                        )}
                        {auth('013185001') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            style={{padding: 0}}
                            onClick={() =>
                              history.push('/performance-beike/add-performance', {planId: value, mode: 'copy'})
                            }
                          >
                            复制
                          </Button>
                        )}
                      </Space>
                    ),
                  },
                ]}
                pagination={getPaginationProps(form2, planTotal)}
              />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>

      <Modal
        open={isImport}
        title={'贝壳管家数据导入'}
        centered
        footer={null}
        afterClose={() => form.resetFields()}
        onCancel={() => setIsImport(false)}
        destroyOnClose={true}
      >
        <Form form={form}>
          <Form.Item name={'cityCode'} label={'城市'} rules={[{required: true, message: '请选择城市'}]}>
            <Select
              placeholder={'请选择'}
              options={cityList}
              fieldNames={{label: 'label', value: 'id'}}
              showSearch
              filterOption={(inputValue, option: any) => {
                return option.label?.indexOf(inputValue) > -1
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item name={'month'} label={'所属月份'} rules={[{required: true, message: '请选择所属月份'}]}>
            <DatePicker picker={'month'} locale={locale} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {iForm => {
              const cityCode = iForm.getFieldValue('cityCode')
              const month = iForm.getFieldValue('month')
              return cityCode && month ? (
                <Form.Item label={'上传'} required>
                  <Upload
                    data={{month: moment(month).format('YYYY-MM'), cityCode}}
                    accept={'.xlsx, .xls'}
                    multiple={false}
                    showUploadList={false}
                    customRequest={options =>
                      uploadExcelData({
                        info: options,
                        uri: '/steward/salary-bk/import',
                        onLoading: loading => setUpLoading(loading),
                        onSuccess: () => {
                          setIsImport(false)
                          form1.submit()
                        },
                      })
                    }
                  >
                    <Button loading={upLoading}>选择文件（.xls, .xlsx）</Button>
                  </Upload>
                </Form.Item>
              ) : null
            }}
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
