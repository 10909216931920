import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Menu,
  PageHeader,
  Row,
  Space,
  Table,
  Tabs,
  Typography,
  Upload,
} from 'antd'
import styles from './index.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import moment from 'moment'
import {lessorModel} from '@/store/models/Lessor'
import {downloadBlob} from '@/utils/util'
import {stewardModel} from '@/store/models/Steward'
import {usePermission} from '@/components/Permission'
import ClientAdd from '@/pages/client/ClientAdd'
import ClientBasicInfo from '@/pages/client/components/ClientBasicInfo'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import PropertyAddDelete from '@/pages/client/PropertyAddDelete'
import {customerModel} from '@/store/models/Customer'
import AccountDetail from '../AccountDetail'
import Framework from '@/pages/client/Framework'
import {userModel} from '@/store/models/User'
import ChargeAccount from '../ChargeAccount'
import Nyg8Account from '../Nyg8Account'
import ClientServiceList from '../ClientServiceList'
import ClientServiceInfo from '@/pages/client/components/ClientServiceInfo'
import ClientContract from '@/pages/client/ClientContract'
import PropertyTransfer from '@/pages/client/PropertyTransfer'
import {uploadExcelData} from '@/components/UploadExcel'
import ClientContractConfig from '@/pages/client/ClientContractConfig'
import ClientContractConfiged from '@/pages/client/ClientContractConfiged'
import TenantServiceConfig from '@/pages/client/components/TenantServiceConfig'
import {ClientBillComponent} from '@/pages/client/components/ClientBillComponent'
import Refund from '../components/Refund'
import {InvoiceListComponent} from '@/pages/client/InvoiceList/components/InvoiceListComponent'

interface LessorBillCountVO extends LessorContractBillCountVO {
  payLineCountMoneyPercent: string
  payLineCountPercent: string
}

interface TreeProps {
  title: string
  key: string
  children?: TreeProps[]
  icon: React.ReactNode
}

const {TabPane} = Tabs
const {RangePicker} = DatePicker
const {Text} = Typography

const menu = [
  {
    key: 'a',
    title: '客户',
    sub: [
      {key: '7', title: '客户详情', permission: '002001002'},
      {key: '1', title: '客户信息', permission: '001001002'},
      {key: '14', title: '客户房源', permission: '001005003'},
      {key: '2', title: '组织架构', permission: '001042002'},
      {key: '10', title: '余额明细', permission: '001067001'},
    ],
  },
  {
    key: 'b',
    title: '服务',
    sub: [
      {key: '3', title: '服务账单', permission: '001003001'},
      {key: '11', title: '果币', permission: '001003001'},
      {key: '12', title: '服务单', permission: '001003001'},
      {key: '4', title: '服务费账户', permission: '001009002'},
      {key: '17', title: '退款管理', permission: '001195001'},
      {key: '5', title: '服务类型', permission: '001043002'},
      {key: '19', title: '开票汇总', permission: '001199001'},
    ],
  },
  {
    key: 'c',
    title: '其他',
    sub: [
      {key: '16', title: '租客报修项目限制', permission: '001022002'},
      {key: '6', title: '耗材使用', permission: '001052001'},
      {key: '13', title: '合同', permission: '001048002'},
      {key: '8', title: '合同配置', permission: '001064001'},
      {key: '15', title: '合同配置新', permission: '001064001'},
      {key: '9', title: '房源增删', permission: '001068001'},
    ],
  },
]

export default function ClientInfo() {
  const history = useAppHistory()
  const location = useAppLocation<'/client/client-info'>()
  const [auth] = usePermission()
  const userId = location.state?.userId
  const teamId = location.state?.teamId
  const clientBillRef = useRef(null)
  const refundRef = useRef(null)

  const renderMenu = useMemo(
    () =>
      menu
        .map(value => {
          const menuItem = {...value}
          menuItem.sub = value.sub.filter(item => !item.permission || auth(item.permission))
          return menuItem
        })
        .filter(value => value.sub.length),
    [auth]
  )

  const [clientInfoForm] = Form.useForm()

  const [lessorInfo, setLessorInfo] = useState({} as LessorInfoVO)
  const [propertyCount, setPropertyCount] = useState([] as LessorPropertyCountVO[])
  const [lessorContractCount, setLessorContractCount] = useState([] as LessorContractCountVO[])
  const [lessorContractBillCount, setLessorContractBillCount] = useState([] as LessorBillCountVO[])
  const chargeRef = useRef(null)

  //服务费账户组织
  const [teamInfo, setTeamInfo] = useState({} as TeamTreeNodeVO)

  //耗材使用
  const [materialList, setMaterialList] = useState([] as StewardOrderItemVO[])
  const [materParams, setMaterParams] = useState({pageNum: 1, pageSize: 10} as StewardOrderItemQueryDTO)
  const [materTotal, setMaterTotal] = useState(0)
  const materLoading = stewardModel.stewardAdminOrderListItem.useLoading()
  const [materForm] = Form.useForm()
  const [clientEdit, setClientEdit] = useState(false)

  const nyg8Ref = useRef(null)

  // todo: networking
  // 客户-详情-查看合同
  // useEffect(() => {
  //   clientModel.customerContractInfo({userId}).then(({response: {data}}) => {
  //     setContractId(data?.contractId)
  //   })
  // }, [userId])

  const queryMaterList = useCallback(
    (args: StewardOrderItemQueryDTO) => {
      stewardModel.stewardAdminOrderListItem({...args, customerId: userId}).then(({response: {data, total}}) => {
        setMaterialList(data)
        setMaterTotal(total)
      })
    },
    [userId]
  )
  useEffect(() => {
    queryMaterList(materParams)
  }, [materParams, queryMaterList])

  //客户信息
  useEffect(() => {
    lessorModel.queryLessorInfo(userId).then(({response: {data}}) => {
      setLessorInfo(data)
      setPropertyCount([data.propertyCountVO])
      setLessorContractCount([data.lessorContractCountVO])
      let payLineCountMoneyPercent = '0'
      let payLineCountPercent = '0%'
      if (data?.lessorContractBillCountVO?.billPayLineMoneyCount && data?.lessorContractBillCountVO?.billMoneyCount) {
        payLineCountMoneyPercent =
          Number(
            (
              data?.lessorContractBillCountVO?.billPayLineMoneyCount / data?.lessorContractBillCountVO?.billMoneyCount
            ).toFixed(2)
          ) *
            100 +
          '%'
        payLineCountPercent =
          Number(
            (data?.lessorContractBillCountVO?.billPayLineCount / data?.lessorContractBillCountVO?.billCount).toFixed(2)
          ) *
            100 +
          '%'
      }
      setLessorContractBillCount(
        [data.lessorContractBillCountVO]?.map(item => ({...item, payLineCountPercent, payLineCountMoneyPercent}))
      )
    })
  }, [userId])

  const [agreementList, setAgreementList] = useState<UserAgreementVO[]>([])
  // 用户协议
  useEffect(() => {
    userModel.userAgreementList({userId}).then(({response: {data}}) => setAgreementList(data))
  }, [userId])

  //组织列表
  useEffect(() => {
    customerModel.customerTeamGetTeamTree({ownerId: userId}).then(({response: {data}}) => {
      if (data?.[0]) setTeamInfo(data?.[0])
    })
  }, [teamId, userId])

  //导出房东账单列表
  const [upLoading, setUpLoading] = useState(false)

  function operation(key: string) {
    switch (key) {
      case '1':
      case '2':
      case '5':
        return null
      case '3':
        return (
          <Space>
            {auth('004003002') && (
              <Button
                type='primary'
                onClick={() => {
                  clientBillRef.current?.batchDelete?.()
                }}
              >
                批量删除
              </Button>
            )}

            {auth('011003001') && (
              <Upload
                accept={'.xlsx, .xls'}
                multiple={false}
                showUploadList={false}
                customRequest={options =>
                  uploadExcelData({
                    info: options,
                    uri: '/user/lessor/bill/confirm-excel',
                    onLoading: loading => setUpLoading(loading),
                    onSuccess: () => clientBillRef.current?.import?.(),
                  })
                }
              >
                <Button type={'primary'} loading={upLoading}>
                  导入已收款账单
                </Button>
              </Upload>
            )}
            {auth('003003002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  clientBillRef.current?.addBill?.()
                }}
              >
                添加服务账单
              </Button>
            )}
            {auth('007003001') && (
              <Button
                onClick={() => {
                  clientBillRef.current?.export?.()
                }}
              >
                导出
              </Button>
            )}
          </Space>
        )
      case '4':
        return (
          <Space>
            {auth('003086002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  chargeRef.current.withdraw()
                }}
              >
                退款
              </Button>
            )}
            {auth('009012002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  chargeRef.current.recharge()
                }}
              >
                充值
              </Button>
            )}
            {auth('001014002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  chargeRef.current.withhold()
                }}
              >
                扣款
              </Button>
            )}
          </Space>
        )
      case '6':
        return auth('007052001') ? (
          <Button
            onClick={() =>
              downloadBlob({url: '/steward/admin/order/list-item-excel', body: {...materParams, customerId: userId}})
            }
          >
            导出
          </Button>
        ) : null
      case '7':
        return (
          <Space>
            {/* {auth('001065001') && (
              <Button
                type={'primary'}
                onClick={() => {
                  history.push({
                    pathname: '/client/contract',
                    state: {userId},
                  })
                }}
              >
                查看合同
              </Button>
            )} */}
            {clientEdit ? (
              <>
                <Button
                  type={'primary'}
                  onClick={() => {
                    clientInfoForm.validateFields().then(() => {
                      clientInfoForm.submit()
                      setClientEdit(false)
                    })
                  }}
                >
                  保存
                </Button>
                <Button
                  onClick={() => {
                    clientInfoForm.resetFields(['stewardDistribution', 'stewardWeigh'])
                    setClientEdit(false)
                  }}
                >
                  取消编辑
                </Button>
              </>
            ) : (
              <Button type={'primary'} onClick={() => setClientEdit(true)}>
                编辑
              </Button>
            )}
          </Space>
        )
      case '11':
        return (
          <Space>
            <Button
              onClick={() => {
                nyg8Ref.current?.set()
              }}
            >
              设置抵扣
            </Button>
            <Button
              type='primary'
              onClick={() => {
                nyg8Ref.current?.recharge()
              }}
            >
              充值
            </Button>
            <Button onClick={() => nyg8Ref.current?.minus()}>划扣</Button>
          </Space>
        )
      case '17':
        return (
          auth('003195002') && (
            <Button type={'primary'} onClick={() => refundRef.current?.onAdd()}>
              新增退款
            </Button>
          )
        )
      default:
        return null
    }
  }

  const defaultOpenKeys = useMemo(() => renderMenu.map(value => value.key), [renderMenu])
  const [active, setActive] = useState(() => {
    // 设置 initActive
    // if (key && renderMenu.some(value => value.sub?.some(item => item.key === key))) {
    //   return key
    // }
    return renderMenu[0]?.sub?.[0]?.key
  })

  return (
    <Page
      header={
        <PageHeader onBack={() => history.goBack()} ghost={false} title={'基本信息'} extra={operation(active)}>
          <ClientBasicInfo info={lessorInfo} />
        </PageHeader>
      }
    >
      <Layout>
        <Layout.Sider className={styles.side} theme={'light'}>
          <Menu
            className={styles.customMenu}
            activeKey={active}
            defaultSelectedKeys={[active]}
            defaultOpenKeys={defaultOpenKeys}
            mode='inline'
            onSelect={info => setActive(`${info.key}`)}
          >
            {renderMenu.map(value => (
              <Menu.SubMenu key={value.key} title={value.title}>
                {value.sub.map(item => (
                  <Menu.Item key={item.key}>{item.title}</Menu.Item>
                ))}
              </Menu.SubMenu>
            ))}
          </Menu>
        </Layout.Sider>
        <Layout>
          <Layout.Content>
            <Tabs activeKey={active} onChange={value => setActive(value)} renderTabBar={() => null}>
              <TabPane tab={'客户详情'} key={'7'}>
                <ClientAdd form={clientInfoForm} userId={userId} isEdit={clientEdit} />
              </TabPane>
              <TabPane tab={'客户信息'} key={'1'}>
                <div className={commonStyles.tableSection}>
                  <div className={styles.titleSection}>
                    <DividerLine title={'房源信息'} />
                    <Button
                      type={'link'}
                      style={{paddingTop: 0, paddingBottom: 0}}
                      onClick={() => {
                        history.push({pathname: '/property', state: {lessor: lessorInfo.mobile}})
                      }}
                    >
                      查看该房东为团队创建者的房源列表
                    </Button>
                  </div>
                  <Table
                    style={{marginTop: '20px', width: 720, marginBottom: 20}}
                    dataSource={propertyCount}
                    rowKey={'propertyNum'}
                    columns={[
                      {title: '房源总数', key: 'propertyNum', dataIndex: 'propertyNum', width: 180},
                      {title: '集中式房源', key: 'condoPropertyNum', dataIndex: 'condoPropertyNum', width: 180},
                      {title: '分散整租', key: 'scatterPropertyNum', dataIndex: 'scatterPropertyNum', width: 180},
                      {
                        title: '分散合租（套数）',
                        key: 'scatterJoinPropertyNum',
                        dataIndex: 'scatterJoinPropertyNum',
                        width: 180,
                        render: (text, record) => (
                          <Text>
                            {text}（{record.scatterPropertySuiteNum}）
                          </Text>
                        ),
                      },
                    ]}
                    pagination={false}
                  />

                  <div className={styles.titleSection}>
                    <DividerLine title={'租约信息'} />
                    <Button
                      type={'link'}
                      style={{paddingTop: 0, paddingBottom: 0}}
                      onClick={() => {
                        history.push({pathname: '/contract', state: {lessor: lessorInfo.mobile}})
                      }}
                    >
                      查看房东租约列表
                    </Button>
                  </div>
                  <Table
                    style={{marginTop: '20px', width: 900, marginBottom: 20}}
                    dataSource={lessorContractCount}
                    rowKey={'contractNum'}
                    columns={[
                      {title: '租约总数（不含作废）', key: 'contractNum', dataIndex: 'contractNum', width: 180},
                      {
                        title: '执行中租约数',
                        key: 'executingContractNum',
                        dataIndex: 'executingContractNum',
                        width: 180,
                      },
                      {title: '已退房', key: 'checkOutContractNum', dataIndex: 'checkOutContractNum', width: 180},
                      {
                        title: '执行中的电子合同',
                        key: 'executingElectrContractNum',
                        dataIndex: 'executingElectrContractNum',
                        width: 180,
                      },
                      {
                        title: '执行中的纸质合同',
                        key: 'executingPaperContractNum',
                        dataIndex: 'executingPaperContractNum',
                        width: 180,
                      },
                    ]}
                    pagination={false}
                  />

                  <div className={styles.titleSection}>
                    <DividerLine title={'账单信息'} />
                    <Button
                      type={'link'}
                      style={{paddingTop: 0, paddingBottom: 0}}
                      onClick={() => {
                        history.push({pathname: '/bill', state: {lessor: lessorInfo.mobile}})
                      }}
                    >
                      查看房东账单信息
                    </Button>
                  </div>
                  <Table
                    style={{marginTop: '20px', width: 950, marginBottom: 20}}
                    dataSource={lessorContractBillCount}
                    rowKey={'billMoneyCount'}
                    columns={[
                      {
                        title: '账单总金额（数量）',
                        key: 'billMoneyCount',
                        dataIndex: 'billMoneyCount',
                        width: 180,
                        render: (text, record) => (
                          <Text>
                            {text} ({record?.billCount})
                          </Text>
                        ),
                      },
                      {
                        title: '未支付金额（数量）',
                        key: 'billNoPayMoneyCount',
                        dataIndex: 'billNoPayMoneyCount',
                        width: 180,
                        render: (text, record) => (
                          <Text>
                            {text} ({record?.billNoPayCount})
                          </Text>
                        ),
                      },
                      {
                        title: '已支付金额（数量）',
                        key: 'billPayMoneyCount',
                        dataIndex: 'billPayMoneyCount',
                        width: 180,
                        render: (text, record) => (
                          <Text>
                            {text} ({record?.billPayCount})
                          </Text>
                        ),
                      },
                      {
                        title: '线上支付金额（数量）',
                        key: 'billPayLineMoneyCount',
                        dataIndex: 'billPayLineMoneyCount',
                        width: 180,
                        render: (text, record) => (
                          <Text>
                            {text} ({record?.billPayLineCount})
                          </Text>
                        ),
                      },
                      {
                        title: '线上支付金额比例（数量比例）',
                        key: 'payLineCountMoneyPercent',
                        dataIndex: 'payLineCountPercent',
                        width: 230,
                        render: (text, record) => (
                          <Text>
                            {text} ({record?.payLineCountPercent})
                          </Text>
                        ),
                      },
                    ]}
                    pagination={false}
                  />

                  <div className={styles.titleSection}>
                    <DividerLine title={'协议签署'} />
                  </div>
                  <Table
                    style={{marginTop: '20px', marginBottom: 20}}
                    dataSource={agreementList}
                    rowKey={'agreementId'}
                    pagination={false}
                    columns={[
                      {title: '协议名称', render: (_, record) => `《${record.name}》`},
                      {title: '客户端', dataIndex: 'ua'},
                      {title: '时间', dataIndex: 'updateTime'},
                      {title: '签署人', dataIndex: 'userName'},
                    ]}
                  />
                </div>
              </TabPane>
              <TabPane tab={'客户房源'} key={'14'}>
                <PropertyTransfer userId={userId} />
              </TabPane>
              <TabPane tab={'组织架构'} key={'2'}>
                <Framework ownerId={userId} />
              </TabPane>
              <TabPane tab={'余额明细'} key={'10'}>
                <AccountDetail userId={userId} />
              </TabPane>
              <TabPane tab={'服务账单'} key={'3'}>
                <ClientBillComponent userId={userId} ref={clientBillRef} />
              </TabPane>
              <TabPane tab={'果币'} key={'11'}>
                <Nyg8Account userId={userId} ref={nyg8Ref} certName={lessorInfo.certName} teamId={teamInfo.teamId} />
              </TabPane>
              <TabPane tab={'服务单'} key={'12'}>
                <ClientServiceList userId={userId} />
              </TabPane>
              <TabPane tab={'服务费账户'} key={'4'}>
                <ChargeAccount
                  ref={chargeRef}
                  userId={userId}
                  certName={lessorInfo.certName}
                  teamId={teamId || teamInfo.teamId}
                />
              </TabPane>
              <TabPane tab={'服务收费'} key={'5'}>
                <ClientServiceInfo userId={userId} />
              </TabPane>
              <TabPane tab={'退款管理'} key={'17'}>
                <Refund
                  ref={refundRef}
                  customerName={lessorInfo.certName}
                  userId={lessorInfo.userId}
                  customerMobile={lessorInfo.mobile}
                />
              </TabPane>
              <TabPane tab={'开票汇总'} key={'19'}>
                <InvoiceListComponent userId={userId} />
              </TabPane>
              <TabPane tab={'租客项目报修限制'} key={'16'}>
                <TenantServiceConfig lessorId={lessorInfo.userId} />
              </TabPane>
              <TabPane tab={'耗材使用'} key={'6'}>
                <div className={styles.form}>
                  <Form
                    form={materForm}
                    onFinish={() => {
                      const values = materForm.getFieldsValue(true)
                      const {time, ...rest} = values
                      const [insertTimeBegin, insertTimeEnd] = time
                        ? time.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
                        : []
                      setMaterParams(state => ({...state, ...rest, insertTimeBegin, insertTimeEnd, pageNum: 1}))
                    }}
                  >
                    <Row>
                      <Col span={6}>
                        <Form.Item name={'name'} label={'耗材名称'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={6} offset={1}>
                        <Form.Item name={'sku'} label={'SKU'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={6} offset={1}>
                        <Form.Item name={'serverName'} label={'管家名称'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item name={'time'} label={'使用时间'}>
                          <RangePicker showTime />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'}>
                          查询
                        </Button>
                        <Button
                          onClick={() => {
                            materForm.resetFields()
                            setMaterParams({pageNum: 1, pageSize: 10} as StewardOrderItemQueryDTO)
                          }}
                        >
                          重置
                        </Button>
                      </Space>
                    </Row>
                  </Form>
                </div>
                <div className={commonStyles.tableSection}>
                  <Table
                    rowKey={'recordId'}
                    loading={materLoading}
                    columns={[
                      {title: '服务单号', dataIndex: 'orderId', key: 'orderId'},
                      {title: 'SKU', dataIndex: 'sku', key: 'sku'},
                      {title: '耗材名称', dataIndex: 'name', key: 'name'},
                      {title: '数量', dataIndex: 'num', key: 'num'},
                      {title: '单位', dataIndex: 'unit', key: 'unit'},
                      {title: '使用时间', dataIndex: 'insertTime', key: 'insertTime'},
                      {title: '管家', dataIndex: 'serverName', key: 'serverName'},
                    ]}
                    dataSource={materialList}
                    pagination={{
                      total: materTotal,
                      pageSize: materParams.pageSize,
                      current: materParams.pageNum,
                      showSizeChanger: true,
                      position: ['bottomCenter'],
                      onChange: (pageNum, pageSize) => {
                        setMaterParams(state => ({...state, pageNum, pageSize}))
                      },
                    }}
                  />
                </div>
              </TabPane>
              <TabPane tab={'合同'} key={'13'}>
                <ClientContract userId={userId} />
              </TabPane>
              <TabPane tab={'合同配置'} key={'8'}>
                <ClientContractConfiged userId={userId} />
              </TabPane>
              <TabPane tab={'合同配置新'} key={'15'}>
                <ClientContractConfig customerId={userId} />
              </TabPane>
              <TabPane tab={'房源增删'} key={'9'}>
                <PropertyAddDelete customerId={userId} />
              </TabPane>
            </Tabs>
          </Layout.Content>
        </Layout>
      </Layout>
    </Page>
  )
}
