import React, {useCallback, useEffect, useMemo, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tree,
  Typography,
} from 'antd'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import OrgSelect from '@/components/Form/OrgSelect'
import {useAppHistory} from '@/common/hooks/useRouter'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import {sysUserModel} from '@/store/models/SysUser'
import {FORMAT_YEAR_MONTH} from '@/common/date'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {APP_ID} from '@/common/constants'
import SysRoleSelect from '@/components/Form/SysRoleSelect'
import {SearchSelect} from '@/components/Form/CitySelect'
import {usePermission} from '@/components/Permission'
import useSorter from '@/common/hooks/useSorter'
import {downloadBlob} from '@/utils/util'

const status = [
  {label: '未确定', value: 0},
  {label: '已确定', value: 1},
]

export default function LonghuPerformance() {
  const [form] = Form.useForm()
  const history = useAppHistory()

  // orgId 不需要动态响应
  const orgId = userModel.data.userInfo?.orgId

  // 岗位等级
  const level = useCodeArraySync('sys.user.steward.position.level')
  const levelObject = useCodeSync('sys.user.steward.position.level')
  // console.log('level', level)

  const [auth] = usePermission()

  const sorterProps = useSorter(form)

  const [dataSource, setDataSource] = useState<StewardSalaryLhVO[]>([])
  const [total, setTotal] = useState(0)
  const search = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardSalaryLhList(values)
    setTotal(total)
    setDataSource(data)
  }, [form])
  const loading = stewardModel.stewardSalaryLhList.useLoading()

  // 页面聚焦时触发
  useFocusEffect(() => {
    form.submit()
  })

  // 页面加载时触发
  useEffect(() => {
    form.submit()
  }, [form])

  const [idList, setIdList] = useState<string[]>([])

  // 手动生成绩效
  const [createForm] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [_orgTree, setOrgTree] = useState<SysOrgTreeNodeVO[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [defaultExpandAll, setDefaultExpandAll] = useState(false)
  useEffect(() => {
    if (!defaultExpandAll && searchValue) {
      setDefaultExpandAll(true)
    }
  }, [defaultExpandAll, searchValue])
  useEffect(() => {
    if (!visible) {
      setDefaultExpandAll(false)
    }
  }, [visible])

  // 城市
  const [cityList, setCityList] = useState<{label: string; value: string}[]>()
  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityList(data.city.map(value => ({label: value.label, value: value.id})))
    })
  }, [])

  const orgTree = useMemo(() => {
    if (!searchValue) return _orgTree

    const loop = (data: SysOrgTreeNodeVO[] = []) => {
      return data
        .map(value => ({...value}))
        .filter(value => {
          if (value.children) {
            value.children = loop(value.children)
            return value.children.length
          }
          return value.label.includes(searchValue)
        })
    }

    return loop(_orgTree)
  }, [_orgTree, searchValue])

  // 离职人员列表
  const [stewardList, setStewardList] = useState<SysUserVO[]>([])
  const createLoading = stewardModel.stewardSalaryLhCreate.useLoading()

  useEffect(() => {
    if (visible && !_orgTree?.length) {
      const orgId = userModel.data.userInfo?.orgId
      sysModel
        .sysOrgTree({fetchUser: true, orgIdLike: orgId, orgRoleId: 'steward'} as SysOrgTreeQueryDTO)
        .then(({response: {data}}) => setOrgTree(data))
    }
  }, [_orgTree?.length, visible])

  useEffect(() => {
    if (visible && !stewardList?.length) {
      sysUserModel.querySysUserList({roleId: 'steward', pageable: false}).then(({response: {data}}) => {
        setStewardList(data.filter(item => item.status === '0'))
      })
    }
  }, [stewardList?.length, visible])

  const [rewardsForm] = Form.useForm()
  const [rewardsList, setRewardsList] = useState<string[]>(null)
  const [typeList, setTypeList] = useState<StewardSalaryTypeVO[]>([])
  useEffect(() => {
    if (rewardsList && !typeList?.length) {
      stewardModel
        .stewardSalaryTypeList({
          appId: APP_ID.GUANYU,
          enable: 1,
          pageable: false,
          pageNum: 1,
          pageSize: 10,
        } as StewardSalaryTypeQueryDTO)
        .then(({response: {data}}) => data && setTypeList(data))
    }
  }, [rewardsList, typeList?.length])

  const [createTypeList, setCreateTypeList] = useState<StewardSalaryPlanListVO[]>([])

  const salaryConfirm = (record: StewardSalaryLhVO) => {
    const statusText = record.status === 1 ? '撤回' : '确认'

    const instance = Modal.confirm({
      icon: null,
      title: `绩效${statusText}`,
      content: `确定要${statusText}${record.stewardName}、${record.salaryDate}月份绩效？`,
      onOk: async () => {
        try {
          instance.update({okButtonProps: {loading: true}})
          await stewardModel.stewardSalaryLhUpdateBatch({
            confirmOrCancel: record.status === 1 ? 0 : 1,
            idList: [record.salaryId],
          })
          form.submit()
        } catch (e) {
          return Promise.reject(e)
        } finally {
          instance.update({okButtonProps: {loading: false}})
        }
      },
    })
  }

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form
          labelCol={{flex: '80px'}}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            // confirmTimeBegin: moment().subtract(3, 'month').format(FORMAT_DATE),
            // confirmTimeEnd: moment().format(FORMAT_DATE),
          }}
          form={form}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'管家名称'} name={'stewardName'}>
                <Input allowClear placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'绩效月份'}
                name={'salaryDate'}
                getValueFromEvent={(_, formatString) => formatString}
                getValueProps={value => ({value: value ? moment(value) : value})}
              >
                <DatePicker picker={'month'} allowClear format={FORMAT_YEAR_MONTH} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'组织'} name={'orgId'}>
                <OrgSelect allowClear orgPid={orgId} placeholder={'请选择'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'所在用户组'} name={'roleId'}>
                <SysRoleSelect allowClear placeholder={'请选择'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'岗位等级'} name={'stewardPositionLevel'}>
                <Select placeholder={'请选择'} allowClear options={level} fieldNames={{label: 'label', value: 'id'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'确认时间'}
                name={'confirmTimeBegin'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue('confirmTimeEnd', formatString?.[1])
                  return formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('confirmTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'确认状态'} name={'status'}>
                <Select options={status} allowClear placeholder={'请选择'} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  idList?.length && setIdList([])
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Space style={{marginBottom: 12}}>
          {auth('026183001') && (
            <Button
              title={!idList.length ? '请选择数据' : ''}
              disabled={!idList.length}
              type={'primary'}
              onClick={() => {
                let dialogForm: any = null
                const instance = Modal.confirm({
                  icon: null,
                  title: '批量绩效确认',
                  content: (
                    <Form initialValues={{idList}}>
                      <Form.Item noStyle shouldUpdate>
                        {f => (dialogForm = f) && null}
                      </Form.Item>
                      <Form.Item
                        label={'执行操作'}
                        name={'confirmOrCancel'}
                        rules={[{required: true, message: '请选择执行操作'}]}
                      >
                        <Radio.Group>
                          {auth('018183002') && <Radio value={'1'}>确认绩效</Radio>}
                          {auth('025183002') && <Radio value={'0'}>撤回绩效</Radio>}
                        </Radio.Group>
                      </Form.Item>
                    </Form>
                  ),
                  onOk: async () => {
                    await dialogForm.validateFields()
                    const values = dialogForm.getFieldsValue(true)
                    try {
                      instance.update({okButtonProps: {loading: true}})
                      await stewardModel.stewardSalaryLhUpdateBatch({confirmOrCancel: values.confirmOrCancel, idList})
                      form.submit()
                      setIdList([])
                    } catch (e) {
                      return Promise.reject(e)
                    } finally {
                      instance.update({okButtonProps: {loading: false}})
                    }
                  },
                })
              }}
            >
              批量绩效确认
            </Button>
          )}
          {auth('027183001') && (
            <Button
              title={!idList.length ? '请选择数据' : ''}
              disabled={!idList.length}
              type={'primary'}
              onClick={() => setRewardsList(idList)}
            >
              批量奖惩方案
            </Button>
          )}
          {auth('006183001') && (
            <>
              <Button
                type={'primary'}
                onClick={() => {
                  const instance = Modal.confirm({
                    icon: null,
                    title: '重新生成绩效',
                    content: (
                      <Form>
                        <Form.Item label={'绩效月份'} required>
                          <DatePicker
                            disabled
                            defaultValue={moment().subtract(1, 'month') as any}
                            format={FORMAT_YEAR_MONTH}
                          />
                        </Form.Item>
                      </Form>
                    ),
                    okText: '生成绩效',
                    onOk: async () => {
                      try {
                        instance.update({okButtonProps: {loading: true}})
                        await stewardModel.stewardSalaryLhCreateAll()
                        message.success('绩效方案生成中，请半小时后再查看。')
                        form.submit()
                      } catch (e) {
                        return Promise.reject(e)
                      } finally {
                        instance.update({okButtonProps: {loading: false}})
                      }
                    },
                  })
                }}
              >
                重新生成绩效
              </Button>
              <Button type={'primary'} onClick={() => setVisible(true)}>
                手动生成绩效
              </Button>
            </>
          )}

          {auth('007183001') && (
            <Button
              type={'primary'}
              onClick={() => {
                const value = form.getFieldsValue(true)
                downloadBlob({url: '/steward/salary/lh/list-excel', body: value})
              }}
            >
              导出
            </Button>
          )}
        </Space>
        <Table
          loading={loading}
          dataSource={dataSource}
          rowKey={'salaryId'}
          {...sorterProps}
          // title={data => <Button>批量绩效确认</Button>}
          scroll={{x: 'max-content'}}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: idList,
            onChange: (selectedRowKeys, selectedRows, info) => setIdList(selectedRowKeys as string[]),
          }}
          columns={[
            {title: '姓名', dataIndex: 'stewardName', fixed: 'left'},
            {title: '手机号', dataIndex: 'stewardMobile', fixed: 'left'},
            {title: '组织', dataIndex: 'stewardOrgName', fixed: 'left'},
            {title: '所在用户组', dataIndex: 'stewardRoleName', fixed: 'left'},
            {title: '岗位等级', dataIndex: 'stewardPositionLevel', fixed: 'left', render: value => levelObject[value]},
            {title: '绩效方案', dataIndex: 'planName'},
            {title: '绩效月份', dataIndex: 'salaryDate', sorter: true},
            {title: '底薪', dataIndex: 'basicSalary', render: value => `${value}元`, sorter: true},
            {title: '绩效', dataIndex: 'performance', sorter: true},
            {title: '综合评分', dataIndex: 'comprehensiveScore', sorter: true},
            {title: '评定系数', dataIndex: 'ratingFactor', sorter: true},
            {title: '评定绩效', dataIndex: 'ratingPerformance', render: value => `${value}元`, sorter: true},
            {title: '额外收费', dataIndex: 'feeTotal', render: value => `${value}元`, sorter: true},
            {title: '提成', dataIndex: 'commission', render: value => `${value}元`, sorter: true},
            {title: '餐补', dataIndex: 'mealAllowance', render: value => `${value}元`, sorter: true},
            {
              title: '额外奖惩',
              dataIndex: 'rewardAmount',
              sorter: true,
              render: value => (value > 0 ? `+${value}` : value),
            },
            {title: '薪资合计', dataIndex: 'salaryTotal', render: value => `${value}元`, sorter: true},
            {title: '确认状态', dataIndex: 'status', render: value => status.find(item => item.value === value)?.label},
            {title: '确认人', dataIndex: 'confirmName'},
            {title: '确认时间', dataIndex: 'confirmTime', sorter: true},
            {
              title: '操作',
              render: (value, record) => {
                return (
                  <Space>
                    {auth('001183002') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          history.push('/performance-longhu/detail', {salaryId: record.salaryId})
                        }}
                      >
                        查看
                      </Button>
                    )}

                    {record.status === 0 && auth('018183002') && (
                      <Button type={'link'} style={{padding: 0}} onClick={() => salaryConfirm(record)}>
                        绩效确认
                      </Button>
                    )}
                    {record.status === 1 && auth('025183002') && (
                      <Button type={'link'} style={{padding: 0}} onClick={() => salaryConfirm(record)}>
                        绩效撤回
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/*手动生成绩效*/}
      <Modal
        title={'手动生成绩效'}
        open={visible}
        destroyOnClose
        onOk={() => createForm.submit()}
        okButtonProps={{loading: createLoading}}
        onCancel={() => setVisible(false)}
        afterClose={() => {
          createForm.resetFields()
          setCreateTypeList([])
        }}
      >
        <Form
          form={createForm}
          labelCol={{flex: '100px'}}
          initialValues={{on: [], off: []}}
          onFinish={async () => {
            const {on = [], off = [], applyBeginDate, applyEndDate, ...rest} = createForm.getFieldsValue(true)
            // const stewardIdList = [...on.map(value => value.key), ...off.map(value => value.key)]
            const stewardIdList = [...on, ...off]
            if (!stewardIdList.length) return message.error('请选择管家')
            await stewardModel.stewardSalaryLhCreate({stewardIdList, ...rest})
            message.success('操作成功')
            form.submit()
            setVisible(false)
          }}
        >
          <Form.Item
            label={'绩效月份'}
            name={'yearMonth'}
            rules={[{required: true}]}
            getValueFromEvent={(_, formatString) => formatString}
            getValueProps={value => ({value: value ? moment(value) : value})}
          >
            <DatePicker picker={'month'} format={FORMAT_YEAR_MONTH} />
          </Form.Item>
          <Form.Item label={'绩效方案'} required>
            <Row gutter={12} style={{alignItems: 'center'}}>
              <Col>城市</Col>
              <Col flex={1}>
                <Form.Item noStyle name={'cityCode'} rules={[{required: true, message: '请选择城市'}]}>
                  <SearchSelect
                    options={cityList}
                    placeholder={'请选择'}
                    onChange={value => {
                      if (value) {
                        createForm.resetFields(['planId', 'applyBeginDate', 'applyEndDate'])
                        stewardModel
                          .stewardSalaryPlanList({
                            appId: APP_ID.GUANYU,
                            pageable: false,
                            pageNum: 1,
                            pageSize: 10,
                            cityCode: value,
                          })
                          .then(({response: {data}}) => setCreateTypeList(data || []))
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>名称</Col>
              <Col flex={1}>
                <Form.Item
                  noStyle
                  name={'planId'}
                  rules={[{required: true, message: '请选择绩效方案名称'}]}
                  getValueFromEvent={(event, option) => {
                    createForm.setFields([
                      {name: 'applyBeginDate', value: option?.applyBeginDate},
                      {name: 'applyEndDate', value: option?.applyEndDate},
                    ])
                    return event
                  }}
                >
                  <Select
                    placeholder={'请选择'}
                    options={createTypeList}
                    fieldNames={{label: 'planName', value: 'planId'}}
                    allowClear
                    showSearch
                    filterOption={(inputValue, option) => option.planName?.includes(inputValue)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.planId !== nextValues.planId}>
            {f => {
              const {applyBeginDate, applyEndDate} = f.getFieldsValue(true)
              return !!applyBeginDate && <Form.Item label={'适用时间'}>{`${applyBeginDate}-${applyEndDate}`}</Form.Item>
            }}
          </Form.Item>
          <Tabs>
            <Tabs.TabPane tab={'在职'} key={'1'}>
              <div style={{height: 600, overflow: 'scroll'}}>
                <Row align={'middle'} style={{marginBottom: 10}}>
                  <Col>姓名：</Col>
                  <Col flex={1}>
                    <Input.Search
                      allowClear
                      placeholder={'请输入'}
                      onSearch={value => setSearchValue(value)}
                      onChange={event => !event.target.value && setSearchValue('')}
                    />
                  </Col>
                </Row>
                <Form.Item noStyle name={'on'} valuePropName={'checkedKeys'}>
                  <Tree
                    fieldNames={{title: 'label', key: 'key', children: 'children'}}
                    treeData={orgTree}
                    key={`${defaultExpandAll}`}
                    defaultExpandAll={defaultExpandAll}
                    onCheck={(checked, info) => {
                      const on: string[] = createForm.getFieldValue('on')
                      const items = info.checkedNodes.filter(value => value.type === 'user').map(value => value.key)
                      if (info.checked) {
                        createForm.setFieldValue('on', Array.from(new Set([...on, ...items])))
                      } else {
                        createForm.setFieldValue('on', checked)
                      }
                    }}
                    checkable
                    selectable={false}
                    autoExpandParent
                  />
                </Form.Item>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'离职'} key={'2'}>
              <Table
                rowKey={'userId'}
                dataSource={stewardList}
                rowSelection={{
                  onSelect(record, selected, selectedRows) {
                    createForm.setFieldValue(
                      'off',
                      selectedRows.map(value => value.userId)
                    )
                  },
                  onSelectAll(selected, selectedRows) {
                    createForm.setFieldValue(
                      'off',
                      selectedRows.map(value => value.userId)
                    )
                  },
                }}
                columns={[
                  {
                    dataIndex: 'name',
                    filterMode: 'menu',
                    filters: stewardList.map(value => ({
                      text: `${value.name}（${value.mobile}）`,
                      value: value.userId,
                    })),
                    render: (value, record) => `${value}（${record.mobile}）`,
                    filterSearch: true,
                    onFilter: (value, record) => record.userId === value,
                  },
                ]}
                size={'small'}
                pagination={{position: ['bottomCenter']}}
              />
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Modal>

      {/*批量奖惩方案*/}
      <Modal
        title={'批量奖惩方案'}
        open={!!rewardsList?.length}
        destroyOnClose
        onOk={() => rewardsForm.submit()}
        onCancel={() => setRewardsList(null)}
        afterClose={() => rewardsForm.resetFields()}
      >
        <Form
          form={rewardsForm}
          labelCol={{flex: '100px'}}
          initialValues={{idList: rewardsList}}
          onFinish={async () => {
            const values = rewardsForm.getFieldsValue(true)
            console.log('values', values)
            await stewardModel.stewardSalaryLhTypeUse(values)
            message.success('操作成功')
            form.submit()
            setRewardsList(null)
            setIdList([])
          }}
        >
          <Form.Item label={'执行操作'} name={'addOrDelete'} rules={[{required: true, message: '请选择执行操作'}]}>
            <Radio.Group>
              <Radio value={1}>新增</Radio>
              <Radio value={0}>撤回</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={'额外奖惩方案'}
            name={'typeId'}
            rules={[{required: true, message: '请选择额外奖惩方案'}]}
            getValueFromEvent={(event, option) => {
              rewardsForm.setFieldValue('amt', option?.amt)
              return event
            }}
          >
            <Select
              placeholder={'请选择'}
              showSearch
              filterOption={(inputValue, option: any) =>
                option?.typeName?.toLowerCase()?.includes(inputValue?.toLowerCase())
              }
              options={typeList}
              fieldNames={{label: 'typeName', value: 'typeId'}}
            />
          </Form.Item>
          <Form.Item
            label={' '}
            colon={false}
            shouldUpdate={(prevValues, nextValues) => prevValues.typeId !== nextValues.typeId}
          >
            {f => {
              const amt = f.getFieldValue('amt')
              if (!amt) return null
              return amt > 0 ? (
                <Typography.Link>奖：{amt}元</Typography.Link>
              ) : (
                <Typography.Text type={'danger'}>罚：{Math.abs(amt)}元</Typography.Text>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
