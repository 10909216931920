import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from 'react'
import {Avatar, Button, Col, Dropdown, Form, Input, Layout, Menu, message, Modal, Row, Space} from 'antd'
import {
  ExclamationCircleFilled,
  UserOutlined,
  HomeOutlined,
  ClearOutlined,
  FundOutlined,
  ApartmentOutlined,
  LaptopOutlined,
  DesktopOutlined,
  ContactsOutlined,
  CalendarOutlined,
  CopyOutlined,
  SnippetsOutlined,
  CustomerServiceOutlined,
  BarChartOutlined,
  DribbbleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import styles from './AppLayout.module.less'
import {Link, Route, useHistory, useLocation, RouteProps, Redirect} from 'react-router-dom'
import {userModel} from '@/store/models/User'
import {useCacheManager} from '@/common/hooks/useCacheManager'

import LessorList from '../user/LessorList'
import AddorEditLessor from '../user/AddorEditLessor'
import LessorDetail from '../user/LessorDetail'
import LesseeList from '../user/LesseeList'
import AddorEditLessee from '../user/AddorEditLessee'
import LesseeDetail from '../user/LesseeDetail'
import SysUserListPage from '../administrator/sysUserList/SysUserListPage'
import SysRoleListPage from '../administrator/sysRoleList'
import SysPermissionListPage from '../administrator/sysPermission'
import logo from '@/assets/logo.png'
import PropertyList from '../property/PropertyList'
import PropertyDetail from '../property/PropertyDetail'
import ContractList from '../property/ContractList'
import ContractDetail from '../property/ContractDetail'
import BillList from '../property/BillList'
import BillDetail from '../property/BillDetail'
import FeedBackList from '../feedback/FeedBackList'
import FeedBackDetail from '../feedback/FeedBackDetail'
import SysUserAddPage from '@/pages/administrator/sysUserList/SysUserAddPage'
import SysRoleAddPage from '@/pages/administrator/sysRoleList/addSysUser/AddSysUser'
import StewardDetail from '@/pages/steward/StewardDetail'
import StewardList from '@/pages/steward/StewardList'
import ServiceType from '@/pages/steward/ServiceType'
import StewardServiceAdd from '@/pages/steward/StewardServiceAdd'
import ServiceOrderList from '@/pages/steward/ServiceOrderList'
import CommunityDataList from '@/pages/cityData/CommunityDataList'
import CommunityDataDetail from '@/pages/cityData/CommunityDataDetail'
import AllotApplyList from '@/pages/storage/AllotApplyList'
import SiteList from '@/pages/storage/SiteList'
import AllotDetail from '@/pages/storage/AllotDetail'
import StorageInput from '@/pages/storage/StorageInput'
import StorageReport from '@/pages/storage/StorageReport'
import StorageCheck from '@/pages/storage/StorageCheck'
import StorageOutput from '@/pages/storage/StorageOutput'
import ClientList from '@/pages/client/ClientList'
import TenantBindList from '@/pages/tenant/TenantBindList'
import TenantBindDetail from '@/pages/tenant/TenantBindDetail'
import StewardOrderAdd from '@/pages/steward/StewardOrderAdd'
import SysCode from '@/pages/administrator/SysCode'
import Recharge from '@/pages/client/Recharge'
import RechargeDetail from '@/pages/client/RechargeDetail'
import AuthList from '@/pages/user/AuthList'
import AuthDetail from '@/pages/user/AuthDetail'
import PropertyInfoList from '@/pages/steward/PropertyInfoList'
import GoodsManagement from '@/pages/oa/GoodsManagement'
import PurchaseManagement from '@/pages/oa/PurchaseManagement'
import OrgManage from '@/pages/administrator/OrgManage'
import QAManagement from '@/pages/feedback/QAManagement'
import List from '@/pages/stewardManage/List'
import StewardDetails from '@/pages/stewardManage/StewardDetails'
import PerformanceManagement from '@/pages/performanceManagement/PerformanceManagement'
import PerformanceDetail from '@/pages/stewardManage/PerformanceDetail'
import SaleClientList from '@/pages/saleManagement/SaleClientList'
import SaleClientDetail from '@/pages/saleManagement/SaleClientDetail'
import ClientInfo from '@/pages/client/ClientInfo'
import SaleClientContractList from '@/pages/saleManagement/SaleClientContractList'
import SaleClientSignList from '@/pages/saleManagement/SaleClientSignList'
import {hasPermission} from '@/components/Permission'
import ClientStatement from '@/pages/client/ClientStatement'
// import PropertyMap from '@/pages/property/PropertyMap'
import AddressManagement from '@/pages/oa/AddressManagement'
import VirtualNumberManage from '@/pages/user/VirtualNumberManage'
import CallManage from '@/pages/user/CallManage'
import LogList from '@/pages/log/LogList'
import ClientBill from '@/pages/client/ClientBill'
import SaleList from '@/pages/saleManagement/SaleList'
import ClientDocking from '../stewardManage/ClientDocking'
import ClientContract from '@/pages/client/ClientContract'
import ClientContractDetail from '@/pages/client/ClientContractDetail'
import OrderBatchAdd from '@/pages/steward/OrderBatchAdd'
import OrderBatchList from '@/pages/steward/OrderBatchList'
import UpdatePassword from '../user/UpdatePassword'
import ServiceChargeItems from '@/pages/steward/ServiceChargeItems'
import PurchaseTemplateSetting from '@/pages/oa/PurchaseTemplateSetting'
import Schedule from '@/pages/stewardManage/Schedule'
import ScheduleConfig from '@/pages/stewardManage/ScheduleConfig'
import EngineeringManagement from '@/pages/project/EngineeringManagement'
import NodeManagement from '@/pages/project/NodeManagement'
import EngineeringDetail from '@/pages/project/EngineeringDetail'
import EngineeringAdd from '@/pages/project/EngineeringAdd'
import EngineeringNodeDetail from '@/pages/project/EngineeringNodeDetail'
import StewardLocation from '@/pages/steward/StewardLocation'
import ConcentrateProjectList from '../concentrateProject/ConcentrateProjectList'
import AddProject from '../concentrateProject/AddProject'
import ProjectDetail from '../concentrateProject/ProjectDetail'
import LesseeMember from '@/pages/client/LesseeMember'
import FollowList from '../saleManagement/FollowList'
import CompanyAccountApprove from '../client/CompanyAccountApprove'
import CompanyAccountDetail from '../client/CompanyAccountDetail'
import AddCustomer from '../saleManagement/AddCustomer'
import DataStatistics from '../concentrateProject/DataStatistics'
import SaleContractSign from '@/pages/saleManagement/SaleContractSign'
import SaleLocation from '@/pages/saleManagement/SaleLocation'
import SaleSalaryManageMent from '../saleManagement/SaleSalaryManageMent'
import StewardDailyLog from '@/pages/stewardManage/StewardDailyLog'
import ApartmentDataList from '@/pages/apartment/ApartmentDataList'
import ApartmentDataDetail from '@/pages/apartment/ApartmentDataDetail'
import CondoTeamManage from '../concentrateProject/CondoTeamManage'
import AddPost from '../concentrateProject/AddPost'
import FinanceCompany from '../concentrateProject/FinanceCompany'
import AddFinanceCompany from '../concentrateProject/AddFinanceCompany'
import ProjectSet from '../concentrateProject/ProjectSet'
import AddHouseTemplate from '../concentrateProject/AddHouseTemplate'
import AddRoom from '../concentrateProject/AddRoom'
import PropertyDiscount from '@/pages/concentrateProject/PropertyDiscount'
import StewardCallingCheckList from '@/pages/steward/StewardCallingCheckList'
import ContentOperateManage from '../feedback/ContentOperateManage'
import AddBanner from '../feedback/AddBanner'
import ReportManagement from '../report/ReportManagement'
import StatisticsReport from '../report/StatisticsReport'
import DownLoadCenter from '../report/DownLoadCenter'
import Home from '../home/Home'
import AddNotice from '../feedback/AddNotice'
import CRMClientList from '../crm/CRMClientList'
import AddClient from '../crm/AddClient'
import AddLookOrder from '../crm/AddLookOrder'
import CRMClientDetail from '../crm/CRMClientDetail'
import CRMLookOrder from '../crm/CRMLookOrder'
import CRMLookDetail from '../crm/CRMLookDetail'
import StoreAddAgentSteward from '@/pages/concentrateProject/StoreAddAgentSteward'
import StoreApartmentManageDetail from '@/pages/concentrateProject/StoreApartmentManageDetail'
import StoreManagementInfo from '@/pages/concentrateProject/StoreManagementInfo'
import StorePropertyInfo from '@/pages/concentrateProject/StorePropertyInfo'
import PromotionManagement from '../concentrateProject/PromotionManagement'
import PropertyInfo from '../concentrateProject/PropertyInfo'
import StoreManagement from '../concentrateProject/StoreManagement'
import AddStore from '../concentrateProject/AddStore'
import StorePropertyDetail from '@/pages/concentrateProject/StorePropertyDetail'
import StorePorpertyTemplate from '@/pages/concentrateProject/StorePorpertyTemplate'
import StewardExamManagement from '@/pages/steward/StewardExamManagement'
import StewardExamDetail from '@/pages/steward/StewardExamDetail'
import CooperationList from '@/pages/administrator/CooperationList'
import FileManagement from '../saleManagement/FileManagement'
// import MapSearchOrder from '../steward/MapSearchOrder'
import StewardExamGeneration from '@/pages/steward/StewardExamGeneration'
import CleanServerArea from '@/pages/steward/CleanServerArea'
import CleanServerAreaAdd from '@/pages/steward/CleanServerAreaAdd'
import ServiceRemoteApply from '@/pages/service/ServiceRemoteApply'
import RestDayOrder from '@/pages/customer/RestDayOrder'
import ServiceCancelApply from '@/pages/service/ServiceCancelApply'
import CustomerWhiteList from '@/pages/user/CustomerWhiteList'
import ServiceCleanConfirm from '@/pages/cleanManagement/ServiceCleanConfirm'
import ServiceCleanQuality from '@/pages/cleanManagement/ServiceCleanQuality'
import CleanServiceWillDispatch from '@/pages/cleanManagement/CleanServiceWillDispatch'
import CleanServiceDispatch from '@/pages/cleanManagement/CleanServiceDispatch'
import CleanServiceFinish from '@/pages/cleanManagement/CleanServiceFinish'
import CleanServiceDeductConfirm from '@/pages/cleanManagement/CleanServiceDeductConfirm'
import CleanServiceQuality from '@/pages/cleanManagement/CleanServiceQuality'
import CleanServiceCancel from '@/pages/cleanManagement/CleanServiceCancel'
import ServiceCleanConfirmDetail from '@/pages/cleanManagement/ServiceCleanConfirmDetail'
import CleanServiceDetail from '@/pages/cleanManagement/CleanServiceDetail'
import CleanServiceAll from '@/pages/cleanManagement/CleanServiceAll'
import AppliancesList from '@/pages/appliances/AppliancesList'
import PerformanceNew from '@/pages/stewardManage/PerformanceNew'
import CompanyList from '@/pages/company/CompanyList'
import CompanyInsert from '@/pages/company/CompanyInsert'
import CompanyDetail from '@/pages/company/CompanyDetail'
import CompanyPermission from '@/pages/company/CompanyPermission'
import SetPermissionIdList from '@/pages/administrator/sysRoleList/addSysUser/SetPermissionIdList'
import OpenAppManageList from '@/pages/user/OpenAppManageList'
import AddOpenApp from '@/pages/user/AddOpenApp'
import OpenAppDetail from '@/pages/user/OpenAppDetail'
import OpenAppService from '@/pages/user/OpenAppService'
import SysRuleConfig from '@/pages/administrator/SysRuleConfig'
import OperationReport from '@/pages/report/OperationReport'
import ServiceDataBoard from '@/pages/service/ServiceDataBoard'
import SysBlackList from '@/pages/administrator/SysBlackList'
import TodoManagement from '@/pages/service/TodoManagement'
import MineTodo from '@/pages/service/MineTodo'
import PropertyAdd from '@/pages/property/PropertyAdd'
import PropertyAddCondo from '@/pages/property/PropertyAddCondo'
import PropertyAddScatter from '@/pages/property/PropertyAddScatter'
import ComplaintWorkOrder from '@/pages/service/ComplaintWorkOrder'
import AddComplaintOrder from '@/pages/service/AddComplaintOrder'
import PayOrder from '@/pages/service/PayOrder'
import PunishOrder from '@/pages/service/PunishOrder'
import ComplaintDetail from '@/pages/service/ComplaintDetail'
import AnJuPropertyListManage from '@/pages/anju/AnJuPropertyListManage'
import AnJuQualityUnit from '@/pages/anju/AnJuQualityUnit'
import AnJuQualityProject from '@/pages/anju/AnJuQualityProject'
import AnJuServiceList from '@/pages/anju/AnJuServiceList'
import AnJuServiceStatistics from '@/pages/anju/AnJuServiceStatistics'
import AnJuProjectList from '@/pages/anju/AnJuProjectList'
import AnjuRepairList from '@/pages/anju/AnjuRepairList'
import DashboardPage from '../dashboard'
import ClientServiceTemplate from '@/pages/client/ClientServiceTemplate'
import ClientServiceTemplateAdd from '@/pages/client/ClientServiceTemplateAdd'
import {Model} from '@redux-model/web'
import MapService from '@/pages/steward/MapService'
import MapPropertyLayer from '@/pages/steward/MapPropertyLayer'
import ServiceChargeDetail from '@/pages/steward/ServiceChargeDetail'
import Beike from '@/pages/performance/Beike'
import BeikeAddPerformance from '@/pages/performance/Beike/AddPerformance'
import BeikeStewardPerformanceDetail from '@/pages/performance/Beike/StewardPerformanceDetail'
import RewardAndPunishmentList from '@/pages/performanceManagement/RewardAndPunishmentList'
import Longhu from '@/pages/performance/Longhu'
import LonghuPerformanceNew from '@/pages/performance/Longhu/LonghuPerformanceNew'
import LonghuPerformanceDetail from '@/pages/performance/Longhu/LonghuPerformanceDetail'
import AssessmentManagementList from '@/pages/steward/AssessmentManagementList'
import PerformanceConfiguration from '@/pages/performanceManagement/PerformanceConfiguration'
import MessageList from '@/pages/message/MessageList'
import StewardApprovalList from '@/pages/steward/StewardApprovalList'
import RefundList from '../client/RefundList'
import ImprestApply from '../user/ImprestApply'
import ImprestApplyDetail from '../user/ImprestApplyDetail'
import MessageBadge from '@/pages/message/MessageBadge'
import ContactList from '@/pages/steward/ContactList'
import PerformanceRuleSetList from '@/pages/performanceManagement/PerformanceRuleSetList'
import PerformanceRuleSet from '@/pages/performanceManagement/PerformanceRuleSet'
import OperationOrgManage from '@/pages/administrator/OperationOrgManage'
import PublishNotificationList from '@/pages/message/PublishNotificationList'
import PublishNotification from '@/pages/message/PublishNotification'
import InvoiceList from '@/pages/client/InvoiceList'
import SettlementRecord from '@/pages/longhu/SettlementRecord'
import SettlementDetail from '@/pages/longhu/SettlementDetail'

const {confirm} = Modal

export interface Routers {
  permission?: string | string[]
  title: string
  link?: string
  component?: any
  hidden?: boolean
  keepAlive?: boolean
  icon?: React.ReactNode
  sub?: Routers[]
  style?: CSSProperties
}

export const routers: Routers[] = [
  {
    title: '系统首页',
    link: '/home',
    icon: <HomeOutlined />,
    component: Home,
    sub: [
      {title: '系统首页', link: '/home', component: Home},
      {link: '/updatePwd', title: '修改密码', component: UpdatePassword, hidden: true},
      {link: '/message', title: '消息', component: MessageList, hidden: true},
    ],
  },
  {
    title: '客户管理',
    icon: <UserOutlined />,
    sub: [
      {
        title: '签约客户管理',
        sub: [
          {
            permission: '001001001',
            link: '/client',
            title: '客户列表',
            component: ClientList,
            keepAlive: true,
          },
          {
            permission: ['001057001', '001058001', '001011001', '001013001'],
            link: '/client-bill',
            title: '客户账单',
            component: ClientBill,
            keepAlive: true,
          },
          {
            permission: '001121001',
            link: '/client-contract',
            title: '客户合同',
            component: ClientContract,
          },
          {
            permission: '015001002',
            link: '/client_state',
            title: '客户报表',
            component: ClientStatement,
          },
          {
            permission: '001001003',
            link: '/client-template',
            title: '客户服务报价模板',
            component: ClientServiceTemplate,
          },
          {
            link: '/client-template/add',
            title: '新增客户服务报价模板',
            component: ClientServiceTemplateAdd,
            hidden: true,
          },
          {
            permission: '001007001',
            link: '/auth',
            title: '企业认证',
            component: AuthList,
            keepAlive: true,
          },
          {
            link: '/auth/detail',
            title: '企业认证审核',
            component: AuthDetail,
            hidden: true,
          },
          {
            link: '/client/recharge',
            title: '充值',
            component: Recharge,
            hidden: true,
          },
          {
            link: '/client/recharge/detail',
            title: '充值详情',
            component: RechargeDetail,
            hidden: true,
          },
          {
            link: '/client/client-info',
            title: '客户信息',
            component: ClientInfo,
            hidden: true,
          },
          {
            link: '/client/contract_detail',
            title: '合同信息',
            component: ClientContractDetail,
            hidden: true,
          },
          {
            permission: '001082001',
            link: '/lessee-member',
            title: '付费租客',
            component: LesseeMember,
          },
          {
            permission: '018007001',
            link: '/company_account',
            title: '企业账户审批',
            component: CompanyAccountApprove,
            keepAlive: true,
          },
          {
            link: '/company_account/detail',
            title: '企业账户审批',
            component: CompanyAccountDetail,
            hidden: true,
          },
          {
            permission: '001200001',
            link: '/refund-list',
            title: '退款列表',
            component: RefundList,
          },
          {
            permission: '001201001',
            link: '/invoice-list',
            title: '开票列表',
            component: InvoiceList,
            keepAlive: true,
          },
        ],
      },
      {
        title: '准客户管理',
        sub: [
          {
            permission: ['001046001', '001085001', '001087001'],
            link: '/sale-client',
            title: '准客户列表',
            component: SaleClientList,
            keepAlive: true,
          },
          {
            link: '/sale-client/detail',
            title: '客户详情',
            component: SaleClientDetail,
            hidden: true,
          },
          {
            permission: ['001046001', '003048002', '001048002'],
            link: '/sale-client/contract_input',
            title: '录入合同',
            component: SaleContractSign,
            hidden: true,
          },
          {
            link: '/sale-client/add-customer',
            title: '新增客户',
            component: AddCustomer,
            hidden: true,
          },
          {
            permission: '001048001',
            link: '/contract-list',
            title: '合同列表',
            component: SaleClientContractList,
            keepAlive: true,
          },
          {
            permission: ['001049001', '001050001'],
            link: '/sign-list',
            title: '签约列表',
            component: SaleClientSignList,
            keepAlive: true,
          },
          {
            permission: ['001083001', '001084001'],
            link: '/follow-list',
            title: '跟进列表',
            component: FollowList,
            keepAlive: true,
          },
        ],
      },
      {
        title: '销售管理',
        sub: [
          {
            permission: '001059001',
            link: '/sale-list',
            title: '销售列表',
            component: SaleList,
          },
          {
            permission: '001090001',
            link: '/sale-location',
            title: '销售定位',
            component: SaleLocation,
          },
          {
            permission: '001091001',
            link: '/sale-salary',
            title: '销售绩效',
            component: SaleSalaryManageMent,
          },
          {
            permission: '001122001',
            link: '/file-manage',
            title: '附件管理',
            component: FileManagement,
          },
        ],
      },
    ],
  },
  {
    title: '房态相关',
    icon: <FundOutlined />,
    sub: [
      {
        permission: '001005001',
        link: '/property',
        title: '房源列表',
        component: PropertyList,
        keepAlive: true,
      },
      {
        permission: '003005001',
        link: '/property/add',
        title: '添加房源',
        component: PropertyAdd,
        hidden: true,
      },
      {
        permission: '003005001',
        link: '/property/add/condo',
        title: '添加公寓',
        component: PropertyAddCondo,
        hidden: true,
      },
      {
        permission: '003005001',
        link: '/property/add/scatter',
        title: '添加房源',
        component: PropertyAddScatter,
        hidden: true,
      },
      {
        link: '/property/detail',
        title: '房源详情',
        component: PropertyDetail,
        hidden: true,
      },
      {
        permission: '001015001',
        link: '/contract',
        title: '租约列表',
        component: ContractList,
        keepAlive: true,
      },
      {
        link: '/contract/detail',
        title: '租约详情',
        component: ContractDetail,
        hidden: true,
      },
      {
        permission: '001004001',
        link: '/bill',
        title: '账单列表',
        component: BillList,
        keepAlive: true,
      },
      {
        link: '/bill/detail',
        title: '账单详情',
        component: BillDetail,
        hidden: true,
      },
    ],
  },
  {
    title: '管家运营',
    icon: <ClearOutlined />,
    sub: [
      {
        title: '服务管理',
        sub: [
          {
            permission: '001016001',
            title: '管家分配',
            link: '/steward_list',
            component: StewardList,
            keepAlive: true,
          },
          {
            permission: ['001041001', '001102001', '001202001'],
            title: '服务单列表',
            link: '/order-list',
            component: ServiceOrderList,
            keepAlive: true,
          },
          // {
          //   permission: ['001124001', '001131001'],
          //   title: '服务地图',
          //   link: '/map_search',
          //   component: MapSearchOrder,
          //   keepAlive: true,
          // },
          {
            permission: ['001124001', '001131001', '001051002', '001051003'],
            title: '服务地图',
            link: '/map_service',
            component: MapService,
            keepAlive: true,
          },
          {
            permission: '001051003',
            title: '房源热力图',
            link: '/map_layer',
            component: MapPropertyLayer,
          },
          {
            permission: '001127001',
            title: '保洁服务区域',
            link: '/clean-server-area',
            component: CleanServerArea,
          },
          {
            title: '新增服务区域',
            link: '/clean-server-area/add',
            component: CleanServerAreaAdd,
            hidden: true,
          },
          // {
          //   title: '付款记录',
          //   link: '/order-list/pay-list',
          //   component: ServicePayList,
          //   hidden: true,
          // },
          {
            title: '服务单详情',
            link: '/steward/detail',
            component: StewardDetail,
            hidden: true,
          },
          {
            permission: '001022001',
            title: '服务类型管理',
            link: '/steward_serviceManager',
            component: ServiceType,
          },
          {
            title: '新增编辑服务',
            link: '/steward/service/add',
            component: StewardServiceAdd,
            hidden: true,
          },
          {
            permission: ['001069001'],
            link: '/service-charges',
            title: '服务收费类目',
            component: ServiceChargeItems,
            keepAlive: true,
          },
          {
            permission: ['002069002'],
            title: '详情',
            link: '/service-charges/detail',
            component: ServiceChargeDetail,
            hidden: true,
          },
          // {
          //   permission: '001051002',
          //   link: '/map',
          //   title: '房源地图',
          //   component: PropertyMap,
          // },
          {
            permission: ['001023001', '001024001'],
            link: '/tenant',
            title: '租客绑定',
            component: TenantBindList,
            keepAlive: true,
          },
          {
            link: '/tenant/bind',
            title: '租客绑定',
            component: TenantBindDetail,
            hidden: true,
          },
          {
            permission: '001038001',
            title: '房源信息记录',
            link: '/steward_info',
            component: PropertyInfoList,
          },
        ],
      },
      // {
      //   title: '服务单创建',
      //   sub: [
      //     {
      //       permission: '001063001',
      //       title: '批量创建记录',
      //       link: '/order-batch-list',
      //       component: OrderBatchList,
      //       keepAlive: true,
      //     },
      //     {
      //       permission: '003063002',
      //       title: '新建申请',
      //       link: '/order-batch-list/add',
      //       component: OrderBatchAdd,
      //       hidden: true,
      //     },
      //   ],
      // },
      {
        title: '仓储管理',
        icon: <ApartmentOutlined />,
        sub: [
          {
            permission: '001006001',
            title: '站点管理',
            link: '/site',
            component: SiteList,
            keepAlive: true,
          },
          {
            link: '/allot',
            title: '调拨申请',
            component: AllotApplyList,
            hidden: true,
          },
          {
            link: '/allot/detail',
            title: '查看详情',
            component: AllotDetail,
            hidden: true,
          },
          {
            link: '/site/apply',
            title: '入仓',
            component: StorageInput,
            hidden: true,
          },
          {
            link: '/site/output',
            title: '调拨',
            component: StorageOutput,
            hidden: true,
          },
          // {
          //   permission: 'MENU_STORAGE_SITE_INVENTORY_LIST',
          //   link: '/site/list',
          //   title: '库存',
          //   component: InventoryList,
          //   hidden: true,
          // },
          {
            permission: '001028001',
            link: '/report',
            title: '仓储报表',
            component: StorageReport,
            keepAlive: true,
            // hidden: true,
          },
          {
            link: '/site/check',
            title: '盘点',
            component: StorageCheck,
            hidden: true,
          },
        ],
      },
      {
        title: '管家管理',
        icon: <ContactsOutlined />,
        sub: [
          {
            link: '/steward-approval',
            title: '审批',
            component: StewardApprovalList,
          },
          {
            permission: '001113001',
            link: '/steward-exam-management',
            title: '考试管理',
            component: StewardExamManagement,
            keepAlive: true,
          },
          {
            permission: '001113001',
            link: '/steward-exam-management/detail',
            title: '练习详情',
            component: StewardExamDetail,
            hidden: true,
          },
          {
            link: '/steward-exam-management/generation',
            title: '生成试题',
            component: StewardExamGeneration,
            hidden: true,
          },
          {
            permission: ['001002001', '001002003'],
            link: '/steward-list',
            title: '管家列表',
            component: List,
            keepAlive: true,
          },
          {
            permission: '001189001',
            link: '/assessment-list',
            title: '评定管理',
            component: AssessmentManagementList,
          },
          {
            permission: '001198001',
            link: '/contact-list',
            title: '通讯录',
            component: ContactList,
          },
          {
            link: '/steward-list/detail',
            title: '管家详情',
            component: StewardDetails,
            hidden: true,
          },
          {
            permission: '001093001',
            link: '/steward-daily-log',
            title: '管家服务数据',
            component: StewardDailyLog,
            keepAlive: true,
          },
          {
            permission: '001104001',
            link: '/steward-calling',
            title: '电联核实申请',
            component: StewardCallingCheckList,
          },
          {
            permission: ['001072001', '001205001'],
            link: '/schedule',
            title: '排班管理',
            component: Schedule,
            keepAlive: true,
          },
          {
            permission: '002072002',
            link: '/schedule/config',
            title: '排班配置',
            component: ScheduleConfig,
            hidden: true,
          },
          {
            permission: '001080001',
            link: '/location',
            title: '定位看板',
            component: StewardLocation,
          },
        ],
      },
      {
        title: '绩效管理',
        icon: <ContactsOutlined />,
        sub: [
          // {
          //   permission: ['001045001', '001135001', '001159001'],
          //   link: '/performance',
          //   title: '绩效管理',
          //   component: PerformanceManagement,
          //   keepAlive: true,
          // },
          {
            permission: ['001045001', /*'001135001',*/ '001159001'],
            link: '/performance',
            title: '牛油果绩效管理',
            component: PerformanceManagement,
            keepAlive: true,
          },
          {
            link: '/performance/detail',
            title: '绩效详情',
            component: PerformanceDetail,
            hidden: true,
          },
          {
            link: '/performance/new',
            title: '新增绩效方案',
            component: PerformanceNew,
            hidden: true,
          },
          // {
          //   link: '/longfor',
          //   title: '龙湖绩效管理',
          //   component: PerformanceManagement,
          //   keepAlive: true,
          // },
          // {
          //   link: '/performance3',
          //   title: '贝壳绩效管理',
          //   component: PerformanceManagement,
          //   keepAlive: true,
          // },
          {
            permission: ['001184001', '001183001'],
            link: '/performance-longhu',
            title: '龙湖绩效管理',
            component: Longhu,
            keepAlive: true,
          },
          {
            link: '/performance-longhu/new',
            title: '新增龙湖绩效',
            component: LonghuPerformanceNew,
            hidden: true,
          },
          {
            link: '/performance-longhu/detail',
            title: '龙湖绩效详情',
            component: LonghuPerformanceDetail,
            hidden: true,
          },
          {
            permission: ['001185001', '001186001'],
            link: '/performance-beike',
            title: '贝壳绩效管理',
            component: Beike,
            keepAlive: true,
          },
          {
            permission: ['001185002'],
            link: '/performance-beike/steward-performance',
            title: '管家绩效详情',
            component: BeikeStewardPerformanceDetail,
            hidden: true,
          },
          {
            permission: ['003186002', '001186002'],
            link: '/performance-beike/add-performance',
            title: '新增绩效方案',
            component: BeikeAddPerformance,
            hidden: true,
          },
          {
            permission: '001187001',
            link: '/performance-salary',
            title: '额外奖惩管理',
            component: RewardAndPunishmentList,
            keepAlive: true,
          },
          {
            permission: '001188001',
            link: '/performance-conf',
            title: '绩效方案配置',
            component: PerformanceConfiguration,
            keepAlive: true,
          },
          {
            permission: '001203001',
            link: '/performance-rule-set-list',
            title: '绩效规则配置',
            component: PerformanceRuleSetList,
            keepAlive: true,
          },
          {
            link: '/performance-rule-set-list/set',
            title: '绩效规则配置',
            component: PerformanceRuleSet,
            hidden: true,
          },
        ],
      },
    ],
  },
  {
    title: '综合管理',
    icon: <LaptopOutlined />,
    sub: [
      {
        permission: '001031001',
        link: '/sys-user',
        title: '后台人员管理',
        component: SysUserListPage,
        keepAlive: true,
      },
      {
        permission: '001032001',
        link: '/sys-org',
        title: '组织管理',
        component: OrgManage,
      },
      {permission: '001193001', link: '/operation-org', title: '运营组织管理', component: OperationOrgManage},
      {
        title: '报表管理',
        sub: [
          {
            permission: '001103001',
            link: '/report-management',
            title: '报表管理',
            component: ReportManagement,
          },
          {
            permission: '007103002',
            link: '/statistics-report',
            title: '统计报表',
            component: StatisticsReport,
          },
          {
            permission: '007103002',
            link: '/download',
            title: '下载中心',
            component: DownLoadCenter,
          },
          {
            permission: '001166001',
            link: '/operation-report',
            title: '运营报表',
            component: OperationReport,
          },
          // {
          //   // permission: '007103002',
          //   link: '/service-report',
          //   title: '服务单明细',
          //   component: ServiceOrderReport,
          // },
          // {
          //   // permission: '007103002',
          //   link: '/monthly-report',
          //   title: '包月明细',
          //   component: MonthlyReport,
          // },
        ],
      },
      {
        link: '/sys-user/add',
        title: '添加用户',
        component: SysUserAddPage,
        hidden: true,
      },
      {
        permission: '001037002',
        link: '/community',
        title: '小区数据维护',
        component: CommunityDataList,
        keepAlive: true,
      },
      {
        link: '/community/detail',
        title: '小区详情',
        component: CommunityDataDetail,
        hidden: true,
      },
      {
        permission: '001094001',
        link: '/apartment',
        title: '公寓定位维护',
        component: ApartmentDataList,
        keepAlive: true,
      },
      {
        link: '/apartment/detail',
        title: '公寓详情',
        component: ApartmentDataDetail,
        hidden: true,
      },
      {
        permission: ['001025001', '001061001'],
        title: '采购管理',
        link: '/purchase',
        component: PurchaseManagement,
        keepAlive: true,
      },
      {
        // permission: ['001025001', '001061001'],
        title: '采购模版设置',
        link: '/purchase/template-setting',
        component: PurchaseTemplateSetting,
        hidden: true,
      },
      {
        permission: '001062001',
        title: '收货地址管理',
        link: '/address',
        component: AddressManagement,
      },
      {
        permission: '001026001',
        title: '物品管理',
        link: '/goods',
        component: GoodsManagement,
        keepAlive: true,
      },
      {
        permission: ['001105001', '001108001', '001126001'],
        title: '内容运营管理',
        link: '/content',
        component: ContentOperateManage,
        keepAlive: true,
      },
      {
        title: '新增banner',
        link: '/content/add-banner',
        component: AddBanner,
        hidden: true,
      },
      {title: '添加公告', link: '/content/add-notice', component: AddNotice, hidden: true},
      {
        permission: '001044001',
        link: '/qa',
        title: 'QA管理',
        component: QAManagement,
        keepAlive: true,
      },
      {
        title: '通话相关',
        sub: [
          {
            permission: '001053001',
            link: '/virtual-number',
            title: '虚拟号码管理',
            component: VirtualNumberManage,
          },
        ],
      },
      {
        permission: '001190001',
        title: '备用金列表',
        link: '/imprest-apply',
        component: ImprestApply,
        keepAlive: true,
      },
      {
        title: '备用金详情',
        link: '/imprest-apply/detail',
        component: ImprestApplyDetail,
        keepAlive: false,
        hidden: true,
      },
      {
        title: '上线通知',
        permission: '001204001',
        link: '/publish-notification-list',
        component: PublishNotificationList,
        keepAlive: true,
      },
    ],
  },
  {
    title: '管理员管理',
    icon: <DesktopOutlined />,
    sub: [
      {
        permission: ['001163001', '001163002', '001163003'],
        link: '/sys_rule_config',
        title: '系统相关规则配置',
        component: SysRuleConfig,
        keepAlive: true,
      },
      {
        permission: '001169001',
        link: '/sys-black-list',
        title: '系统黑名单管理',
        component: SysBlackList,
        keepAlive: true,
      },
      {
        permission: '001030001',
        link: '/lessor',
        title: '房东用户管理',
        component: LessorList,
        keepAlive: true,
      },
      {
        link: '/lessor/addoredit',
        title: '添加编辑房东用户',
        component: AddorEditLessor,
        hidden: true,
      },
      {
        link: '/lessor/detail',
        title: '房东信息',
        component: LessorDetail,
        hidden: true,
      },
      {
        permission: '001008001',
        link: '/lessee',
        title: '租客用户管理',
        component: LesseeList,
        keepAlive: true,
      },
      {
        link: '/lessee/addoredit',
        title: '添加编辑租客用户',
        component: AddorEditLessee,
        hidden: true,
      },
      {
        link: '/lessee/detail',
        title: '租客信息',
        component: LesseeDetail,
        hidden: true,
      },
      {
        permission: '001034001',
        link: '/sys-role',
        title: '用户组管理',
        component: SysRoleListPage,
        keepAlive: true,
      },
      {
        link: '/sys-role/add',
        title: '添加用户组',
        component: SysRoleAddPage,
        hidden: true,
        keepAlive: true,
      },
      {
        link: '/oms-permission',
        title: '集中式权限',
        component: SetPermissionIdList,
        hidden: true,
      },
      {
        permission: '001136001',
        link: '/white-list',
        title: '客户白名单管理',
        component: CustomerWhiteList,
      },
      {
        permission: '001035001',
        link: '/sys-permission',
        title: '权限管理',
        component: SysPermissionListPage,
      },
      {
        permission: '001036001',
        link: '/sys-code',
        title: '码表配置',
        component: SysCode,
      },
      {
        permission: '001161001',
        link: '/open-app-list',
        title: '维修开放平台管理',
        component: OpenAppManageList,
      },
      {
        link: '/open-app-list/add',
        title: '新增接入方',
        component: AddOpenApp,
        hidden: true,
      },
      {
        link: '/open-app-list/detail',
        title: '接入方详情',
        component: OpenAppDetail,
        hidden: true,
      },
      {
        link: '/dashboard',
        title: '数字大屏',
        component: DashboardPage,
      },
    ],
  },
  // {title: '意见反馈', icon: <MailOutlined />, sub: []},
  {
    title: '日志管理',
    icon: <CalendarOutlined />,
    sub: [
      {
        permission: '001055001',
        link: '/log-list',
        title: '管家分配日志',
        component: LogList,
      },
    ],
  },
  {
    title: '项目管理',
    icon: <CopyOutlined />,
    sub: [
      {
        permission: '001077001',
        link: '/engineering',
        title: '工程管理',
        component: EngineeringManagement,
        keepAlive: true,
      },
      {
        link: '/engineering/add',
        title: '工程管理',
        component: EngineeringAdd,
        hidden: true,
      },
      {
        link: '/engineering/detail',
        title: '工程管理',
        component: EngineeringDetail,
        hidden: true,
      },
      {
        link: '/engineering/node-detail',
        title: '工程管理',
        component: EngineeringNodeDetail,
        hidden: true,
      },
      {
        permission: '001076001',
        link: '/node',
        title: '节点管理',
        component: NodeManagement,
      },
    ],
  },
  {
    title: '集中式项目管理',
    icon: <SnippetsOutlined />,
    sub: [
      {
        title: 'CRM管理',
        sub: [
          {
            permission: ['001109001', '001110001', '001111001', '001120001'],
            link: '/crm',
            title: '集中式客户',
            component: CRMClientList,
          },
          {
            link: '/crm/add-client',
            title: '新增客户',
            component: AddClient,
            hidden: true,
          },
          {
            link: '/crm/add-look',
            title: '新增带看单',
            component: AddLookOrder,
            hidden: true,
          },
          {
            link: '/crm/client-detail',
            title: '客户详情',
            component: CRMClientDetail,
            hidden: true,
          },
          {
            permission: ['001112001', '003112002'],
            link: '/crm-look',
            title: '带看单管理',
            component: CRMLookOrder,
            keepAlive: true,
          },
          {
            link: '/crm-look/detail',
            title: '带看单详情',
            component: CRMLookDetail,
            hidden: true,
          },
        ],
      },
      {
        title: '房源推广',
        sub: [
          {
            permission: '001116001',
            link: '/promotion',
            title: '推广管理',
            component: PromotionManagement,
          },
          {
            link: '/promotion/property-info',
            title: '房源信息',
            component: PropertyInfo,
            hidden: true,
          },
          {
            permission: '001117001',
            link: '/store-manage',
            title: '门店管理',
            component: StoreManagement,
          },
          {
            link: '/store-manage/add',
            title: '新增门店',
            component: AddStore,
            hidden: true,
          },
          {
            link: '/store-manage/info',
            title: '门店信息',
            component: StoreManagementInfo,
            hidden: true,
            keepAlive: true,
          },
          {
            link: '/store-manage/add-agent',
            title: '新增人员',
            component: StoreAddAgentSteward,
            hidden: true,
          },
          {
            link: '/store-manage/apartment-detail',
            title: '公寓信息',
            component: StoreApartmentManageDetail,
            hidden: true,
          },
          {
            link: '/store-manage/property-info',
            title: '房源信息',
            component: StorePropertyInfo,
            hidden: true,
            keepAlive: true,
          },
          {
            link: '/store-manage/property-detail',
            title: '房源信息',
            component: StorePropertyDetail,
            hidden: true,
          },
          {
            link: '/store-manage/property-template',
            title: '添加户型模板',
            component: StorePorpertyTemplate,
            hidden: true,
          },
        ],
      },
      {
        permission: '001073001',
        link: '/concentrate-pm',
        title: '项目管理',
        component: ConcentrateProjectList,
        keepAlive: true,
      },
      {
        link: '/concentrate-pm/add',
        title: '创建项目',
        component: AddProject,
        hidden: true,
      },
      {
        link: '/concentrate-pm/detail',
        title: '项目详情',
        component: ProjectDetail,
        hidden: true,
      },
      {
        permission: '001088001',
        link: '/data-statistics',
        title: '数据统计',
        component: DataStatistics,
      },
      {
        permission: ['001098001', '001099001'],
        link: '/condo-team-manage',
        title: '公寓团队管理',
        component: CondoTeamManage,
        keepAlive: true,
      },
      {
        link: '/condo-team-manage/add-post',
        title: '新增岗位',
        component: AddPost,
        hidden: true,
      },
      {
        permission: '001100001',
        link: '/finance-company',
        title: '财务公司',
        component: FinanceCompany,
        keepAlive: true,
      },
      {
        link: '/finance-company/add',
        title: '新增财务公司',
        component: AddFinanceCompany,
        hidden: true,
      },
      {
        link: '/concentrate-pm/set',
        title: '项目设置',
        component: ProjectSet,
        hidden: true,
        // keepAlive: true,
      },
      {
        link: '/concentrate-pm/template',
        title: '添加户型模板',
        component: AddHouseTemplate,
        hidden: true,
      },
      {
        link: '/concentrate-pm/add-room',
        title: '添加房间',
        component: AddRoom,
        hidden: true,
      },
      {
        permission: '001101001',
        link: '/property-discount',
        title: '房价码优惠',
        component: PropertyDiscount,
      },
    ],
  },
  {
    title: '保洁运营管理',
    icon: <BarChartOutlined />,
    sub: [
      {
        permission: '001147001',
        link: '/clean-service',
        title: '保洁服务单',
        component: CleanServiceAll,
        keepAlive: true,
      },
      {
        permission: '001143001',
        link: '/service-clean-confirm',
        title: '保洁确认',
        component: ServiceCleanConfirm,
        keepAlive: true,
      },
      {
        permission: '001137001',
        link: '/clean-will-dispatch',
        title: '待派单',
        component: CleanServiceWillDispatch,
        keepAlive: true,
      },
      {
        permission: '001138001',
        link: '/clean-dispatch',
        title: '已派单',
        component: CleanServiceDispatch,
        keepAlive: true,
      },
      {
        permission: '001139001',
        link: '/clean-finish',
        title: '已完成',
        component: CleanServiceFinish,
        keepAlive: true,
      },
      {
        permission: '001144001',
        link: '/service-clean-quality',
        title: '保洁质检',
        component: ServiceCleanQuality,
        keepAlive: true,
      },
      {
        permission: '001140001',
        link: '/clean-deduct-confirm',
        title: '扣款确认',
        component: CleanServiceDeductConfirm,
        keepAlive: true,
      },
      {
        permission: '001141001',
        link: '/clean-quality',
        title: '现场质检',
        component: CleanServiceQuality,
        keepAlive: true,
      },
      {
        permission: '001142001',
        link: '/clean-cancel',
        title: '已取消',
        component: CleanServiceCancel,
        keepAlive: true,
      },
      {
        link: '/clean-detail',
        title: '服务单详情',
        component: CleanServiceDetail,
        hidden: true,
      },
      {
        link: '/service-clean-confirm/detail',
        title: '服务单详情',
        component: ServiceCleanConfirmDetail,
        hidden: true,
      },
    ],
  },
  {
    title: '集中式saas',
    icon: <CustomerServiceOutlined />,
    sub: [
      {
        permission: '001164002',
        link: '/company-list',
        title: '公司管理',
        component: CompanyList,
        keepAlive: true,
      },
      {
        // permission: '001132001',
        link: '/company-insert',
        title: '新增公司',
        component: CompanyInsert,
        hidden: true,
        keepAlive: true,
      },
      {
        // permission: '001132001',
        link: '/company-detail',
        title: '公司详情',
        component: CompanyDetail,
        hidden: true,
        keepAlive: true,
      },
      {
        // permission: '001132001',
        link: '/company-permission',
        title: '系统使用模块',
        component: CompanyPermission,
        hidden: true,
      },
    ],
  },
  {
    title: '客服管理',
    icon: <CustomerServiceOutlined />,
    sub: [
      {
        permission: '001171001',
        link: '/service-data',
        title: '服务数据看板',
        component: ServiceDataBoard,
      },
      {
        permission: '001168001',
        link: '/todo',
        title: '我的待办',
        component: MineTodo,
      },
      {
        permission: '001167001',
        link: '/todo-management',
        title: '待办管理',
        component: TodoManagement,
      },
      {
        permission: '001132001',
        link: '/service-remote-apply',
        title: '远程指导单申请',
        component: ServiceRemoteApply,
      },
      {
        permission: '001162001',
        link: '/open-app-service',
        title: '第三方服务单',
        component: OpenAppService,
      },
      {
        permission: '001160001',
        link: '/appliances',
        title: '家电信息审核',
        component: AppliancesList,
      },
      {
        permission: '001133001',
        title: '休息日新单',
        link: '/rest-day',
        component: RestDayOrder,
        keepAlive: true,
      },
      {
        permission: '001134001',
        link: '/service-cancel-apply',
        title: '取消服务申请',
        component: ServiceCancelApply,
      },
      {
        permission: '001060001',
        link: '/client-docking',
        title: '客户对接',
        component: ClientDocking,
      },
      {
        permission: '001029001',
        link: '/feedback',
        title: '意见反馈',
        component: FeedBackList,
        keepAlive: true,
      },
      {
        link: '/feedback/detail',
        title: '意见反馈详情',
        component: FeedBackDetail,
        hidden: true,
      },
      {
        permission: '001054001',
        link: '/call-manage',
        title: '呼叫管理',
        component: CallManage,
      },
      {
        permission: '001063001',
        title: '批量创建记录',
        link: '/order-batch-list',
        component: OrderBatchList,
        keepAlive: true,
      },
      {
        permission: '003063002',
        title: '新建申请',
        link: '/order-batch-list/add',
        component: OrderBatchAdd,
        hidden: true,
      },
      {
        permission: '003041002',
        title: '创建服务单',
        link: '/steward_orderAdd',
        component: StewardOrderAdd,
      },
      {
        permission: '001119001',
        link: '/cooperation',
        title: '合作意向',
        component: CooperationList,
      },
      {
        permission: ['001173001', '001174001', '001175001'],
        link: '/complaint',
        title: '投诉工单',
        component: ComplaintWorkOrder,
        keepAlive: true,
      },
      {
        link: '/complaint/add',
        title: '新增工单',
        component: AddComplaintOrder,
        hidden: true,
      },
      {
        link: '/complaint/pay',
        title: '赔偿工单',
        component: PayOrder,
        hidden: true,
      },
      {
        link: '/complaint/punish',
        title: '处罚工单',
        component: PunishOrder,
        hidden: true,
      },
      {
        link: '/complaint/detail',
        title: '工单详情',
        component: ComplaintDetail,
        hidden: true,
      },
    ],
  },
  {
    title: '深圳安居',
    icon: <DribbbleOutlined />,
    sub: [
      {
        permission: '001177019',
        link: '/anju-project-list',
        title: '项目管理',
        component: AnJuProjectList,
      },
      {
        permission: '001177001',
        link: '/anju-property',
        title: '房源管理',
        component: AnJuPropertyListManage,
      },
      {
        permission: '001177008',
        link: '/anju-unit',
        title: '质保单位',
        component: AnJuQualityUnit,
      },
      {
        permission: '001177020',
        link: '/anju-repair',
        title: '报修确认',
        component: AnjuRepairList,
      },
      {
        permission: '001177011',
        link: '/anju-project',
        title: '质保项目',
        component: AnJuQualityProject,
      },
      {
        permission: '001177005',
        link: '/anju-service-list',
        title: '服务单列表',
        component: AnJuServiceList,
        keepAlive: true,
      },
      {
        permission: '001177007',
        link: '/anju-service-sum',
        title: '服务数据统计',
        component: AnJuServiceStatistics,
      },
    ],
  },
  {
    title: '龙湖冠寓',
    icon: <DribbbleOutlined />,
    sub: [
      {
        permission: ['001081001', '001081002'],
        link: '/settlement-record',
        title: '结算记录',
        component: SettlementRecord,
        keepAlive: true,
      },
      {
        link: '/settlement-detail',
        title: '结算详情',
        component: SettlementDetail,
        hidden: true,
      },
    ],
  },
]

function useRenderMenu(rs: Routers[]) {
  const permission = userModel.useData(data => data.permission)
  const filterRouters = useCallback(
    (_routes: Routers[]) => {
      _routes = _routes.map(routes => {
        if (routes.sub?.length) {
          return {...routes, sub: filterRouters(routes.sub)}
        }
        return routes
      })

      return _routes.filter(route => {
        if (route.hidden) return false
        if (route.sub?.length) return true
        if (route.component) {
          if (!route.permission || hasPermission(route.permission, permission)) {
            return true
          }
        }

        return false
      })
    },
    [permission]
  )

  return useMemo(() => filterRouters(rs), [filterRouters, rs])
}

function AppLayout() {
  const history = useHistory()
  const token = userModel.useData(data => data.token)

  useEffect(() => {
    if (!token) {
      window.location.pathname = '/login'
    }
  }, [token])

  const userInfo = userModel.useData(data => data.userInfo)
  const permission = userModel.useData(data => data.permission)
  const [form] = Form.useForm()
  const loading = Model.isLoading(userModel.sysUserUpdatePassword.loading, userModel.logout.loading)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(userInfo.updatePasswordTimeEnd === null)
  }, [userInfo.updatePasswordTimeEnd])

  const skip = false
  // const skip = true

  const renderMenu = useRenderMenu(routers)
  const renderRouter = useMemo(
    () =>
      routers
        .flatMap(route => (route.sub?.length ? route.sub : route))
        .flatMap(route => (route.sub?.length ? route.sub : route))
        .filter(it => skip || !it.permission || hasPermission(it.permission, permission)),
    [permission, skip]
  )
  // console.log('renderMenu', renderMenu)
  // console.log('renderRouter', renderRouter)

  let path = window.location.pathname
  if (path.indexOf('/', 5) > 0) {
    // 如果是二层路径，取第一层路径
    path = path.substring(0, path.indexOf('/', 5))
  }

  function logout() {
    confirm({
      title: '提示',
      icon: <ExclamationCircleFilled />,
      content: '是否确认退出登录？',
      onOk() {
        userModel.logout()
      },
    })
  }

  const defaultOpenKey = useMemo(() => {
    const pathname = window.location.pathname
    return routers.find(value => {
      if (value.sub) {
        return value.sub.some(item => item.link === pathname)
      }
      return value.link === pathname
    })?.title
  }, [])

  // const skip = true

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Layout.Sider breakpoint={'md'} width={256}>
        <div style={{color: 'white', textAlign: 'center', fontSize: '32px', marginBottom: 32}}>
          <img src={logo} width={113} height={24} alt={''} />
        </div>
        <Menu theme='dark' mode='inline' defaultOpenKeys={[defaultOpenKey]} selectedKeys={[path]}>
          {renderMenu
            .filter(it => skip || !it.hidden)
            .map(it => {
              if (it.component) {
                // 一级菜单
                return (
                  <Menu.Item key={it.link} icon={it.icon}>
                    <Link to={it.link}>{it.title}</Link>
                  </Menu.Item>
                )
              } else if (it.sub?.length) {
                return (
                  <Menu.SubMenu key={it.title} title={it.title} icon={it.icon}>
                    {it.sub
                      .filter(it => skip || !it.hidden)
                      .map(item => {
                        if (item.sub?.length) {
                          return (
                            <Menu.SubMenu key={item.title} title={item.title}>
                              {item.sub
                                .filter(i => skip || !i.hidden)
                                .map(ele => {
                                  return (
                                    <Menu.Item key={ele.link}>
                                      <Link to={ele.link}>{ele.title}</Link>
                                    </Menu.Item>
                                  )
                                })}
                            </Menu.SubMenu>
                          )
                        } else {
                          return (
                            <Menu.Item key={item.link}>
                              <Link to={item.link}>{item.title}</Link>
                            </Menu.Item>
                          )
                        }
                      })}
                  </Menu.SubMenu>
                )
              }
            })}
        </Menu>
      </Layout.Sider>
      <Layout>
        <Layout.Header className={styles.header}>
          <Row justify='space-between'>
            <Col span={6}>{/*token:{token}*/}</Col>

            <Col>
              <Space size={20}>
                <MessageBadge />

                <Dropdown
                  menu={{
                    items: [
                      {key: '修改密码', label: '修改密码', onClick: () => history.push('/updatePwd')},
                      {key: '退出登录', label: '退出登录', onClick: () => logout()},
                    ],
                  }}
                >
                  <div>
                    <Avatar size='small' icon={<UserOutlined />} />
                    <span style={{cursor: 'pointer'}}> {userInfo.name}</span>
                  </div>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content
        // style={{margin: '0 20px'}}
        // className={commonStyles.content}
        >
          <Route exact path={'/'}>
            <Redirect to={'/home'} />
          </Route>
          {renderRouter.map(route => {
            const path = route.link
            const title = route.title
            return route.keepAlive ? (
              <KeepAliveRoute
                key={`${path}${title}`}
                exact
                path={path}
                component={route.component}
                style={route.style}
              />
            ) : (
              <Route key={`${path}${title}`} exact path={path} component={route.component} />
            )
          })}
        </Layout.Content>
      </Layout>

      <Modal
        open={open}
        title={'设定初始密码'}
        centered
        footer={null}
        maskClosable={false}
        width={600}
        destroyOnClose
        closeIcon={<></>}
      >
        <Form
          form={form}
          onFinish={async () => {
            const newPwd = form.getFieldValue('newPwd')
            await userModel.sysUserUpdatePassword({newPwd})
            message.success('密码设置成功，请重新登录！')
            setOpen(false)
            form.resetFields()
            await userModel.logout()
          }}
          labelCol={{flex: '100px'}}
          wrapperCol={{span: 16}}
        >
          <Form.Item colon={false} label={' '}>
            <div style={{background: '#FFF5E1', padding: '10px 0px 10px 15px'}}>
              <Space>
                <ExclamationCircleOutlined style={{color: '#FAAD14'}} />
                <span>为了账户安全起见，首次登录必须更改初始密码。</span>
              </Space>
            </div>
          </Form.Item>
          <Form.Item name={'newPwd'} label={'新密码'} rules={[{required: true, message: '请输入新密码'}]}>
            <Input.Password allowClear />
          </Form.Item>
          <Form.Item
            name={'confirmPwd'}
            label={'确认密码'}
            rules={[
              {required: true, message: '请确认新密码'},
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPwd') === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject(new Error('确认密码不一致，请重新填写'))
                },
              }),
            ]}
          >
            <Input.Password allowClear />
          </Form.Item>
          <Row justify={'center'}>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              提交
            </Button>
          </Row>
        </Form>
      </Modal>

      <PublishNotification />
    </Layout>
  )
}

export default AppLayout

type KeepAliveRouteProps = {
  style?: React.CSSProperties
  className?: string
} & RouteProps

/**
 * 保持状态的Route
 */
function KeepAliveRoute({style, className, path, exact /* 排除 exact 的副作用 */, ...rest}: KeepAliveRouteProps) {
  const location = useLocation()
  const [isInit, setIsInit] = useState(false)
  const pathname = location.pathname

  const {cache, add} = useCacheManager()

  // 删除 key，设置 isInit 为 false
  useEffect(() => {
    const p: string = Array.isArray(path) ? path[0] : path
    if (isInit && pathname !== path && !cache.includes(p)) {
      setIsInit(false)
    }
  }, [add, cache, isInit, path, pathname])

  // 添加 cache key
  useEffect(() => {
    const p: string = Array.isArray(path) ? path[0] : path
    if (isInit && pathname === path && !cache.includes(p)) {
      add(p)
    }
  }, [add, cache, isInit, path, pathname])

  useEffect(() => {
    if (pathname === path && !isInit) {
      setIsInit(true)
    }
  }, [pathname, path, isInit])

  return !isInit ? null : (
    <div className={className} style={{...style, ...(pathname !== path ? {display: 'none'} : {})}}>
      {/*path=*，防止Router内部自动销毁*/}
      <Route path={'*'} {...rest} />
    </div>
  )
}
