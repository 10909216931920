import React, {useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  InputNumber,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {stewardModel} from '@/store/models/Steward'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import {ExclamationCircleOutlined, MinusCircleFilled, PlusCircleFilled} from '@ant-design/icons'
import {getCascaderLeafValues, getCascaderValueByLeafValues} from '@/utils/tree'
import moment from 'moment/moment'
import {Model} from '@redux-model/web'

const performanceTypeOptions = [
  {name: 'serviceCommissionEnable', label: '服务单提成'},
  {name: 'invalidEnable', label: '无效单'},
  {name: 'violationEnable', label: '违规单'},
]

const perOrderChargeType = [
  {label: '额外费用提成', value: '1'},
  {label: '计算单量倍数', value: '2'},
]

const numOptions = [
  {label: '2', value: 2},
  {label: '3', value: 3},
]

const hours = Array.from({length: 20}, (v, k) => {
  const value = (k + 1) * 12
  return {label: `${value}`, value: value * 60} // 后端需要分钟
})

const minutes = Array.from({length: 48}, (v, k) => {
  const value = (k + 1) * 5
  return {label: `${value}`, value: value} // 后端需要分钟
})

const handleResultType = [
  {label: '已完成服务', value: '1'},
  {label: '无需服务', value: '2'},
  {label: '无法完成服务', value: '3'},
]

// 1.下单时间、2.首次联系时间、3.预约时间、4.出发时间、5.到达时间、6.完成时间
const timeType = [
  {label: '下单时间', value: '1'},
  {label: '首次联系时间', value: '2'},
  {label: '预约时间', value: '3'},
  {label: '出发时间', value: '4'},
  {label: '到达时间', value: '5'},
  {label: '完成时间', value: '6'},
]

export default function PerformanceRuleSet() {
  const history = useAppHistory()
  const location = useAppLocation<'/performance-rule-set-list/set'>()
  const {viewOnly, ruleId} = location.state ?? {}
  const [form] = Form.useForm()
  const [cityList, setCityList] = useState<CityDataProps[]>([])
  const [openAppList, setOpenAppList] = useState<StewardOpenAppVO[]>([])
  const feeType = useCodeArraySync('steward.fee.type')
  const [stewardServiceList, setStewardServiceList] = useState<RecursionOption[]>([])
  const loading = Model.isLoading(
    stewardModel.stewardSalaryRuleInsert.useLoading(),
    stewardModel.stewardSalaryRuleUpdate.useLoading()
  )

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))

    stewardModel
      .stewardOpenAppList({
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appStatus: 1,
      } as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenAppList(data)
      })

    stewardModel.stewardAdminServiceTree({} as StewardServiceQueryDTO).then(({response: {data}}) => {
      setStewardServiceList(transformTree(data))
    })
  }, [])

  useEffect(() => {
    if (ruleId) {
      stewardModel.stewardSalaryRuleDetail({ruleId}).then(({response: {data}}) => {
        form.setFieldsValue({
          ...data,
          cityCodeList: data.cityList.map(item => item.key),
          appIdList: data.appList.map(item => item.key),
        })
      })
    }
  }, [ruleId, form])

  return (
    <Page breadcrumb={<Breadcrumb />}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '150px'}}
          wrapperCol={{span: 12}}
          initialValues={{
            serviceCommissionEnable: 0,
            serviceCommissionList: [{}],
            minServiceCommissionList: [{}],
            serviceCommissionMethod: '1',
            specialServiceCommissionList: [{}],
            violationEnable: 0,
            violationRuleList: [{}],
            invalidEnable: 0,
            invalidOrderType: {
              categoryThiList: [{}],
              invalidOrderTypeTwoList: [{}],
            },
          }}
          onFinish={() => {
            Modal.confirm({
              title: '提示',
              icon: null,
              centered: true,
              content: '确认提交？',
              onOk: async () => {
                const values: StewardSalaryRuleUpsertDTO = form.getFieldsValue(true)
                if (ruleId) {
                  await stewardModel.stewardSalaryRuleUpdate(values)
                } else {
                  await stewardModel.stewardSalaryRuleInsert(values)
                }
                message.success('操作成功')
                history.goBack()
              },
            })
          }}
          style={{pointerEvents: viewOnly ? 'none' : 'auto'}}
        >
          <Form.Item
            label={'适用城市'}
            name={'cityCodeList'}
            rules={[{required: ruleId !== '1', message: '请选择适用城市'}]}
          >
            {ruleId === '1' ? (
              <span>所有城市</span>
            ) : (
              <Select
                options={cityList}
                fieldNames={{label: 'name', value: 'code'}}
                placeholder={'请选择'}
                showSearch
                mode={'multiple'}
                filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
                allowClear
              />
            )}
          </Form.Item>
          <Form.Item
            label={'适用渠道'}
            name={'appIdList'}
            rules={[{required: ruleId !== '1', message: '请选择适用渠道'}]}
          >
            {ruleId === '1' ? (
              <span>所有渠道</span>
            ) : (
              <Select
                options={openAppList}
                fieldNames={{value: 'appId', label: 'appName'}}
                placeholder={'请选择'}
                mode={'multiple'}
                allowClear
              />
            )}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {f => {
              const {violationEnable, serviceCommissionEnable, invalidEnable} = f.getFieldsValue(true)
              return (
                <Form.Item
                  label={'绩效类型'}
                  name={' '}
                  rules={[
                    {
                      required: true,
                      validator: (_, value, callback) => {
                        if ([violationEnable, serviceCommissionEnable, invalidEnable].every(item => item === 0))
                          return callback('请选择绩效类型')
                        return callback()
                      },
                    },
                  ]}
                >
                  <Space>
                    {performanceTypeOptions.map(value => {
                      return (
                        <Form.Item
                          key={value.name}
                          noStyle
                          name={value.name}
                          getValueFromEvent={event => (event.target.checked ? 1 : 0)}
                          getValueProps={value => ({checked: value === 1})}
                          valuePropName={'checked'}
                        >
                          <Checkbox>{value.label}</Checkbox>
                        </Form.Item>
                      )
                    })}
                  </Space>
                </Form.Item>
              )
            }}
          </Form.Item>
          {/*服务单提成*/}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.serviceCommissionEnable !== nextValues.serviceCommissionEnable
            }
          >
            {f => {
              const serviceCommissionEnable = f.getFieldValue('serviceCommissionEnable')
              return (
                serviceCommissionEnable === 1 && (
                  <>
                    <Space size={12}>
                      <Typography.Title level={5} style={{marginBottom: 0}}>
                        服务单提成
                      </Typography.Title>
                      <Button
                        type={'link'}
                        onClick={() =>
                          form.resetFields([
                            'serviceCommissionMethod',
                            'specialServiceCommissionList',
                            'serviceCommissionList',
                            'minServiceCommission',
                            'overBudgetCommissionEnable',
                          ])
                        }
                      >
                        重置
                      </Button>
                      <Popover
                        arrowPointAtCenter
                        placement={'topLeft'}
                        content={
                          <pre>{`①单个服务单提成为所选该服务单各收费项的提成总和
②按收费类目：1、固定金额：该收费项提成=固定金额
           2、按比例：①抵扣耗材核算价：该收费项提成=（收费项价格-该收费项所选耗材核算总价）*该收费项设置的提成比例
                    ②不抵扣耗材核算价：该收费项提成=收费项价格*该收费项设置的提成比例
③按总费用的百分比：该单提成=（该单总费用-该单所选耗材核算总价）*绩效方案里设置的比例
④特殊提成（若选中）、常规提成按顺序选其中一种方式计算提成，若常规提成设置了单量倍数，提成即为0，计算结束；
⑤特殊提成和常规提成按顺序选其中一种方式计算出提成后（非单量倍数），按最低提成、支出大于收入（若选中）顺序计算提成；
`}</pre>
                        }
                      >
                        <ExclamationCircleOutlined />
                      </Popover>
                    </Space>
                    <Form.Item label={'提成计算方式'} name={'serviceCommissionMethod'} rules={[{required: true}]}>
                      <Radio.Group
                        options={[
                          {label: '按收费类目', value: '2'},
                          {label: '按总费用的百分比', value: '1'},
                        ]}
                      />
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, nextValues) =>
                        prevValues.serviceCommissionMethod !== nextValues.serviceCommissionMethod
                      }
                    >
                      {f => {
                        const serviceCommissionMethod = f.getFieldValue('serviceCommissionMethod')
                        return (
                          <>
                            {/*特殊提成*/}
                            {serviceCommissionMethod === '2' && (
                              <Form.List name={'specialServiceCommissionList'}>
                                {(fields, operation) => {
                                  return fields.map((field, index, array) => {
                                    return (
                                      <Form.Item
                                        label={index === 0 ? '特殊提成' : ' '}
                                        colon={index === 0}
                                        key={field.key}
                                      >
                                        <Form.Item
                                          noStyle
                                          shouldUpdate={(prevValues, nextValues) =>
                                            prevValues?.specialServiceCommissionList?.[field.name]
                                              ?.specialCommissionSwitch !==
                                            nextValues?.specialServiceCommissionList?.[field.name]
                                              ?.specialCommissionSwitch
                                          }
                                        >
                                          {f => {
                                            const specialCommissionSwitch = !!f.getFieldValue([
                                              'specialServiceCommissionList',
                                              field.name,
                                              'specialCommissionSwitch',
                                            ])
                                            return (
                                              <Row gutter={12} style={{alignItems: 'center'}}>
                                                <Col>
                                                  <Form.Item
                                                    noStyle
                                                    name={[field.name, 'specialCommissionSwitch']}
                                                    valuePropName={'checked'}
                                                  >
                                                    <Checkbox />
                                                  </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                  <Form.Item
                                                    noStyle
                                                    name={[field.name, 'tollType']}
                                                    rules={[
                                                      {required: specialCommissionSwitch, message: '请选择收费类型'},
                                                    ]}
                                                  >
                                                    <Select
                                                      allowClear
                                                      options={feeType.map(value => ({
                                                        label: value.label,
                                                        value: value.id,
                                                      }))}
                                                      placeholder='请选择收费类型'
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                  <Form.Item
                                                    noStyle
                                                    name={[field.name, 'categoryFirList']}
                                                    rules={[
                                                      {required: specialCommissionSwitch, message: '请选择服务类型'},
                                                    ]}
                                                  >
                                                    <Select
                                                      placeholder={'请选择服务类型'}
                                                      showArrow
                                                      mode={'multiple'}
                                                      allowClear
                                                      options={stewardServiceList}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                                <Col>的提成为</Col>
                                                <Col span={4}>
                                                  <Form.Item
                                                    noStyle
                                                    name={[field.name, 'commission']}
                                                    rules={[{required: specialCommissionSwitch, message: '请输入提成'}]}
                                                  >
                                                    <InputNumber min={0} style={{width: '100%'}} placeholder='请输入' />
                                                  </Form.Item>
                                                </Col>
                                                <Col>元</Col>
                                                <Col>
                                                  <Space>
                                                    {array.length > 1 && (
                                                      <MinusCircleFilled onClick={() => operation.remove(index)} />
                                                    )}
                                                    {index + 1 === array.length && (
                                                      <PlusCircleFilled onClick={() => operation.add({})} />
                                                    )}
                                                  </Space>
                                                </Col>
                                              </Row>
                                            )
                                          }}
                                        </Form.Item>
                                      </Form.Item>
                                    )
                                  })
                                }}
                              </Form.List>
                            )}

                            {/*常规提成*/}
                            <Form.Item label={'常规提成'} required style={{marginBottom: 0}}>
                              <Form.List name={'serviceCommissionList'}>
                                {(fields, operation) => {
                                  return fields.map((field, index, array) => {
                                    return (
                                      <Form.Item
                                        key={field.key}
                                        label={`提成${index + 1}`}
                                        required
                                        style={{flexWrap: 'nowrap'}}
                                      >
                                        <Row
                                          gutter={12}
                                          wrap={false}
                                          style={{alignItems: 'center', whiteSpace: 'nowrap', width: 850}}
                                        >
                                          <Col span={4}>
                                            <Form.Item
                                              noStyle
                                              name={[field.name, 'tollType']}
                                              rules={[{required: true, message: '收费类型不能为空'}]}
                                            >
                                              <Select
                                                allowClear
                                                options={feeType.map(value => ({label: value.label, value: value.id}))}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={8}>
                                            <Form.Item
                                              noStyle
                                              name={[field.name, 'categoryFirList']}
                                              rules={[{required: true, message: '服务类型不能为空'}]}
                                              // getValueFromEvent={event => getCascaderLeafValues(stewardServiceList, event)}
                                              // getValueProps={value => ({
                                              //   value: getCascaderValueByLeafValues(stewardServiceList, value),
                                              // })}
                                            >
                                              <Select
                                                showArrow
                                                mode={'multiple'}
                                                allowClear
                                                options={stewardServiceList}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col>每单</Col>
                                          <Col span={5}>
                                            <Form.Item
                                              noStyle
                                              name={[field.name, 'perOrderChargeType']}
                                              rules={[{required: true, message: '每单收费方式不能为空'}]}
                                            >
                                              <Select
                                                allowClear
                                                options={perOrderChargeType}
                                                onChange={() => {
                                                  form.resetFields([
                                                    ['serviceCommissionList', field.name, 'coefficient'],
                                                  ])
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                          <Col span={4}>
                                            <Form.Item noStyle shouldUpdate>
                                              {() => {
                                                const perOrderCharge = form.getFieldValue([
                                                  'serviceCommissionList',
                                                  field.name,
                                                  'perOrderChargeType',
                                                ])
                                                return perOrderCharge === '1' ? (
                                                  serviceCommissionMethod !== '2' && (
                                                    <Space>
                                                      <Form.Item
                                                        noStyle
                                                        name={[field.name, 'coefficient']}
                                                        getValueFromEvent={event => event / 100}
                                                        getValueProps={value => ({value: value * 100})}
                                                        rules={[{required: true, message: '系数不能为空'}]}
                                                      >
                                                        <InputNumber min={0} placeholder='请输入' />
                                                      </Form.Item>
                                                      <span>%</span>
                                                    </Space>
                                                  )
                                                ) : (
                                                  <Form.Item
                                                    noStyle
                                                    name={[field.name, 'coefficient']}
                                                    rules={[{required: true, message: '倍数不能为空'}]}
                                                  >
                                                    <Select
                                                      allowClear
                                                      options={perOrderCharge ? numOptions : []}
                                                      placeholder='请选择'
                                                    />
                                                  </Form.Item>
                                                )
                                              }}
                                            </Form.Item>
                                          </Col>
                                          <Col span={3}>
                                            <Space>
                                              {index + 1 === array.length && (
                                                <PlusCircleFilled
                                                  onClick={() => {
                                                    operation.add({})
                                                  }}
                                                />
                                              )}
                                              {index !== 0 && index + 1 === array.length && (
                                                <MinusCircleFilled onClick={() => operation.remove(index)} />
                                              )}
                                            </Space>
                                          </Col>
                                        </Row>
                                      </Form.Item>
                                    )
                                  })
                                }}
                              </Form.List>
                            </Form.Item>

                            {/*最低提成*/}
                            {serviceCommissionMethod === '2' && (
                              <Form.Item noStyle shouldUpdate>
                                {f => {
                                  const minCommissionSwitch = !!f.getFieldValue([
                                    'minServiceCommission',
                                    'minCommissionSwitch',
                                  ])
                                  return (
                                    <Form.Item label={'最低提成'} required={minCommissionSwitch}>
                                      <Row gutter={12} style={{alignItems: 'center'}}>
                                        <Col>
                                          <Form.Item
                                            noStyle
                                            name={['minServiceCommission', 'minCommissionSwitch']}
                                            valuePropName={'checked'}
                                          >
                                            <Checkbox />
                                          </Form.Item>
                                        </Col>
                                        <Col>总费用＞0时，服务单提成不满</Col>
                                        <Col span={6}>
                                          <Form.Item
                                            noStyle
                                            name={['minServiceCommission', 'lessThan']}
                                            rules={[{required: minCommissionSwitch, message: '不满金额不能为空'}]}
                                          >
                                            <InputNumber min={0} style={{width: '100%'}} placeholder='请输入' />
                                          </Form.Item>
                                        </Col>
                                        <Col>元，按</Col>
                                        <Col span={6}>
                                          <Form.Item
                                            noStyle
                                            name={['minServiceCommission', 'commission']}
                                            rules={[{required: minCommissionSwitch, message: '提成金额不能为空'}]}
                                          >
                                            <InputNumber min={0} style={{width: '100%'}} placeholder='请输入' />
                                          </Form.Item>
                                        </Col>
                                        <Col>元算</Col>
                                      </Row>
                                    </Form.Item>
                                  )
                                }}
                              </Form.Item>
                            )}

                            {/*支出大于收入*/}
                            {serviceCommissionMethod === '2' && (
                              <Form.Item label={'支出大于收入'}>
                                <Row gutter={12}>
                                  <Col>
                                    <Form.Item
                                      noStyle
                                      name={'overBudgetCommissionEnable'}
                                      valuePropName={'checked'}
                                      getValueFromEvent={event => (event.target.checked ? 1 : 0)}
                                      getValueProps={value => ({checked: value === 1})}
                                    >
                                      <Checkbox />
                                    </Form.Item>
                                  </Col>
                                  <Col>{'【服务单提成】+【服务单耗材成本总价】 > 【总费用】时，提成为 0'}</Col>
                                </Row>
                              </Form.Item>
                            )}
                          </>
                        )
                      }}
                    </Form.Item>
                  </>
                )
              )
            }}
          </Form.Item>

          {/*无效单*/}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.invalidEnable !== nextValues.invalidEnable}
          >
            {f => {
              const invalidEnable = f.getFieldValue('invalidEnable')
              return (
                invalidEnable === 1 && (
                  <>
                    <Typography.Title level={5} style={{marginBottom: 0}}>
                      无效单
                    </Typography.Title>
                    <div style={{paddingLeft: 50}}>
                      <Form.Item
                        label={'违规是否无效'}
                        name={'violationAsInvalid'}
                        valuePropName={'checked'}
                        getValueProps={value => ({checked: value === 1})}
                        getValueFromEvent={event => (event.target.checked ? 1 : 0)}
                      >
                        <Checkbox>违规即无效</Checkbox>
                      </Form.Item>

                      <Space size={12}>
                        <Typography.Title level={5} style={{marginBottom: 0}}>
                          类型一
                        </Typography.Title>
                        <Button
                          type={'link'}
                          onClick={() => form.resetFields([['invalidOrderType', 'categoryThiList']])}
                        >
                          重置
                        </Button>
                      </Space>

                      <Form.List name={['invalidOrderType', 'categoryThiList']}>
                        {(fields, operation) => {
                          return fields.map((field, index, array) => {
                            return (
                              <Form.Item
                                key={field.key}
                                label={`规则${index + 1}`}
                                required
                                style={{flexWrap: 'nowrap'}}
                              >
                                <Row gutter={12} wrap={false} style={{alignItems: 'center', whiteSpace: 'nowrap'}}>
                                  <Col span={10}>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, 'categoryThiList']}
                                      rules={[{required: true, message: '不能为空'}]}
                                      // getValueFromEvent={event => getCascaderLeafValues(stewardServiceList, event)}
                                      // getValueProps={value => ({
                                      //   value: getCascaderValueByLeafValues(stewardServiceList, value),
                                      // })}
                                    >
                                      <Select
                                        showArrow
                                        mode={'multiple'}
                                        allowClear
                                        options={stewardServiceList}
                                        placeholder='请选择'
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>的处理结果为</Col>
                                  <Col span={10}>
                                    <Form.Item noStyle name={[field.name, 'handleResultType']}>
                                      <Select allowClear options={handleResultType} placeholder='请选择' />
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Space>
                                      {index + 1 === array.length && (
                                        <PlusCircleFilled
                                          onClick={() => {
                                            operation.add({})
                                          }}
                                        />
                                      )}
                                      {index !== 0 && index + 1 === array.length && (
                                        <MinusCircleFilled onClick={() => operation.remove(index)} />
                                      )}
                                    </Space>
                                  </Col>
                                </Row>
                              </Form.Item>
                            )
                          })
                        }}
                      </Form.List>
                    </div>

                    <div style={{paddingLeft: 50}}>
                      <Space size={12}>
                        <Typography.Title level={5} style={{marginBottom: 0}}>
                          类型二
                        </Typography.Title>
                        <Button
                          type={'link'}
                          onClick={() => form.resetFields([['invalidOrderType', 'invalidOrderTypeTwoList']])}
                        >
                          重置
                        </Button>
                        <Popover
                          arrowPointAtCenter
                          placement={'topLeft'}
                          content={
                            <pre style={{marginBottom: 0}}>
                              {`①若勾选了计算关联房源，维修单完成后，将服务单上的房源及其所有关联房源一起计算是否无效，如：维修单上的房源为合租房源，
综合该合租房源及关联的所有合租房源一起计算是否无效
②未勾选计算关联房源，维修单完成后，只根据服务单上的房源单独计算是否无效，不计算关联房源`}
                            </pre>
                          }
                        >
                          <ExclamationCircleOutlined />
                        </Popover>
                      </Space>

                      <Form.List name={['invalidOrderType', 'invalidOrderTypeTwoList']}>
                        {(fields, operation) => {
                          return fields.map((field, index, array) => {
                            return (
                              <Form.Item
                                key={field.key}
                                label={`规则${index + 1}`}
                                required
                                style={{flexWrap: 'nowrap'}}
                              >
                                <Row gutter={12} wrap={false} style={{alignItems: 'center', whiteSpace: 'nowrap'}}>
                                  <Col span={14}>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, 'categoryThiList']}
                                      rules={[{required: true, message: '不能为空'}]}
                                      getValueFromEvent={event => getCascaderLeafValues(stewardServiceList, event)}
                                      getValueProps={value => ({
                                        value: getCascaderValueByLeafValues(stewardServiceList, value),
                                      })}
                                    >
                                      <Cascader
                                        showArrow
                                        multiple
                                        allowClear
                                        options={stewardServiceList}
                                        placeholder='请选择'
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col>完成时间距离上一单不足</Col>
                                  <Col span={5}>
                                    <Form.Item noStyle name={[field.name, 'lessThanMinutes']}>
                                      <Select allowClear options={hours} placeholder='请选择' />
                                    </Form.Item>
                                  </Col>
                                  <Col>小时</Col>
                                  <Col>
                                    <Form.Item
                                      noStyle
                                      name={[field.name, 'relatedCalculation']}
                                      valuePropName={'checked'}
                                    >
                                      <Checkbox>计算关联房源</Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={2}>
                                    <Space>
                                      {index + 1 === array.length && (
                                        <PlusCircleFilled
                                          onClick={() => {
                                            operation.add({})
                                          }}
                                        />
                                      )}
                                      {index !== 0 && index + 1 === array.length && (
                                        <MinusCircleFilled onClick={() => operation.remove(index)} />
                                      )}
                                    </Space>
                                  </Col>
                                </Row>
                              </Form.Item>
                            )
                          })
                        }}
                      </Form.List>
                    </div>
                  </>
                )
              )
            }}
          </Form.Item>

          {/*违规单*/}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.violationEnable !== nextValues.violationEnable}
          >
            {f => {
              const violationEnable = f.getFieldValue('violationEnable')
              return (
                violationEnable === 1 && (
                  <>
                    <Space>
                      <Typography.Title level={5} style={{marginBottom: 0}}>
                        违规单
                      </Typography.Title>
                      <Button
                        type={'link'}
                        onClick={() =>
                          form.resetFields(['violationCalculationMethod', 'violationLine', 'violationRuleList'])
                        }
                      >
                        重置
                      </Button>
                    </Space>
                    <Form.Item
                      label={'违规计算'}
                      name={'violationCalculationMethod'}
                      rules={[{required: true, message: '请选择违规计算'}]}
                    >
                      <Radio.Group
                        options={[
                          {label: '按单量', value: '1'},
                          {label: '违规线', value: '2'},
                        ]}
                        onChange={() => {
                          form.setFieldsValue({
                            violationRuleList: [{}],
                            violationLine: undefined,
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {f => {
                        const value = f.getFieldValue('violationCalculationMethod')
                        return value ? (
                          <>
                            {value === '2' && (
                              <Form.Item label={'实际违规线'} required>
                                <Row gutter={12} style={{alignItems: 'center'}}>
                                  <Col>实际违规单 - 有效单 *</Col>
                                  <Col span={6}>
                                    <Space>
                                      <Form.Item
                                        noStyle
                                        name={'violationLine'}
                                        rules={[{required: true, message: '请设置违规线'}]}
                                        getValueFromEvent={event => event / 100}
                                        getValueProps={value => ({value: value * 100})}
                                      >
                                        {/*<Select allowClear options={extOptions} />*/}
                                        <InputNumber min={0} placeholder='请输入' />
                                      </Form.Item>
                                      <span>%</span>
                                    </Space>
                                  </Col>
                                </Row>
                              </Form.Item>
                            )}

                            <Form.List name={'violationRuleList'}>
                              {(fields, operation) => {
                                return fields.map((field, index, array) => {
                                  return (
                                    <Form.Item
                                      key={field.key}
                                      label={`规则${index + 1}`}
                                      required
                                      style={{flexWrap: 'nowrap'}}
                                    >
                                      <Row
                                        gutter={12}
                                        wrap={false}
                                        style={{alignItems: 'center', whiteSpace: 'nowrap', width: 850}}
                                      >
                                        <Col>下单时间为</Col>
                                        <Col span={7}>
                                          <Space>
                                            <Form.Item
                                              noStyle
                                              name={[field.name, 'violationRuleTimeStart']}
                                              rules={[{required: true, message: '不能为空'}]}
                                              getValueFromEvent={(_, dataString) =>
                                                dataString ? timeToMinutes(dataString) : dataString
                                              }
                                              getValueProps={value => ({
                                                value: value
                                                  ? moment(moment().format(`YYYY-MM-DD ${minutesToTime(value)}`))
                                                  : null,
                                              })}
                                            >
                                              <TimePicker format={'HH:mm'} placeholder={'开始时间'} />
                                            </Form.Item>
                                            <Form.Item
                                              noStyle
                                              name={[field.name, 'violationRuleTimeEnd']}
                                              rules={[{required: true, message: '不能为空'}]}
                                              getValueFromEvent={(_, dataString) =>
                                                dataString ? timeToMinutes(dataString) : dataString
                                              }
                                              getValueProps={value => ({
                                                value: value
                                                  ? moment(moment().format(`YYYY-MM-DD ${minutesToTime(value)}`))
                                                  : null,
                                              })}
                                            >
                                              <TimePicker format={'HH:mm'} placeholder={'结束时间'} />
                                            </Form.Item>
                                          </Space>
                                        </Col>
                                        <Col>时</Col>
                                        <Col span={4}>
                                          <Form.Item noStyle name={[field.name, 'startNode']}>
                                            <Select allowClear options={timeType} placeholder='请选择' />
                                          </Form.Item>
                                        </Col>
                                        <Col>至</Col>
                                        <Col span={4}>
                                          <Form.Item noStyle name={[field.name, 'endNode']}>
                                            <Select allowClear options={timeType} placeholder='请选择' />
                                          </Form.Item>
                                        </Col>
                                        <Col>大于</Col>
                                        <Col span={4}>
                                          <Form.Item noStyle name={[field.name, 'nodeTime']}>
                                            <Select allowClear options={minutes} placeholder='请选择' />
                                          </Form.Item>
                                        </Col>
                                        <Col>分钟</Col>
                                        <Col span={2}>
                                          <Space>
                                            {index + 1 === array.length && (
                                              <PlusCircleFilled
                                                onClick={() => {
                                                  operation.add({})
                                                }}
                                              />
                                            )}
                                            {index !== 0 && index + 1 === array.length && (
                                              <MinusCircleFilled onClick={() => operation.remove(index)} />
                                            )}
                                          </Space>
                                        </Col>
                                      </Row>
                                    </Form.Item>
                                  )
                                })
                              }}
                            </Form.List>
                          </>
                        ) : null
                      }}
                    </Form.Item>
                  </>
                )
              )
            }}
          </Form.Item>

          {!viewOnly && (
            <Form.Item label={' '} colon={false}>
              <Space size={12}>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                  提交
                </Button>
                <Button htmlType={'reset'}>重置</Button>
              </Space>
            </Form.Item>
          )}
        </Form>
        {viewOnly && (
          <Row justify={'center'}>
            <Button onClick={() => history.goBack()}>返回</Button>
          </Row>
        )}
      </div>
    </Page>
  )
}

function transformTree(vos: StewardServiceVO[]): RecursionOption[] {
  return vos.map(value => ({
    label: value.name,
    value: value.id,
    children: value.stewardServiceList ? transformTree(value.stewardServiceList) : undefined,
  }))
}

// 时间字符串转分钟
function timeToMinutes(time: string) {
  if (!time) return time
  const [hour, minute] = time.split(':')
  return Number(hour) * 60 + Number(minute)
}

// 分钟转时间字符串
function minutesToTime(minutes: number) {
  if (!minutes) return minutes
  const hour = `${Math.floor(minutes / 60)}`.padStart(2, '0')
  const minute = `${minutes % 60}`.padStart(2, '0')
  return `${hour}:${minute}:00`
}
