import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  // Transfer,
  Typography,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {downloadBlob} from '@/utils/util'
import {SwapRightOutlined} from '@ant-design/icons'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {sysUserModel} from '@/store/models/SysUser'
import {useAppHistory} from '@/common/hooks/useRouter'
import useSorter from '@/common/lib/useSorter'
import CitySelect from '@/components/Form/CitySelect'

const {RangePicker} = DatePicker

export default function PerformanceManagement() {
  const [auth] = usePermission()
  const history = useAppHistory()
  const [form] = Form.useForm()

  // const orgId = userModel.useData(data => data.userInfo?.orgId)

  const [salaryList, setSalaryList] = useState([] as StewardSalaryVO[])
  const [total, setTotal] = useState(0)
  const salaryStatus = useCodeSync('steward.salaryStatus')
  const salaryStatusArr = useCodeArraySync('steward.salaryStatus')
  const [hasMin, setHasMin] = useState(false)
  const [hasMax, setHasMax] = useState(false)
  const loading = stewardModel.stewardSalaryList.useLoading()
  // const [info, setInfo] = useState({} as StewardSalaryVO)
  // const [wageBase, setWageBase] = useState(0)
  const [transferVisible, setTransferVisible] = useState<boolean>(false)

  // 离职人员列表
  const [stewardList, setStewardList] = useState<SysUserVO[]>([])
  // 在职人员
  const [onSelected, setOnSelected] = useState<{label: string; key: string}[]>([])
  // 离职人员
  const [offSelected, setOffSelected] = useState<{label: string; key: string}[]>([])

  const [deductVisible, setDeductVisible] = useState(false)
  const [deductForm] = Form.useForm()
  const [active, setActive] = useState(() => {
    const permissionObj = {
      '001045001': '1',
      // '001135001': '2',
      '001159001': '3',
    }
    const key = Object.keys(permissionObj).filter(item => auth(item))[0]
    return permissionObj[key]
  })

  const [yearMonth, setYearMonth] = useState('')
  const [planId, setPlanId] = useState('')
  const [cityCode, setCityCode] = useState('')

  useEffect(() => {
    sysUserModel
      .querySysUserList({roleId: 'steward', pageable: false})
      .then(({response: {data}}) => setStewardList(data))
  }, [])

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    if (auth('001045001')) {
      const {
        response: {data, total},
      } = await stewardModel.stewardSalaryList(value)
      setSalaryList(data)
      setTotal(total)
    }
  }, [auth, form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form, deductForm])

  // const [salaryTypeForm] = Form.useForm()
  // const [salaryTypeList, setSalaryTypeList] = useState<StewardSalaryTypeVO[]>([])
  // const [salaryTypeTotal, setSalaryTypeTotal] = useState<number>(0)
  // const salaryTypeCategoryArr = useCodeArraySync('steward.salaryTypeCategory')
  // const salaryTypeCategory = useCodeSync('steward.salaryTypeCategory')
  // const salaryTypeLoading = stewardModel.stewardSalaryTypeList.useLoading()
  // const salaryTypeSearch = useCallback(() => {
  //   const formValues = salaryTypeForm.getFieldsValue(true)
  //   if (auth('001135001')) {
  //     stewardModel.stewardSalaryTypeList(formValues).then(({response: {data, total}}) => {
  //       setSalaryTypeList(data)
  //       setSalaryTypeTotal(total)
  //     })
  //   }
  // }, [auth, salaryTypeForm])

  // useEffect(() => {
  //   salaryTypeSearch()
  // }, [salaryTypeSearch])

  // const [updateSalaryTypeForm] = Form.useForm()
  // const [updateSalaryVisible, setUpdateSalaryVisible] = useState<boolean>(false)
  // const [salaryTypeInfo, setSalaryTypeInfo] = useState({} as StewardSalaryTypeVO)

  const [performanceForm] = Form.useForm()
  const [performanceList, setPerformanceList] = useState<StewardSalaryPlanListVO[]>([])
  const [performanceTotal, setPerformanceTotal] = useState(0)
  const performanceLoading = stewardModel.stewardSalaryPlanList.useLoading()

  const stewardSalaryPlan = useCodeSync('steward.salary.plan')
  const stewardSalaryPlanArr = useCodeArraySync('steward.salary.plan')

  const searchPerformance = useCallback(() => {
    const formValues = performanceForm.getFieldsValue(true)
    stewardModel.stewardSalaryPlanList(formValues).then(({response: {data, total}}) => {
      setPerformanceList(data)
      setPerformanceTotal(total)
    })
  }, [performanceForm])

  const [allPlanList, setAllPlanList] = useState<StewardSalaryPlanListVO[]>([])
  const [cityCodes, setCityCodes] = useState<CityDataProps[]>([])

  useEffect(() => {
    if (auth('001159001')) {
      searchPerformance()
    }
  }, [auth, searchPerformance])

  useEffect(() => {
    // 绩效方案筛选数据
    stewardModel
      .stewardSalaryPlanList({pageable: false, pageNum: 1, pageSize: 1} as StewardSalaryPlanListDTO)
      .then(({response: {data}}) => {
        setAllPlanList(data)
      })

    // 城市数据
    commonModel.queryCityCode({type: '2', parentCode: ''}).then(({response: {data}}) => setCityCodes(data))
  }, [])

  useFocusEffect(() => {
    performanceForm.submit()
  })

  // 绩效方案列表
  const [salaryPlanList, setSalaryPlanList] = useState<StewardSalaryPlanListVO[]>([])
  const salaryPlay = salaryPlanList?.find(item => item.planId === planId)

  useEffect(() => {
    if (yearMonth || cityCode) {
      stewardModel
        .stewardSalaryPlanList({month: yearMonth, cityCode, pageNum: 1, pageSize: 10, pageable: false, status: '1'})
        .then(({response: {data}}) => {
          setSalaryPlanList(data)
        })
    }
  }, [cityCode, yearMonth])

  const sorter = useSorter(form)
  const [selectedRows, setSelectedRows] = useState<StewardSalaryVO[]>([])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {active === '1' && (
                <>
                  <Permission code={'006124001'}>
                    <Button
                      disabled={!selectedRows.length}
                      onClick={async () => {
                        if (selectedRows.some(value => value.salaryStatus !== '3'))
                          return message.warning('请选择已确认的绩效')
                        let rollbackReason = ''
                        const instance = Modal.confirm({
                          title: '提示',
                          icon: null,
                          closable: true,
                          content: (
                            <Form>
                              <Form.Item label={'撤回原因'} required>
                                <Input.TextArea
                                  rows={3}
                                  maxLength={200}
                                  showCount
                                  placeholder={'请输入'}
                                  onChange={e => (rollbackReason = e.target.value)}
                                />
                              </Form.Item>
                            </Form>
                          ),
                          onOk: async () => {
                            if (!rollbackReason) {
                              const msg = '请输入撤回原因'
                              message.error(msg)
                              return Promise.reject(msg)
                            }
                            let count = 0
                            // 有报错就终止调用
                            try {
                              instance.update({okButtonProps: {loading: true}})
                              for (const item of selectedRows) {
                                await stewardModel.stewardSalaryRollback({salaryId: item.salaryId, rollbackReason})
                                count++
                              }
                              form.submit()
                              message.success('终审撤回成功！')
                            } catch (e) {
                              if (count) {
                                message.warning(`已撤回${count}条`)
                                form.submit()
                              }
                            } finally {
                              instance.update({okButtonProps: {loading: false}})
                              setSelectedRows([])
                            }
                          },
                        })
                      }}
                    >
                      批量终审确认撤回
                    </Button>
                  </Permission>
                  <Permission code={'018045001'}>
                    <Button
                      disabled={!selectedRows.length}
                      onClick={async () => {
                        if (selectedRows.some(value => value.salaryStatus !== '1'))
                          return message.warning('请选择未确认绩效')
                        const instance = Modal.confirm({
                          title: '提示',
                          icon: null,
                          closable: true,
                          content: '是否确认终审？',
                          onOk: async () => {
                            let count = 0
                            // 有报错就终止调用
                            try {
                              instance.update({okButtonProps: {loading: true}})
                              for (const item of selectedRows) {
                                await stewardModel.stewardSalaryCheck({salaryId: item.salaryId})
                                count++
                              }
                              form.submit()
                              message.success('终审确认成功！')
                            } catch (e) {
                              if (count) {
                                message.warning(`已确认${count}条`)
                                form.submit()
                              }
                            } finally {
                              instance.update({okButtonProps: {loading: false}})
                              setSelectedRows([])
                            }
                          },
                        })
                      }}
                    >
                      批量终审确认
                    </Button>
                  </Permission>
                  <Permission code={'001125001'}>
                    <Button
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await stewardModel.stewardSalaryCheckRollbackList({
                          pageable: false,
                          pageNum: 1,
                          pageSize: 10,
                        })
                        Modal.info({
                          title: '终审撤回记录',
                          icon: null,
                          centered: true,
                          width: 800,
                          okText: '关闭',
                          content: (
                            <Table
                              dataSource={data}
                              rowKey={'rollbackId'}
                              columns={[
                                {title: '撤回人', dataIndex: 'operatorName', width: 100},
                                {title: '撤回时间', dataIndex: 'insertTime', width: 180},
                                {title: '管家', dataIndex: 'stewardName', width: 100},
                                {
                                  title: '月份',
                                  dataIndex: 'salaryDate',
                                  width: 80,
                                  render: value => moment(value).format('M'),
                                },
                                {title: '撤回原因', dataIndex: 'rollbackReason'},
                              ]}
                              pagination={{position: ['bottomCenter']}}
                            />
                          ),
                        })
                      }}
                    >
                      终审撤回记录
                    </Button>
                  </Permission>
                  <Permission code={'003045002'}>
                    <Button
                      type={'primary'}
                      onClick={() => {
                        setTransferVisible(true)
                      }}
                    >
                      生成绩效
                    </Button>
                  </Permission>
                  <Permission code={'007045002'}>
                    <Button
                      onClick={() => {
                        const value = form.getFieldsValue(true)
                        downloadBlob({url: '/steward/salary/list-excel', body: value})
                      }}
                    >
                      导出
                    </Button>
                  </Permission>
                </>
              )}
              {/*{active === '2' && (
                <Permission code={'003135002'}>
                  <Button type={'primary'} onClick={() => setUpdateSalaryVisible(true)}>
                    新增绩效
                  </Button>
                </Permission>
              )}*/}
              {active === '3' && (
                <Permission code={'003159002'}>
                  <Button type={'primary'} onClick={() => history.push('/performance/new')}>
                    新增绩效方案
                  </Button>
                </Permission>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} activeKey={active} onChange={key => setActive(key)}>
        {auth('001045001') && (
          <Tabs.TabPane tab={'管家绩效'} key={'1'}>
            <Form
              form={form}
              onFinish={search}
              onReset={() => {
                search()
                setHasMin(false)
                setHasMax(false)
              }}
              initialValues={{pageNum: 1, pageSize: 10, pageable: true, sort: 'salary_date desc'}}
            >
              <div className={commonStyles.filterSection}>
                <Row gutter={40}>
                  <Col>
                    <Form.Item label={'管家名称'} name={'keyword'}>
                      <Input placeholder={'请输入管家名称或手机号'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={'当月数量'} style={{marginBottom: 0}}>
                      <Space align={'start'}>
                        <Form.Item name={'orderNumMin'} rules={[{required: hasMax, message: '输入最小数量'}]}>
                          <Input
                            placeholder={'最小数量'}
                            style={{width: '100px'}}
                            allowClear
                            onChange={event => setHasMin(!!event.target.value)}
                          />
                        </Form.Item>
                        <div style={{marginTop: 5}}>
                          <SwapRightOutlined
                            style={{color: 'rgba(0, 0, 0, 0.25)', marginRight: 10, fontSize: 16, marginLeft: 10}}
                          />
                        </div>
                        <Form.Item name={'orderNumMax'} rules={[{required: hasMin, message: '输入最大数量'}]}>
                          <Input
                            placeholder={'最大数量'}
                            style={{width: '100px'}}
                            allowClear
                            onChange={event => setHasMax(!!event.target.value)}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={'工作月份'} name={'_time'}>
                      <RangePicker
                        picker={'month'}
                        onChange={(_, formatString) => {
                          form.setFieldsValue({salaryDateMin: formatString?.[0], salaryDateMax: formatString?.[1]})
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={'审核时间'} name={'operatorTime'}>
                      <RangePicker
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: [moment('00:00:00', 'HH:mm:ss') as any, moment('23:59:59', 'HH:mm:ss') as any],
                        }}
                        format='YYYY-MM-DD HH:mm:ss'
                        onChange={(_, formatString) => {
                          form.setFieldsValue({operatorTimeMin: formatString?.[0], operatorTimeMax: formatString?.[1]})
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={'审核状态'} name={'salaryStatus'}>
                      <Select placeholder={'请选择审核状态'} allowClear>
                        {salaryStatusArr.map(value => (
                          <Select.Option key={value.id} value={value.id}>
                            {value.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={'绩效方案'} name={'planIdList'}>
                      <Select
                        placeholder={'请选择'}
                        options={allPlanList}
                        fieldNames={{label: 'planName', value: 'planId'}}
                        showSearch
                        filterOption={(inputValue, option) => {
                          return option.planName.indexOf(inputValue) > -1
                        }}
                        mode={'multiple'}
                        maxTagCount={3}
                        allowClear
                        style={{width: '200px'}}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </div>
              <div className={commonStyles.tableSection}>
                <Table
                  loading={loading}
                  rowKey={'salaryId'}
                  {...sorter}
                  rowSelection={{
                    selectedRowKeys: selectedRows.map(item => item.salaryId),
                    onChange(_, selectedRows) {
                      setSelectedRows(selectedRows)
                    },
                  }}
                  style={{marginBottom: 20}}
                  dataSource={salaryList}
                  columns={[
                    {title: '姓名', dataIndex: 'stewardName'},
                    {title: '手机号', dataIndex: 'stewardMobile'},
                    {
                      title: '工作月份',
                      dataIndex: 'salaryDate',
                      render: text => moment(text).format('YYYY-MM'),
                      sorter: true,
                      defaultSortOrder: 'descend',
                    },
                    {title: '绩效方案', dataIndex: 'planName'},
                    {title: '平均订单综合时效(秒)', dataIndex: 'orderTimeAvg', sorter: true},
                    {title: '当月单量', dataIndex: 'orderNum', sorter: true},
                    {title: '基础绩效', dataIndex: 'salaryBase', sorter: true},
                    {title: '绩效总额', dataIndex: 'salaryTotal', sorter: true},
                    {title: '基本工资', dataIndex: 'wageBase', sorter: true},
                    {
                      title: '审核状态',
                      dataIndex: 'salaryStatus',
                      render: text => <Typography.Text>{salaryStatus[text]}</Typography.Text>,
                    },
                    {title: '审核人', dataIndex: 'operatorName', render: text => text ?? '/'},
                    {title: '审核时间', dataIndex: 'operatorTime', render: text => text ?? '/', sorter: true},
                    {
                      title: '操作',
                      render: record => {
                        return (
                          <Space>
                            <Permission code={'001045002'}>
                              <Button
                                type={'link'}
                                onClick={() => {
                                  history.push({
                                    pathname: '/performance/detail',
                                    state: {salaryId: record.salaryId},
                                  })
                                }}
                                style={{padding: 0}}
                              >
                                查看
                              </Button>
                            </Permission>
                            {/*<Permission code={'005045002'}>
                              <Button
                                type={'link'}
                                onClick={() => {
                                  setWageBase(record.wageBase)
                                  setInfo(record)
                                }}
                                style={{padding: 0}}
                              >
                                个人工资
                              </Button>
                            </Permission>*/}
                          </Space>
                        )
                      },
                    },
                  ]}
                  pagination={getPaginationProps(form, total)}
                />
              </div>
            </Form>
          </Tabs.TabPane>
        )}
        {/*{auth('001135001') && (
          <Tabs.TabPane tab={'类型管理'} key={'2'} forceRender>
            <div className={commonStyles.filterSection}>
              <Form
                form={salaryTypeForm}
                onFinish={salaryTypeSearch}
                onReset={salaryTypeSearch}
                initialValues={{pageSize: 10, pageNum: 1, pageable: true}}
              >
                <Row gutter={[20, 0]}>
                  <Col span={8}>
                    <Form.Item label={'绩效名称'} name={'typeName'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'绩效类型'} name={'category'}>
                      <Select placeholder={'请选择'} allowClear>
                        {salaryTypeCategoryArr.map(item => (
                          <Select.Option value={item.id} key={item.id}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'绩效状态'} name={'enable'}>
                      <Select placeholder={'请选择'}>
                        <Select.Option value={1}>启用</Select.Option>
                        <Select.Option value={0}>禁用</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      onClick={() => salaryTypeForm.setFieldsValue({pageNum: 1})}
                    >
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                rowKey={'typeId'}
                loading={salaryTypeLoading}
                dataSource={salaryTypeList}
                columns={[
                  {title: '绩效名称', dataIndex: 'typeName'},
                  {title: '绩效类型', dataIndex: 'category', render: value => salaryTypeCategory[value]},
                  {title: '奖罚金额（元）', dataIndex: 'amt'},
                  {title: '备注', dataIndex: 'remark'},
                  {title: '状态', dataIndex: 'enable', render: value => (value === 0 ? '禁用' : '正常')},
                  {
                    title: '操作',
                    render: (_, record) => {
                      return (
                        <Space size={0}>
                          <Permission code={'001135002'}>
                            <Button
                              type={'link'}
                              onClick={() => {
                                setSalaryTypeInfo(record)
                              }}
                            >
                              查看
                            </Button>
                          </Permission>
                          <Permission code={'002135002'}>
                            <Button
                              type={'link'}
                              onClick={() => {
                                setUpdateSalaryVisible(true)
                                updateSalaryTypeForm.setFieldsValue({
                                  typeId: record.typeId,
                                  amt: Math.abs(record.amt),
                                  salaryType: record.amt > 0 ? '1' : '2',
                                  category: record.category,
                                  typeName: record.typeName,
                                  remark: record.remark,
                                })
                              }}
                            >
                              编辑
                            </Button>
                          </Permission>
                          <Permission code={'023135002'}>
                            <Switch
                              checked={record.enable === 1}
                              onClick={checked => {
                                Modal.confirm({
                                  title: '提示',
                                  centered: true,
                                  icon: null,
                                  content: `是否${checked ? '启用' : '禁用'}该绩效名称？`,
                                  onOk: async () => {
                                    await stewardModel.stewardSalaryTypeEnable({
                                      typeId: record.typeId,
                                      enable: checked ? 1 : 0,
                                    })
                                    salaryTypeSearch()
                                  },
                                })
                              }}
                            />
                          </Permission>
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(salaryTypeForm, salaryTypeTotal)}
              />
            </div>

            <Modal
              title={'查看绩效'}
              visible={!!salaryTypeInfo.typeId}
              onCancel={() => setSalaryTypeInfo({} as StewardSalaryTypeVO)}
              centered
              footer={null}
            >
              <Descriptions column={1}>
                <Descriptions.Item label={'绩效名称'}>{salaryTypeInfo.typeName}</Descriptions.Item>
                <Descriptions.Item label={'绩效类型'}>{salaryTypeCategory[salaryTypeInfo.category]}</Descriptions.Item>
                <Descriptions.Item label={'奖罚金额'}>{salaryTypeInfo.amt}</Descriptions.Item>
                <Descriptions.Item label={'备注'}>{salaryTypeInfo.remark}</Descriptions.Item>
              </Descriptions>
            </Modal>

            <Modal
              title={`${updateSalaryTypeForm.getFieldValue('typeId') ? '编辑' : '新增'}绩效`}
              visible={updateSalaryVisible}
              onCancel={() => {
                setUpdateSalaryVisible(false)
              }}
              afterClose={() => {
                updateSalaryTypeForm.resetFields()
              }}
              centered
              footer={null}
            >
              <Form
                form={updateSalaryTypeForm}
                onFinish={async () => {
                  const {typeId, typeName, category, amt, salaryType, remark} = updateSalaryTypeForm.getFieldsValue(
                    true
                  )
                  await stewardModel.stewardSalaryTypeUpsert({
                    typeId,
                    typeName,
                    category,
                    amt: salaryType === '1' ? amt : -amt,
                    remark,
                  } as StewardSalaryTypeUpsertDTO)
                  setUpdateSalaryVisible(false)
                  salaryTypeSearch()
                }}
                labelCol={{flex: '100px'}}
              >
                <Form.Item
                  name={'typeName'}
                  label={'绩效名称'}
                  rules={[{required: true, message: '请填写绩效名称'}]}
                  wrapperCol={{span: 15}}
                >
                  <Input maxLength={20} placeholder={'请输入'} allowClear />
                </Form.Item>
                <Form.Item
                  name={'category'}
                  rules={[{required: true, message: '请选择绩效类型'}]}
                  label={'绩效类型'}
                  wrapperCol={{span: 15}}
                >
                  <Select placeholder={'请选择'} allowClear>
                    {salaryTypeCategoryArr.map(item => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={'奖罚金额'} required style={{marginBottom: 0}}>
                  <Space>
                    <Form.Item name={'salaryType'} rules={[{required: true, message: '请选择奖或者罚'}]}>
                      <Select placeholder={'请选择'} style={{minWidth: 100}}>
                        <Select.Option value={'1'}>奖</Select.Option>
                        <Select.Option value={'2'}>罚</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={'amt'} rules={[{required: true, message: '请输入金额'}]}>
                      <InputNumber min={1} max={10000} style={{minWidth: 200}} placeholder={'请输入金额'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item name={'remark'} label={'备注'} wrapperCol={{span: 15}}>
                  <Input.TextArea maxLength={100} showCount placeholder={'请输入'} rows={3} />
                </Form.Item>
                <Row justify={'end'}>
                  <Space>
                    <Button onClick={() => setUpdateSalaryVisible(false)}>取消</Button>
                    <Button type={'primary'} htmlType={'submit'}>
                      确认
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Modal>
          </Tabs.TabPane>
        )}*/}
        {auth('001159001') && (
          <Tabs.TabPane tab={'绩效方案'} key={'3'} forceRender>
            <div className={commonStyles.filterSection}>
              <Form
                form={performanceForm}
                onFinish={searchPerformance}
                onReset={searchPerformance}
                initialValues={{pageSize: 10, pageNum: 1, pageable: true}}
              >
                <Row gutter={[20, 0]}>
                  <Col span={8}>
                    <Form.Item label={'输入查询'} name={'planName'}>
                      <Input placeholder={'绩效名称'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'状态'} name={'status'}>
                      <Select
                        allowClear
                        placeholder={'全部'}
                        options={stewardSalaryPlanArr.map(value => ({label: value.label, value: value.id}))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={'使用城市'} name={'cityCodeList'}>
                      <Select
                        mode={'multiple'}
                        placeholder={'请选择'}
                        allowClear
                        options={cityCodes}
                        fieldNames={{label: 'name', value: 'code'}}
                        showSearch
                        filterOption={(inputValue, option) => {
                          return option.name.indexOf(inputValue) > -1
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      // onClick={() => salaryTypeForm.setFieldsValue({pageNum: 1})}
                    >
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                rowKey={'planId'}
                loading={performanceLoading}
                dataSource={performanceList}
                columns={[
                  {title: '绩效名称', dataIndex: 'planName'},
                  {title: '使用城市', dataIndex: 'cityName', width: 180, ellipsis: true},
                  {title: '适用日期', render: (_, record) => `${record.applyBeginDate}-${record.applyEndDate}`},
                  {title: '创建人', dataIndex: 'insertBy'},
                  {title: '创建时间', dataIndex: 'insertTime'},
                  {title: '状态', dataIndex: 'status', render: value => stewardSalaryPlan[value]},
                  {
                    title: '操作',
                    render: (_, record) => {
                      return (
                        <Space>
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() => history.push('/performance/new', {planId: record.planId, action: 'view'})}
                          >
                            查看
                          </Button>
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() => history.push('/performance/new', {planId: record.planId})}
                          >
                            编辑
                          </Button>
                          {['1', '2'].includes(record.status) && (
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={async () => {
                                Modal.confirm({
                                  icon: null,
                                  title: '提示',
                                  content: `是否${record.status === '1' ? '禁用' : '启用'}`,
                                  onOk: async () => {
                                    await stewardModel.stewardSalaryPlanEnable({
                                      planId: record.planId,
                                      status: record.status === '1' ? '2' : '1',
                                    })
                                    searchPerformance()
                                  },
                                })
                              }}
                            >
                              {record.status === '1' ? '禁用' : '启用'}
                            </Button>
                          )}
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(performanceForm, performanceTotal)}
              />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>

      {/* 工资信息 */}
      {/*<Modal
        visible={!!info.salaryId}
        title={'工资信息'}
        onCancel={() => setInfo({} as StewardSalaryVO)}
        onOk={async () => {
          await stewardModel.stewardSalaryUpdateWageBase({salaryId: info.salaryId, wageBase})
          setInfo({} as StewardSalaryVO)
          search()
        }}
        afterClose={() => setWageBase(0)}
        destroyOnClose
        centered
      >
        <>
          <Form.Item label={'姓名'}>{info.stewardName}</Form.Item>
          <Form.Item label={'工作月份'}>{moment(info.salaryDate).format('YYYY-MM-DD')}</Form.Item>
          <Form.Item label={'金额'}>
            <InputNumber
              style={{width: '100%'}}
              value={wageBase}
              placeholder='请输入工资金额'
              onChange={value => setWageBase(value)}
            />
          </Form.Item>
        </>
      </Modal>*/}

      {/* 生成绩效 */}
      <Modal
        open={transferVisible}
        width={900}
        title={'生成绩效'}
        onCancel={() => {
          setTransferVisible(false)
        }}
        afterClose={() => {
          setYearMonth('')
          setPlanId('')
          setCityCode('')
          setOnSelected([])
          setOffSelected([])
        }}
        destroyOnClose
        onOk={async () => {
          if (!yearMonth) return message.warning('请选择月份!')
          if (!cityCode) return message.warning('请选择城市!')
          if (!planId) return message.warning('请选择绩效方案!')
          const targetKeys = [...onSelected, ...offSelected].map(value => value.key)
          if (!targetKeys.length) return message.warning('请选择管家！')
          await stewardModel.stewardSalaryCreateV2({stewardIdList: targetKeys, yearMonth, cityCode, planId})
          message.success('生成绩效成功！')
          setTransferVisible(false)
          search()
        }}
      >
        <Row gutter={[12, 0]}>
          <Col span={7}>
            <Form.Item label={'月份选择'} required>
              <DatePicker
                picker={'month'}
                onChange={(_, dateString) => {
                  setYearMonth(dateString)
                  setPlanId('')
                }}
                format={'YYYY-MM'}
                disabledDate={date => date && date > moment().add(1, 'month')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={'城市'} required>
              <CitySelect
                placeholder={'请选择'}
                onChange={value => {
                  setCityCode(value)
                  setPlanId('')
                }}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label={'绩效方案'}
              required
              extra={salaryPlay ? `适用日期: ${salaryPlay.applyBeginDate} - ${salaryPlay.applyEndDate}` : ''}
            >
              <Select
                value={planId}
                options={salaryPlanList.map(value => ({
                  label: value.planName,
                  value: value.planId,
                }))}
                onChange={value => setPlanId(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Tabs>
          <Tabs.TabPane tab={'在职'} key={'1'}>
            <Table
              rowKey={'userId'}
              dataSource={stewardList.filter(item => item.status === '1')}
              rowSelection={{
                onSelect(record, selected, selectedRows) {
                  setOnSelected(selectedRows.map(value => ({label: value.name, key: value.userId})))
                },
                onSelectAll(selected, selectedRows) {
                  setOnSelected(selectedRows.map(value => ({label: value.name, key: value.userId})))
                },
              }}
              columns={[
                {
                  dataIndex: 'name',
                  filterMode: 'menu',
                  filters: stewardList
                    .filter(item => item.status === '1')
                    .map(value => ({text: `${value.name}（${value.mobile}）`, value: value.userId})),
                  render: (value, record) => `${value}（${record.mobile}）`,
                  filterSearch: true,
                  onFilter: (value, record) => record.userId === value,
                },
              ]}
              size={'small'}
              pagination={{position: ['bottomCenter']}}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'离职'} key={'2'}>
            <Table
              rowKey={'userId'}
              dataSource={stewardList.filter(item => item.status === '0')}
              rowSelection={{
                onSelect(record, selected, selectedRows) {
                  setOffSelected(selectedRows.map(value => ({label: value.name, key: value.userId})))
                },
                onSelectAll(selected, selectedRows) {
                  setOffSelected(selectedRows.map(value => ({label: value.name, key: value.userId})))
                },
              }}
              columns={[
                {
                  dataIndex: 'name',
                  filterMode: 'menu',
                  filters: stewardList
                    .filter(item => item.status === '0')
                    .map(value => ({text: `${value.name}（${value.mobile}）`, value: value.userId})),
                  render: (value, record) => `${value}（${record.mobile}）`,
                  filterSearch: true,
                  onFilter: (value, record) => record.userId === value,
                },
              ]}
              size={'small'}
              pagination={{position: ['bottomCenter']}}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>

      {/* 提成设置 */}
      <Modal
        visible={deductVisible}
        title={'提成设置'}
        onCancel={() => {
          setDeductVisible(false)
        }}
        centered
        okText={'保存'}
        onOk={async () => {
          await deductForm.validateFields()
          await stewardModel.stewardSalarySetCommissionPercent({percent: deductForm.getFieldValue('percent')})
          setDeductVisible(false)
        }}
        afterClose={() => {
          deductForm.resetFields()
        }}
      >
        <Form form={deductForm}>
          <Form.Item
            name={'percent'}
            label={'服务单提成：（总费用-耗材核算总价）*'}
            rules={[
              {
                required: true,
                message: '请输入服务提成',
              },
            ]}
            colon={false}
          >
            <InputNumber
              placeholder='请输入'
              formatter={value => {
                const reg = /^(-)*(\d+)\.(\d).*$/
                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(reg, '$1$2.$3')
              }}
              parser={(value: string) => {
                const reg = /^(-)*(\d+)\.(\d\d).*$/
                return Number(value.replace(/\s?|(,*)/g, '').replace(reg, '$1$2.$3'))
              }}
              max={1}
              step={0.1}
              min={0}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}

// interface CustomTransferProps extends TransferProps<TransferItem> {
//   radio: string
//   dataSource: SysUserVO[]
// }

// const CustomTransfer = ({radio, ...props}: CustomTransferProps) => (
//   <Transfer className={styles.transfer} {...props}>
//     {({filteredItems, direction, onItemSelect, selectedKeys, onItemSelectAll}) => {
//       const dataSource = direction === 'left' ? filteredItems?.filter(item => item.status === radio) : filteredItems
//       return (
//         <Table
//           rowKey={'userId'}
//           dataSource={dataSource}
//           rowSelection={{
//             selectedRowKeys: selectedKeys,
//             onSelect({userId}, selected) {
//               onItemSelect(userId as string, selected)
//             },
//             onSelectAll(selected, selectedRows) {
//               const selectedIds = selectedRows.map(item => item.userId) //所有选中的项
//               const diffKeys = selected ? difference(selectedIds, selectedKeys) : difference(selectedKeys, selectedIds)
//               // selected是false时，selectedKeys过滤出未选中的项 => diffKeys
//               onItemSelectAll(diffKeys as string[], selected)
//             },
//           }}
//           columns={[{dataIndex: 'name'}]}
//           size={'small'}
//           pagination={{position: ['bottomCenter']}}
//         />
//       )
//     }}
//   </Transfer>
// )

// arr1过滤arr2中有的值
// function difference(arr1, arr2) {
//   return arr1.filter(item => !arr2.includes(item))
// }
