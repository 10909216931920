import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {APP_ID} from '@/common/constants'
import moment from 'moment/moment'
import {useAppHistory} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {sysModel} from '@/store/models/Sys'

const status = [
  {label: '启用', value: '1'},
  {label: '禁用', value: '2'},
  {label: '已失效', value: '3'},
]

export default function LonghuPerformanceManagement() {
  const [form] = Form.useForm()
  const history = useAppHistory()

  const [auth] = usePermission()

  const [dataSource, setDataSource] = useState<StewardSalaryPlanListVO[]>([])
  const [total, setTotal] = useState(0)
  const search = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardSalaryPlanList(values)
    setTotal(total)
    setDataSource(data)
  }, [form])
  const loading = stewardModel.stewardSalaryPlanList.useLoading()

  // 页面加载时触发
  useEffect(() => {
    form.submit()
  }, [form])

  // 页面聚焦时触发
  useFocusEffect(() => {
    form.submit()
  })

  const [cityCodes, setCityCodes] = useState<CityDataProps[]>([])

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityCodes(data.city)
    })
  }, [])

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form
          labelCol={{flex: '80px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, appId: APP_ID.GUANYU} as StewardSalaryPlanListDTO}
          form={form}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'名称'} name={'planName'}>
                <Input placeholder={'绩效方案名称'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'城市'} name={'cityCodeList'}>
                <Select
                  mode={'multiple'}
                  placeholder={'请选择'}
                  allowClear
                  options={cityCodes}
                  fieldNames={{label: 'label', value: 'id'}}
                  showSearch
                  filterOption={(inputValue, option: any) => option.label?.indexOf(inputValue) > -1}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'适用时间'}
                name={'applyBeginDate'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue('applyEndDate', formatString?.[1])
                  return formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('applyEndDate'))] : value,
                })}
              >
                <DatePicker.RangePicker picker='month' />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'status'}>
                <Select options={status} placeholder={'请选择'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'创建人'} name={'insertBy'}>
                <Input placeholder={'创建人'} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          loading={loading}
          dataSource={dataSource}
          rowKey={'planId'}
          columns={[
            {title: '名称', dataIndex: 'planName'},
            {title: '城市', dataIndex: 'cityName', width: 180, ellipsis: true},
            {title: '适用时间', render: (_, record) => `${record.applyBeginDate} - ${record.applyEndDate}`},
            {
              title: '状态',
              dataIndex: 'status',
              render: value => status.find(item => item.value === value)?.label,
            },
            {title: '创建人', dataIndex: 'insertBy'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              dataIndex: 'planId',
              render: (value, record) => {
                return (
                  <Space>
                    {auth('001184002') && (
                      <Button
                        style={{padding: 0}}
                        type={'link'}
                        onClick={() => {
                          history.push('/performance-longhu/new', {planId: record.planId, action: 'view'})
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {auth('002184001') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          history.push('/performance-longhu/new', {planId: record.planId, action: 'edit'})
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {['1', '2'].includes(record.status) && auth('023184001') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={async () => {
                          let msg = `是否${record.status === '1' ? '禁用' : '启用'}`
                          if (record.status === '1') {
                            const {
                              response: {data},
                            } = await stewardModel.stewardSalaryPlanUnableTest({planId: value})

                            if (data) {
                              msg = `当前绩效方案“${record.planName}”已被其他管家绑定，确认要停用？`
                            }
                          }

                          Modal.confirm({
                            icon: null,
                            title: '提示',
                            content: msg,
                            onOk: async () => {
                              await stewardModel.stewardSalaryPlanEnable({
                                planId: value,
                                status: record.status === '1' ? '2' : '1',
                              })
                              form.submit()
                            },
                          })
                        }}
                      >
                        {record.status === '1' ? '禁用' : '启用'}
                      </Button>
                    )}
                    {auth('013184001') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          history.push('/performance-longhu/new', {planId: record.planId, action: 'copy'})
                        }}
                      >
                        复制
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </>
  )
}
