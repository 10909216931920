import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import styles from './index.module.less'
import {useHistory, useLocation} from 'react-router'
import {stewardModel} from '@/store/models/Steward'
import {commonModel, useCodeSync} from '@/store/models/Common'
import {baseCodes} from '@/store/models/Common/baseCodes'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import moment from 'moment'
import {Album} from '@/components/Album'
import {getImg} from '@/utils/util'

const performanceRule = {
  '1': '按薪资百分比',
  '2': '按薪资固定金额',
}

const probationPerformanceRule = {
  '1': '不打折',
  '2': '按绩效百分比打折',
  '3': '按绩效固定金额打折',
}
const {TabPane} = Tabs
export default function StewardDetails() {
  const history = useHistory()
  const location = useLocation<any>()
  const userId = useMemo(() => location.state?.userId, [location])
  const [userInfo, setUserInfo] = useState({} as StewardUserVO)
  const [orderList, setOrderList] = useState([] as StewardOrderQueryVO[])
  const [orderParams, setOrderParams] = useState({} as StewardUserDTO)
  const [orderTotal, setOrderTotal] = useState(0)
  const orderStatus = useCodeSync('steward.orderStatus')
  const [propertyParams, setPropertyParams] = useState({} as StewardUserDTO)
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [propertyList, setPropertyList] = useState([] as PropertyInfoVO[])
  const rentTypeCode = useMemo(() => baseCodes.rentType, [])
  const [materialParams, setMaterialParams] = useState({} as StewardUserDTO)
  const [materialTotal, setMaterialTotal] = useState(0)
  const [materialList, setMaterialList] = useState([] as StewardMaterialListVO[])
  const [salaryDetail, setSalaryDetail] = useState<StewardSalaryVO[]>([])
  const [warehouse, setWarehouse] = useState<PairModel[]>([])

  const [cityList, setCityList] = useState<CityDataProps[]>([])
  const [bkForm] = Form.useForm()
  const [bkDetail, setBKDetail] = useState({} as StewardSalaryBkDetailVO)
  const [lhForm] = Form.useForm()
  const [lhDetail, setLhDetail] = useState({} as StewardSalaryLhVO)

  useEffect(() => {
    stewardModel.stewardUserInfo({userId}).then(({response: {data}}) => {
      setUserInfo(data)
      setWarehouse(data.warehouse)
    })

    stewardModel.stewardUserOrderList({...orderParams, pageable: false, userId}).then(({response: {data, total}}) => {
      setOrderList(data)
      setOrderTotal(total)
    })

    stewardModel
      .stewardUserPropertyList({...propertyParams, pageable: false, userId})
      .then(({response: {data, total}}) => {
        setPropertyTotal(total)
        setPropertyList(data)
      })

    stewardModel
      .stewardUserMaterialList({...materialParams, pageable: false, userId})
      .then(({response: {data, total}}) => {
        setMaterialTotal(total)
        setMaterialList(data)
      })

    stewardModel.stewardUserLatestSalary({pageable: false, userId} as StewardUserDTO).then(({response: {data}}) => {
      setSalaryDetail(data)
    })

    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [materialParams, orderParams, propertyParams, userId])

  const bkSearch = useCallback(() => {
    const values = bkForm.getFieldsValue(true)
    stewardModel.stewardSalaryBkDetailMonthAndCityAndUserId({...values, userId}).then(({response: {data}}) => {
      setBKDetail(data)
    })
  }, [bkForm, userId])

  const lHSearch = useCallback(() => {
    const {cityCode, salaryDate} = lhForm.getFieldsValue(true)
    stewardModel
      .stewardSalaryLhList({
        stewardId: userId,
        salaryDate,
        cityCode,
        pageNum: 1,
        pageSize: 1,
        pageable: true,
      } as StewardSalaryLhQueryDTO)
      .then(({response: {data}}) => {
        setLhDetail(data[0])
      })
  }, [lhForm, userId])

  return (
    <Page header={<PageHeader ghost={false} title={'管家详情'} onBack={() => history.goBack()} />}>
      <div className={commonStyles.filterSection}>
        <DividerLine title={'管家基本信息'} bottom={15} />
        <Descriptions column={4}>
          <Descriptions.Item label={'姓名'}>{userInfo.name}</Descriptions.Item>
          <Descriptions.Item label={'手机号'}>{userInfo.mobile}</Descriptions.Item>
          <Descriptions.Item label={'所属站点'}>
            {warehouse?.length ? (
              warehouse.length === 1 ? (
                warehouse[0].value
              ) : (
                <Tooltip title={warehouse.map(item => item.value).join(',')}>{`${warehouse[0].value}...`}</Tooltip>
              )
            ) : (
              '无'
            )}
          </Descriptions.Item>
          <Descriptions.Item label={'运营组织'}>{userInfo.orgName}</Descriptions.Item>
          <Descriptions.Item label={'用户组'}>{userInfo.userRoleNameJoint}</Descriptions.Item>
          <Descriptions.Item label={'虚拟号码'}>{userInfo.privateNum ?? '无'}</Descriptions.Item>
          <Descriptions.Item label={'入职日期'}>{userInfo.entryTime}</Descriptions.Item>
          <Descriptions.Item label={'转正日期'}>{userInfo.regularDate ?? '无'}</Descriptions.Item>
          <Descriptions.Item label={'离职日期'}>{userInfo.dimissionDate ?? '无'}</Descriptions.Item>
        </Descriptions>
        <DividerLine title={'薪资基本信息'} top={15} bottom={15} />
        <Descriptions column={4}>
          <Descriptions.Item label={'服务标签'}>{userInfo.appNameJoint}</Descriptions.Item>
          <Descriptions.Item
            label={'综合薪资'}
          >{`${userInfo?.salaryAndPerformance?.salary}元（底薪${userInfo?.salaryAndPerformance?.baseSalary}元，绩效${userInfo?.salaryAndPerformance?.performance}元）`}</Descriptions.Item>
          <Descriptions.Item label={'绩效计算规则'}>
            {performanceRule[userInfo?.salaryAndPerformance?.performanceRule]}
          </Descriptions.Item>
          <Descriptions.Item label={'试用期是否打折'}>
            {probationPerformanceRule[userInfo?.salaryAndPerformance?.probationPerformanceRule]}
          </Descriptions.Item>
          <Descriptions.Item
            label={'饭补'}
          >{`试用期${userInfo?.salaryAndPerformance?.probationMealSubsidy}元，转正后${userInfo?.salaryAndPerformance?.mealSubsidy}元`}</Descriptions.Item>
          <Descriptions.Item label={'贝壳用户ID'}>{userInfo.beikeId ?? '/'}</Descriptions.Item>
        </Descriptions>

        <Tabs defaultValue={'1'} className={styles.tabs}>
          <TabPane tab={'服务单列表'} key={'1'}>
            <div className={styles.table}>
              <Table
                rowKey={'orderId'}
                dataSource={orderList}
                scroll={{x: 'max-content'}}
                columns={[
                  {title: 'ID', dataIndex: 'orderId', key: 'orderId'},
                  {
                    title: '下单人',
                    dataIndex: 'orderMakerType',
                    key: 'orderMakerType',
                    render: (text, record) => (
                      <Typography.Text>{text === 'lessor' ? record.lessorName : record.lesseeName}</Typography.Text>
                    ),
                  },
                  {
                    title: '类别',
                    dataIndex: 'category',
                    key: 'category',
                    render: (text, record) => (
                      <Typography.Text>{`${record.categoryFirName}-${record.categorySecName}-${record.categoryThiName}`}</Typography.Text>
                    ),
                  },
                  {
                    title: '下单人手机号',
                    dataIndex: 'orderMakerType',
                    key: 'orderMakerType',
                    render: (text, record) => (
                      <Typography>{text === 'lessor' ? record.lessorMobile : record.lesseeMobile}</Typography>
                    ),
                  },
                  {title: '下单时间', dataIndex: 'timeMake', key: 'timeMake'},
                  {
                    title: '房源',
                    dataIndex: 'propertyAddr',
                    key: 'propertyAddr',
                  },
                  {
                    title: '考核',
                    dataIndex: 'salaryValid',
                    key: 'salaryValid',
                    render: text => <Typography.Text>{text === 0 ? '无效' : '有效'}</Typography.Text>,
                  },
                  {
                    title: '状态',
                    dataIndex: 'orderStatus',
                    key: 'orderStatus',
                    render: text => <Typography.Text>{orderStatus[text]}</Typography.Text>,
                  },
                  {
                    title: '操作',
                    dataIndex: 'orderId',
                    key: 'orderId',
                    align: 'center',
                    fixed: 'right',
                    width: 100,
                    render: text => (
                      <Button
                        type={'link'}
                        onClick={() => history.push({pathname: '/steward/detail', state: {orderId: text}})}
                      >
                        查看
                      </Button>
                    ),
                  },
                ]}
                pagination={{
                  total: orderTotal,
                  current: orderParams.pageNum,
                  pageSize: orderParams.pageSize,
                  showSizeChanger: true,
                  position: ['bottomCenter'],
                  onChange: (pageNum, pageSize) => setOrderParams(state => ({...state, pageNum, pageSize})),
                }}
              />
            </div>
          </TabPane>
          <TabPane tab={'房源列表'} key={'2'}>
            <div className={styles.table}>
              <Table
                dataSource={propertyList}
                rowKey={'propertyCode'}
                columns={[
                  {title: '房源ID', dataIndex: 'propertyCode', key: 'propertyCode'},
                  {title: '房东名称', dataIndex: 'lessorName', key: 'lessorName'},
                  {
                    title: '类别',
                    dataIndex: 'rentType',
                    key: 'rentType',
                    render: text => <Typography.Text>{rentTypeCode[text]}</Typography.Text>,
                  },
                  {title: '门牌号', dataIndex: 'title', key: 'title'},
                  {
                    title: '状态',
                    dataIndex: 'rentStatus',
                    key: 'rentStatus',
                    render: (text, record) => (
                      <Typography.Text>{text === '0' ? '空置' : record.contractId ? '已租' : '待录'}</Typography.Text>
                    ),
                  },
                  {
                    title: '操作',
                    dataIndex: 'propertyCode',
                    key: 'propertyCode',
                    align: 'center',
                    render: text => (
                      <Button
                        type={'link'}
                        onClick={() => {
                          history.push({pathname: '/property/detail', state: {propertyCode: text}})
                        }}
                      >
                        详情
                      </Button>
                    ),
                  },
                ]}
                pagination={{
                  total: propertyTotal,
                  current: propertyParams.pageNum,
                  pageSize: propertyParams.pageSize,
                  showSizeChanger: true,
                  position: ['bottomCenter'],
                  onChange: (pageNum, pageSize) => setPropertyParams(state => ({...state, pageSize, pageNum})),
                }}
              />
            </div>
          </TabPane>
          <TabPane tab={'物品列表'} key={'3'}>
            <div className={styles.table}>
              <Table
                dataSource={materialList}
                rowKey={'materialId'}
                columns={[
                  {title: 'SKU', dataIndex: 'sku', key: 'sku'},
                  {title: '耗材名称', dataIndex: 'name', key: 'name'},
                  {
                    title: '规格',
                    dataIndex: 'specification',
                    key: 'specification',
                    render: text => <Typography.Text>{text || '/'}</Typography.Text>,
                  },
                  {
                    title: '品牌',
                    dataIndex: 'brand',
                    key: 'brand',
                    render: text => <Typography.Text>{text || '/'}</Typography.Text>,
                  },
                  {title: '数量', dataIndex: 'num', key: 'num'},
                  {title: '单位', dataIndex: 'unit', key: 'unit'},
                ]}
                pagination={{
                  total: materialTotal,
                  current: materialParams.pageNum,
                  pageSize: materialParams.pageSize,
                  showSizeChanger: true,
                  position: ['bottomCenter'],
                  onChange: (pageNum, pageSize) => {
                    setMaterialParams(state => ({...state, pageSize, pageNum}))
                  },
                }}
              />
            </div>
          </TabPane>
          <TabPane tab={'绩效考核'} key={'4'}>
            <Tabs
              onChange={key => {
                if (key === '2') lHSearch()
                if (key === '3') bkSearch()
              }}
              items={[
                {
                  key: '1',
                  label: '牛油果绩效',
                  children: (
                    <>
                      {!!salaryDetail.length &&
                        salaryDetail.map(item => (
                          <div className={styles.table} key={item.planId}>
                            <Table
                              style={{width: 750}}
                              title={() => <span style={{fontSize: '16px'}}>{item.planName}</span>}
                              dataSource={item.salaryStatisticsList}
                              rowKey={'label'}
                              columns={[
                                {title: '绩效类型', dataIndex: 'label', key: 'label', width: 250},
                                {title: '绩效金额', dataIndex: 'value', key: 'value', width: 250},
                                {
                                  title: '单量',
                                  dataIndex: 'num',
                                  key: 'num',
                                  width: 250,
                                  render: text => <Typography.Text>{text || '/'}</Typography.Text>,
                                },
                              ]}
                              pagination={false}
                              footer={() => (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                  <div style={{width: 234, paddingRight: 16}}>
                                    <Typography.Text>总绩效</Typography.Text>
                                  </div>
                                  <div style={{width: 250, padding: '0 16px'}}>
                                    <Typography.Text>{item.salaryTotal}</Typography.Text>
                                  </div>
                                  <div style={{width: 250, paddingLeft: 16}}>
                                    <Typography.Text>{item.orderNumValid}</Typography.Text>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        ))}
                    </>
                  ),
                },
                {
                  key: '2',
                  label: '龙湖绩效',
                  forceRender: true,
                  children: (
                    <>
                      <Form
                        form={lhForm}
                        onFinish={lHSearch}
                        onReset={lHSearch}
                        initialValues={{
                          salaryDate: moment().subtract(1, 'month').format('YYYY-MM'),
                          cityCode: '310100',
                        }}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label={'绩效月份'}
                              name={'salaryDate'}
                              getValueFromEvent={(_, value) => value}
                              getValueProps={value => ({value: moment(value)})}
                            >
                              <DatePicker
                                picker={'month'}
                                disabledDate={current => {
                                  return current && current > moment().subtract(1, 'month')
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} offset={1}>
                            <Form.Item label={'城市'} name={'cityCode'}>
                              <Select
                                options={cityList}
                                showSearch
                                filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
                                placeholder={'请选择'}
                                fieldNames={{value: 'code', label: 'name'}}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                          <Col offset={1}>
                            <Space>
                              <Button type={'primary'} htmlType={'submit'}>
                                查询
                              </Button>
                              <Button htmlType={'reset'}>重置</Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                      {lhDetail?.salaryId ? (
                        <>
                          <Table
                            style={{marginTop: 12, marginBottom: 12}}
                            rowKey={'name'}
                            dataSource={lhDetail.performanceBO?.detailList}
                            pagination={false}
                            columns={[
                              {title: '综合评定', dataIndex: 'name'},
                              {title: '考核结果', dataIndex: 'key'},
                              {title: '考核分数', dataIndex: 'value'},
                            ]}
                          />
                          <Descriptions column={1}>
                            <Descriptions.Item label='综合薪资'>{`${lhDetail.totalSalary}（底薪${lhDetail.basicSalary}元，绩效${lhDetail.performance}元）`}</Descriptions.Item>
                            <Descriptions.Item label='绩效系数'>{lhDetail.ratingFactor}</Descriptions.Item>
                            <Descriptions.Item label='评定绩效金额'>{lhDetail.ratingPerformance}</Descriptions.Item>
                            <Descriptions.Item label='额外奖惩'>{lhDetail?.rewardAmount}元</Descriptions.Item>
                            <Descriptions.Item label='餐补'>{lhDetail?.mealAllowance}元</Descriptions.Item>
                            <Descriptions.Item label='提成'>{lhDetail?.commission}元</Descriptions.Item>
                            <Descriptions.Item label='薪资合计'>{lhDetail?.salaryTotal}元</Descriptions.Item>
                          </Descriptions>
                        </>
                      ) : (
                        <p>暂无数据~</p>
                      )}
                    </>
                  ),
                },
                {
                  key: '3',
                  label: '贝壳绩效',
                  forceRender: true,
                  children: (
                    <>
                      <Form
                        form={bkForm}
                        onFinish={bkSearch}
                        onReset={bkSearch}
                        initialValues={{month: moment().subtract(1, 'month').format('YYYY-MM'), cityCode: '310100'}}
                      >
                        <Row>
                          <Col span={6}>
                            <Form.Item
                              label={'绩效月份'}
                              name={'month'}
                              getValueFromEvent={(_, value) => value}
                              getValueProps={value => ({value: moment(value)})}
                            >
                              <DatePicker
                                picker={'month'}
                                disabledDate={current => {
                                  return current && current > moment().subtract(1, 'month')
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6} offset={1}>
                            <Form.Item label={'城市'} name={'cityCode'}>
                              <Select
                                options={cityList}
                                showSearch
                                filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
                                placeholder={'请选择'}
                                fieldNames={{value: 'code', label: 'name'}}
                                allowClear
                              />
                            </Form.Item>
                          </Col>
                          <Col offset={1}>
                            <Space>
                              <Button type={'primary'} htmlType={'submit'}>
                                查询
                              </Button>
                              <Button htmlType={'reset'}>重置</Button>
                            </Space>
                          </Col>
                        </Row>
                      </Form>
                      {bkDetail?.bkId ? (
                        <>
                          <Table
                            dataSource={bkDetail.performanceStatistics}
                            rowKey={'title'}
                            columns={[
                              {title: '绩效指标名称', dataIndex: 'name', width: 200},
                              {title: '指标', dataIndex: 'key', width: 100},
                              {title: '绩效金额', dataIndex: 'value', width: 100},
                            ]}
                            pagination={false}
                          />
                          <Descriptions column={1}>
                            <Descriptions.Item label={'综合薪资'}>{`${
                              bkDetail.baseSalary + bkDetail.performance
                            }元（底薪${bkDetail.baseSalary}元，绩效${bkDetail.performance}元）`}</Descriptions.Item>
                            <Descriptions.Item label={'评定绩效金额'}>{bkDetail.salaryTotal}</Descriptions.Item>
                            <Descriptions.Item label={'餐补'}>
                              {/*<Button*/}
                              {/*    type={'link'}*/}
                              {/*    style={{padding: 0, height: 'inherit', textDecorationLine: 'underline'}}*/}
                              {/*    onClick={() => {*/}
                              {/*      Modal.confirm({*/}
                              {/*        title: '餐补',*/}
                              {/*        icon: null,*/}
                              {/*        centered: true,*/}
                              {/*        closable: true,*/}
                              {/*        okCancel: false,*/}
                              {/*        width: 1000,*/}
                              {/*        maskClosable: true,*/}
                              {/*        content: (*/}
                              {/*            <div>*/}
                              {/*              <Calendar*/}
                              {/*                  locale={locale}*/}
                              {/*                  // fullscreen={false}*/}
                              {/*                  defaultValue={moment(bkDetail.salaryDate) as any}*/}
                              {/*                  headerRender={() => (*/}
                              {/*                      <>*/}
                              {/*                        <Typography.Title level={4}>*/}
                              {/*                          {moment(bkDetail.salaryDate).format('YYYY-MM')}*/}
                              {/*                        </Typography.Title>*/}
                              {/*                        <Typography.Title level={5}>*/}
                              {/*                          出勤天数：{bkDetail.mealSubsidyDetail?.length}天， 金额合计：{bkDetail.extraMealSubsidy}元*/}
                              {/*                        </Typography.Title>*/}
                              {/*                      </>*/}
                              {/*                  )}*/}
                              {/*                  dateCellRender={date => {*/}
                              {/*                    const items = bkDetail.mealSubsidyDetail?.filter(value => date.isSame(value.date, 'date'))*/}
                              {/*                    console.log(items)*/}
                              {/*                    return items?.map((value, index) => <div key={index}>{`${value.key}：${value.value}`}</div>)*/}
                              {/*                  }}*/}
                              {/*              />*/}
                              {/*            </div>*/}
                              {/*        ),*/}
                              {/*      })*/}
                              {/*    }}*/}
                              {/*>*/}
                              {/*  {bkDetail.extraMealSubsidy}元*/}
                              {/*</Button>*/}
                              {`${bkDetail.extraMealSubsidy}元`}
                            </Descriptions.Item>
                            <Descriptions.Item label={'薪资合计'}>{bkDetail.allSalary}</Descriptions.Item>
                          </Descriptions>
                        </>
                      ) : (
                        <p>暂无数据~</p>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </TabPane>
          <TabPane tab={'相关证件'}>
            <Tabs
              items={[
                {label: '电工证', key: '1', children: <Licence urls={userInfo.electricianLicenceList} />},
                {label: '焊工证', key: '2', children: <Licence urls={userInfo.welderLicenceList} />},
                {label: '登高证', key: '3', children: <Licence urls={userInfo.heightWorkLicenceList} />},
              ]}
            ></Tabs>
          </TabPane>
        </Tabs>
      </div>
    </Page>
  )
}

const Licence: React.FC<{urls: string[]}> = props => {
  return props.urls?.length ? (
    <Album disabled fileList={props.urls.map(url => ({uid: url, url: getImg(url)}))}>
      {null}
    </Album>
  ) : (
    <span>暂未上传~</span>
  )
}
