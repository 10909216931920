import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Typography,
} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import styles from './index.module.less'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {useCodeSync} from '@/store/models/Common'
import {downloadBlob, getImg} from '@/utils/util'
import {CloseCircleFilled} from '@ant-design/icons'
import {Permission} from '@/components/Permission'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Album} from '@/components/Album'
import useSorter from '@/common/lib/useSorter'
import {APP_ID} from '@/common/constants'

/*
const violationReasonObj = {
  tag1: '1小时未接单',
  // tag2: '上门时间到完成时间小于5分钟',
  // tag3: '出发至服务完成时间不符合要求',
  tag4: '上门时间超时',
  tag5: '电联沟通违规',
  tag6: '远程指导违规',
}
*/

interface AddOrderSalaryVO extends StewardSalaryOrderInsertDTO {
  recordId: string
  _key: number
}

const tag = {0: '否', 1: '是'}

export default function PerformanceDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/performance/detail'>()
  const {salaryId} = location.state
  const [salaryDetail, setSalaryDetail] = useState({} as StewardSalaryVO)
  const [total, setTotal] = useState(0)
  const [salaryStatisticsList, setSalaryStatisticsList] = useState([] as StewardSalaryStatisticsVO[])
  const [addVisible, setAddVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [serviceList, setServiceList] = useState([] as StewardOrderQueryVO[])
  const [form] = Form.useForm()
  const [teachCheck, setTeachCheck] = useState(false)
  // 违规线百分比
  const [violationPercent, setViolationPercent] = useState<number>(null)
  // 违规线
  const [violation, setViolation] = useState<number>(null)
  const [violationReason, setViolationReason] = useState<string>('')
  const [violationVisible, setViolationVisible] = useState(false)
  //服务单详情
  const [orderDetail, setOrderDetail] = useState({} as StewardOrderQueryVO)
  const [addForm] = Form.useForm()
  const orderStatus = useCodeSync('steward.orderStatus')
  const [editForm] = Form.useForm()
  const loading = stewardModel.stewardSalaryListOrder.useLoading()
  const [salaryTypeList, setSalaryTypeList] = useState<StewardSalaryTypeVO[]>([])
  const salaryTypeCategory = useCodeSync('steward.salaryTypeCategory')
  const [orderSalaryList, setOrderSalaryList] = useState<AddOrderSalaryVO[]>([])
  const [deleteList, setDeleteList] = useState<string[]>([])
  const amtTotal: number = useMemo(() => {
    return orderSalaryList.reduce((sum, item) => {
      if (item.typeId) sum += item.amt
      return sum
    }, 0)
  }, [orderSalaryList])
  const sorterProps = useSorter(form)

  //查询管家基本信息和绩效统计
  const querySalaryDetail = useCallback(() => {
    stewardModel.stewardSalaryDetailV2({salaryId}).then(({response: {data}}) => {
      setSalaryDetail(data)
      setSalaryStatisticsList(data.salaryStatisticsList)
    })
  }, [salaryId])

  //查询管家服务单统计
  const queryServiceList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardSalaryListOrder({...formValues, salaryId}).then(({response: {data, total}}) => {
      setTotal(total)
      setServiceList(data)
    })
  }, [salaryId, form])

  useEffect(() => {
    queryServiceList()
    querySalaryDetail()

    stewardModel
      .stewardSalaryTypeList({
        appId: APP_ID.NYG,
        pageable: false,
        pageNum: 1,
        pageSize: 10,
        enable: 1,
      } as StewardSalaryTypeQueryDTO)
      .then(({response: {data}}) => {
        setSalaryTypeList(data)
      })
  }, [queryServiceList, querySalaryDetail])

  // 终审撤回
  const [cancelVisible, setCancelVisible] = useState(false)
  const [rollbackReason, setRollbackReason] = useState('')

  // 申诉记录
  const approvalStatus = useCodeSync('steward.order.valid.appeal.approval')
  const approvalModal = async (orderId: string, type: '1' | '2') => {
    const {
      response: {data: appList},
    } = await stewardModel.stewardSalaryValidAppealList({
      orderId,
      pageable: false,
      pageNum: 1,
      pageSize: 10,
      flag: false,
      appealType: type,
    } as StewardAppOrderValidAppealListDTO)

    const {
      response: {data: webList},
    } = await stewardModel.stewardSalaryAppealRecord({orderId, type})
    if (appList.length || webList.length) {
      Modal.info({
        title: `${type === '1' ? '无效' : '违规'}单申诉记录`,
        icon: null,
        centered: true,
        width: 700,
        okText: '关闭',
        content: (
          <Tabs
            items={[
              ...appList.map(item => ({
                label: moment(item.insertTime).format('YYYY-MM-DD'),
                key: item.insertTime,
                children: (
                  <>
                    <DividerLine title={'审批信息'} bottom={10} />
                    <Descriptions column={2}>
                      <Descriptions.Item label={'审批状态'}>{approvalStatus[item.approvalStatus]}</Descriptions.Item>
                      <Descriptions.Item label={'审批人'}>{item.approvalName ?? '/'}</Descriptions.Item>
                      <Descriptions.Item label={'审批时间'}>{item.approvalTime ?? '/'}</Descriptions.Item>
                      <Descriptions.Item label={'备注'}>{item.approvalRemark ?? '/'}</Descriptions.Item>
                    </Descriptions>
                    <DividerLine title={'申诉信息'} bottom={10} />
                    <Descriptions column={2}>
                      <Descriptions.Item label={'申诉人'}>{item.appealUserName}</Descriptions.Item>
                      <Descriptions.Item label={'申诉时间'}>{item.insertTime}</Descriptions.Item>
                      <Descriptions.Item label={'图片'}>
                        <Album disabled fileList={item.imgUrlList.map(item => ({url: getImg(item), uid: item}))}>
                          {null}
                        </Album>
                      </Descriptions.Item>
                      <Descriptions.Item label={'备注'}>{item.appealRemark}</Descriptions.Item>
                    </Descriptions>
                  </>
                ),
              })),
              ...webList.map(item => ({
                label: moment(item.insertTime).format('YYYY-MM-DD'),
                key: item.insertTime,
                children: (
                  <>
                    <Descriptions column={2}>
                      <Descriptions.Item label={'修改人'}>{item.operator}</Descriptions.Item>
                      <Descriptions.Item label={'修改时间'}>{item.insertTime}</Descriptions.Item>
                      <Descriptions.Item label={type === '1' ? '有效单' : '违规单'}>
                        {item.tag === 1 ? '是' : '否'}
                      </Descriptions.Item>
                      <Descriptions.Item label={'修改原因'}>{item.reason}</Descriptions.Item>
                    </Descriptions>
                  </>
                ),
              })),
            ].sort((a, b) => (moment(a.key).isBefore(b.key) ? 1 : -1))}
          />
        ),
      })
    } else {
      return message.warning('无申诉记录')
    }
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          style={{backgroundColor: '#fff'}}
          right={
            <Space>
              <Permission code={'018045001'}>
                <Button type={'primary'} onClick={() => setViolationVisible(true)}>
                  违规线设置
                </Button>
              </Permission>
              {salaryDetail.salaryStatus === '3' && (
                <Permission code={'006124001'}>
                  <Button onClick={() => setCancelVisible(true)}>终审撤回</Button>
                </Permission>
              )}
              {salaryDetail.salaryStatus !== '3' && (
                <Permission code={'018045001'}>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await stewardModel.stewardSalaryBeforeCheck({salaryId})
                      if (data.length) {
                        Modal.info({
                          title: '服务单申诉提醒',
                          icon: null,
                          centered: true,
                          width: 480,
                          content: (
                            <>
                              <div>
                                <Typography.Text style={{color: '#FFB92E'}}>
                                  以下服务单正在申诉中，请在工作台审批后再进行终审确认
                                </Typography.Text>
                              </div>
                              <Space direction={'vertical'} size={0} style={{marginTop: '10px'}}>
                                {data.map(item => (
                                  <Typography.Text key={item}>{item}</Typography.Text>
                                ))}
                              </Space>
                            </>
                          ),
                          cancelText: '我知道了',
                        })
                      } else {
                        setConfirmVisible(true)
                      }
                    }}
                  >
                    终审确认
                  </Button>
                </Permission>
              )}
            </Space>
          }
        />
      }
      header={<PageHeader ghost={false} title={'绩效详情'} onBack={() => history.goBack()} />}
    >
      <div className={styles.container}>
        {/* 管家基本信息 */}
        <div className={styles.left}>
          <DividerLine title={'管家基本信息'} bottom={20} />
          <Descriptions column={2}>
            <Descriptions.Item label={'姓名'}>{salaryDetail.stewardName}</Descriptions.Item>
            <Descriptions.Item label={'工作月份'}>
              {moment(salaryDetail.salaryDate).format('YYYY-MM')}
            </Descriptions.Item>
            <Descriptions.Item label={'手机号码'}>{salaryDetail.stewardMobile}</Descriptions.Item>
            <Descriptions.Item label={'当月单量'}>{salaryDetail.orderNum}</Descriptions.Item>
            <Descriptions.Item label={'绩效方案'}>{salaryDetail.planName}</Descriptions.Item>
          </Descriptions>
          <DividerLine title={'服务单统计'} top={20} bottom={20} />
          <Form
            labelAlign={'left'}
            labelCol={{span: 6}}
            onReset={queryServiceList}
            onFinish={queryServiceList}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
            form={form}
          >
            <Row>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'有效单'} name={'salaryValid'}>
                  <Select placeholder={'请选择'} allowClear>
                    <Select.Option value={0}>否</Select.Option>
                    <Select.Option value={1}>是</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'下单类别'} name={'category'}>
                  <Input placeholder={'请输入下单类别'} allowClear />
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'完成时间'} name={'time'}>
                  <DatePicker.RangePicker
                    format={'YYYY-MM-DD HH:mm:ss'}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [moment('00:00:00', 'HH:mm:ss') as any, moment('23:59:59', 'HH:mm:ss') as any],
                    }}
                    onChange={(_, dateStrings) => {
                      const [timeFinishedBegin, timeFinishedEnd] = dateStrings ?? []
                      form.setFieldsValue({timeFinishedBegin, timeFinishedEnd})
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'额外收费'} name={'feeExtraTag'}>
                  <Select placeholder={'请选择'} allowClear>
                    <Select.Option value={0}>否</Select.Option>
                    <Select.Option value={1}>是</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'违规单'} name={'violationTag'}>
                  <Select placeholder={'请选择'} allowClear>
                    <Select.Option value={0}>否</Select.Option>
                    <Select.Option value={1}>是</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'服务单ID'} name={'orderId'}>
                  <Input placeholder={'请输入服务单ID'} allowClear />
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'带教单'} name={'teachTag'}>
                  <Select placeholder={'请选择'} allowClear>
                    <Select.Option value={0}>否</Select.Option>
                    <Select.Option value={1}>是</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'计算有效单数'} name={'stepCount'}>
                  <InputNumber placeholder={'请输入'} style={{width: '100%'}} min={0} step={1} />
                </Form.Item>
              </Col>
              <Col span={9} pull={3} offset={3}>
                <Form.Item label={'改约单'} name={'rescheduleTag'}>
                  <Select placeholder={'请选择'} allowClear>
                    <Select.Option value={0}>否</Select.Option>
                    <Select.Option value={1}>是</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Space>
                <Button
                  type={'primary'}
                  onClick={() => {
                    const formValues = form.getFieldsValue(true)
                    downloadBlob({url: '/steward/salary/list-order-excel', body: {...formValues, salaryId}})
                  }}
                >
                  导出
                </Button>
                <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                  查询
                </Button>
                <Button htmlType={'reset'}>重置</Button>
              </Space>
            </Row>
          </Form>

          <Table
            loading={loading}
            className={styles.table}
            rowKey={'orderId'}
            scroll={{x: 'max-content'}}
            {...sorterProps}
            dataSource={serviceList}
            title={() => (
              <Descriptions column={5}>
                <Descriptions.Item label={'总单量'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNum}</span>
                </Descriptions.Item>
                <Descriptions.Item label={'计算有效单数'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNumValidCalc}</span>
                </Descriptions.Item>
                <Descriptions.Item label={'额外服务单数'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNumExtra}</span>
                </Descriptions.Item>
                <Descriptions.Item label={'无效单数'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNumInvalid}</span>
                </Descriptions.Item>
                <Descriptions.Item label={'违规单数'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNumViolation}</span>
                </Descriptions.Item>
                <Descriptions.Item label={'带教单'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNumTeach ?? 0}</span>
                </Descriptions.Item>
                <Descriptions.Item label={'改约单'}>
                  <span style={{color: '#00BBAF'}}>{salaryDetail.orderNumReschedule ?? 0}</span>
                </Descriptions.Item>
              </Descriptions>
            )}
            columns={[
              {title: '服务单ID', dataIndex: 'orderId'},
              {
                title: '类别',
                dataIndex: 'categoryFir',
                render: (_, record) => `${record.categoryFirName}-${record.categorySecName}-${record.categoryThiName}`,
              },
              {title: '地址', dataIndex: 'propertyAddr'},
              {title: '完成时间', dataIndex: 'timeFinished'},
              {title: '订单综合时效(秒)', dataIndex: 'orderTimeAvg', sorter: true},
              {
                title: '有效单',
                dataIndex: 'salaryValid',
                render: text => tag[text],
              },
              {
                title: '计算有效单数',
                dataIndex: 'stepCount',
                sorter: true,
              },
              {
                title: '额外收费',
                dataIndex: 'feeExtraTag',
                render: text => tag[text],
              },
              {
                title: '违规单',
                dataIndex: 'violationTag',
                render: text => tag[text],
              },
              {
                title: '带教单',
                dataIndex: 'teachTag',
                render: text => tag[text],
              },
              {
                title: '改约单',
                dataIndex: 'rescheduleTag',
                render: text => tag[text],
              },
              {
                title: '提成',
                dataIndex: 'feeCommission',
                sorter: true,
              },
              {
                title: '奖罚绩效',
                dataIndex: 'manualSalary',
              },
              {
                title: '操作',
                dataIndex: 'orderId',
                fixed: 'right',
                render: orderId => {
                  return (
                    <div>
                      <Permission code={'002045002'}>
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          onClick={() => {
                            Promise.all([
                              stewardModel.stewardOrderDetail({orderId}),
                              stewardModel.stewardSalaryListOrderSalary({orderId}),
                            ]).then(([{response: res1}, {response: res2}]) => {
                              const data1 = res1.data
                              const data2 = res2.data
                              setTeachCheck(data1.teachTag === 1)
                              setOrderDetail(data1)
                              editForm.setFieldsValue({
                                feeExtraTag: data1.feeExtraTag,
                                salaryValid: data1.salaryValid,
                                violationTag: data1.violationTag,
                                salaryValidReason: data1.salaryValidReason,
                                feeCommission: data1.feeCommission,
                                salaryViolationReason: data1.violationNotes,
                              })
                              setViolationReason(data1.violationNotes)

                              setOrderSalaryList(
                                data2.map(item => ({
                                  amt: item.amt,
                                  typeId: item.typeId,
                                  typeName: item.typeName,
                                  recordId: item.recordId,
                                  _key: Math.random(),
                                }))
                              )

                              setDeleteList([])

                              setEditVisible(true)
                            })
                          }}
                        >
                          编辑
                        </Button>
                      </Permission>
                      <Permission code={'001041002'}>
                        <Divider type={'vertical'} />
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          onClick={() => {
                            history.push({pathname: '/steward/detail', state: {orderId}})
                          }}
                        >
                          查看
                        </Button>
                      </Permission>
                    </div>
                  )
                },
              },
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>

        {/* 绩效统计 */}
        <div className={styles.right}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <DividerLine title={'绩效统计'} />
            <Permission code={'003045002'}>
              <div>
                <PlusCircleOutlined style={{color: '#00B7AE', fontSize: '14px'}} />
                <Button
                  type={'link'}
                  style={{padding: 0, marginLeft: 5}}
                  onClick={() => {
                    setAddVisible(true)
                  }}
                >
                  新增额外奖惩
                </Button>
              </div>
            </Permission>
          </div>

          <Table
            style={{marginTop: 20}}
            dataSource={salaryStatisticsList}
            columns={[
              {
                title: '绩效类型',
                dataIndex: 'label',
                width: 100,
                render: (text, record, index) => (
                  <Space>
                    <Typography.Text>{text}</Typography.Text>
                    {record.code === '03' ? (
                      <CloseCircleFilled
                        onClick={async () => {
                          const newSalaryList = salaryStatisticsList.filter((item, _index) => _index !== index)
                          await stewardModel.stewardSalaryUpdateStatistic({
                            salaryId,
                            statisticsList: newSalaryList,
                          })
                          querySalaryDetail()
                        }}
                        style={{color: 'rgba(0, 0, 0, 0.35)', fontSize: 18}}
                      />
                    ) : null}
                  </Space>
                ),
              },
              {title: '绩效金额', dataIndex: 'value', width: 100},
              {
                title: '单量',
                dataIndex: 'num',
                width: 70,
                render: (text, record) => <Typography.Text>{record.code === '03' ? '/' : text}</Typography.Text>,
              },
            ]}
            rowKey={record => `${record.label} + ${Math.random()}`}
            pagination={false}
            footer={() => (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 1, paddingRight: 16}}>
                  <span>总绩效</span>
                </div>
                <div style={{width: 100, padding: '0 16px'}}>
                  <span>{salaryDetail.salaryTotal}</span>
                </div>
                <div style={{width: 54, paddingLeft: 16}}>
                  <span>/</span>
                </div>
              </div>
            )}
          />

          <Descriptions column={1} style={{marginTop: 15}}>
            <Descriptions.Item label={'基础工资'}>{salaryDetail.wageBase}元</Descriptions.Item>
            <Descriptions.Item label={'餐补'}>{salaryDetail.mealAllowance}元</Descriptions.Item>
            <Descriptions.Item label={'薪资合计'}>{salaryDetail.salaryFinal}元</Descriptions.Item>
          </Descriptions>
        </div>
      </div>

      {/* 添加绩效 */}
      <Modal
        open={addVisible}
        footer={null}
        onCancel={() => {
          setAddVisible(false)
          addForm.resetFields()
        }}
        title={'新增额外奖惩'}
        centered
      >
        <Form
          wrapperCol={{span: 12}}
          form={addForm}
          onFinish={async () => {
            const {amt, typeName} = addForm.getFieldsValue(true)
            salaryStatisticsList.push({label: typeName, value: amt, code: '03', num: 0})
            await stewardModel.stewardSalaryUpdateStatistic({salaryId, statisticsList: salaryStatisticsList})
            querySalaryDetail()
            addForm.resetFields()
            setAddVisible(false)
          }}
        >
          <Form.Item label={'绩效名称'} name={'typeId'} rules={[{required: true, message: '请选择绩效名称'}]}>
            <Select
              placeholder={'请选择'}
              allowClear
              showSearch
              filterOption={(inputValue, option) => {
                return option?.children?.indexOf(inputValue as any) > -1
              }}
              onSelect={(value, option) => {
                const {amt, typeName, category} = salaryTypeList.find(item => item.typeId === value)
                addForm.setFieldsValue({amt, typeName, category})
              }}
            >
              {salaryTypeList.map(item => (
                <Select.Option value={item.typeId} key={item.typeId}>
                  {item.typeName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'绩效类型'} shouldUpdate>
            {f => <span>{salaryTypeCategory[f.getFieldValue('category')] ?? '/'}</span>}
          </Form.Item>
          <Form.Item label={'绩效金额'} shouldUpdate>
            {f => <span>{f.getFieldValue('amt') ?? '/'}</span>}
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  setAddVisible(false)
                  addForm.resetFields()
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 服务单详情 */}
      <Drawer
        visible={editVisible}
        title={'服务单详情'}
        onClose={() => {
          setEditVisible(false)
        }}
        width={700}
        className={styles.drawer}
      >
        <div>
          <div className={styles.operation}>
            <Row justify={'space-between'}>
              <Typography.Text style={{color: '#000000'}}>操作</Typography.Text>
              <Button
                type={'link'}
                onClick={async () => {
                  await editForm.validateFields()
                  const editFormValues = editForm.getFieldsValue(true)
                  await stewardModel.stewardSalaryUpdateOrder({
                    ...editFormValues,
                    salaryId,
                    orderId: orderDetail.orderId,
                  } as StewardSalaryUpdateOrderDTO)
                  // 带教单，有修改操作，更新带教单标记
                  if (orderDetail.teachUid && teachCheck !== (orderDetail.teachTag === 1)) {
                    await stewardModel.stewardSalaryChangeTeachTag({salaryId, orderId: orderDetail.orderId})
                  }
                  await stewardModel.stewardSalaryEditOrderSalary({
                    orderId: orderDetail.orderId,
                    deleteList,
                    insertList: orderSalaryList
                      .filter(item => !item.recordId)
                      .map(item => ({amt: item.amt, typeId: item.typeId, typeName: item.typeName})),
                  })
                  querySalaryDetail()
                  queryServiceList()
                  setEditVisible(false)
                }}
              >
                确认
              </Button>
            </Row>
          </div>
          <div className={styles.detail}>
            <DividerLine title={'订单信息'} bottom={20} />
            <Descriptions column={2}>
              <Descriptions.Item label={'订单编号'}>{orderDetail.orderId}</Descriptions.Item>
              <Descriptions.Item
                label={'订单类型'}
              >{`${orderDetail.categoryFirName}-${orderDetail.categorySecName}-${orderDetail.categoryThiName}`}</Descriptions.Item>
              <Descriptions.Item label={'下单时间'}>{orderDetail.timeMake}</Descriptions.Item>
              <Descriptions.Item label={'下单人'}>{`${
                orderDetail.orderMakerType === 'lessee'
                  ? `${orderDetail.lesseeName}（租客）`
                  : `${orderDetail.lessorName}（房东）`
              }`}</Descriptions.Item>
              <Descriptions.Item label={'订单状态'}>{orderStatus[orderDetail.orderStatus]}</Descriptions.Item>
              <Descriptions.Item label={'完成时间'}>{orderDetail.timeFinished}</Descriptions.Item>
              <Descriptions.Item label={'房源地址'}>{orderDetail.propertyAddr}</Descriptions.Item>
              <Descriptions.Item label={'有效单'}>{tag[orderDetail.salaryValid]}</Descriptions.Item>
              {orderDetail.salaryValid === 0 && (
                <Descriptions.Item label={'无效原因'}>{orderDetail.salaryValidReason}</Descriptions.Item>
              )}
              <Descriptions.Item label={'额外收费'}>{tag[orderDetail.feeExtraTag]}</Descriptions.Item>
              <Descriptions.Item label={'总费用'}>{orderDetail.feeStaff}</Descriptions.Item>
              <Descriptions.Item label={'垫付金额'}>{orderDetail.feePayFirst ?? ''}元</Descriptions.Item>
              <Descriptions.Item label={'违规单'}> {tag[orderDetail.violationTag]}</Descriptions.Item>
              {orderDetail.violationTag === 1 && (
                <Descriptions.Item label={'违规原因'}>{violationReason || '无'}</Descriptions.Item>
              )}
              <Descriptions.Item label={'带教单'}> {tag[orderDetail.teachTag]}</Descriptions.Item>
              <Descriptions.Item label={'改约单'}> {tag[orderDetail.rescheduleTag]}</Descriptions.Item>
            </Descriptions>
            <Row justify={'space-between'} align={'middle'}>
              <DividerLine title={'审核确认'} style={{marginTop: 20}} />
              {orderDetail.teachUid && (
                <Space size={0}>
                  <span>带教单：</span>
                  <Switch
                    checked={teachCheck}
                    onChange={check => {
                      setTeachCheck(check)
                    }}
                  />
                </Space>
              )}
            </Row>

            <Form style={{marginTop: 20}} form={editForm}>
              <Row>
                <Col span={8}>
                  <Form.Item label={'有效单'} name={'salaryValid'}>
                    <Select allowClear>
                      <Select.Option value={0}>否</Select.Option>
                      <Select.Option value={1}>是</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item
                    label={'修改原因'}
                    name={'salaryValidReason'}
                    rules={[{required: true, message: '修改原因不能为空'}]}
                  >
                    <Input placeholder={'请输入修改原因'} allowClear />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      type={'link'}
                      onClick={async () => {
                        await approvalModal(orderDetail.orderId, '1')
                      }}
                    >
                      申诉记录
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item label={'违规单'} name={'violationTag'}>
                    <Select allowClear>
                      <Select.Option value={0}>否</Select.Option>
                      <Select.Option value={1}>是</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item
                    label={'修改原因'}
                    name={'salaryViolationReason'}
                    rules={[{required: true, message: '修改原因不能为空'}]}
                  >
                    <Input placeholder={'请输入修改原因'} allowClear />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      type={'link'}
                      onClick={async () => {
                        await approvalModal(orderDetail.orderId, '2')
                      }}
                    >
                      申诉记录
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item label={'额外收费'} name={'feeExtraTag'}>
                    <Select allowClear>
                      <Select.Option value={0}>否</Select.Option>
                      <Select.Option value={1}>是</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={2}>
                  <Form.Item label={'提成'} name={'feeCommission'}>
                    <Input placeholder={'请输入提成'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <DividerLine
              title={'奖罚绩效'}
              right={
                <Space size={20} style={{marginLeft: 30}}>
                  <span>总计：{amtTotal}</span>
                  <Space size={0}>
                    <PlusCircleOutlined style={{color: '#00B7AE', fontSize: '14px'}} />
                    <Button
                      type={'link'}
                      onClick={() => {
                        if (salaryTypeList.filter(item => item.category === '2').length === orderSalaryList.length)
                          return message.warn('所有绩效类型已被添加！')
                        setOrderSalaryList(state => [
                          ...state,
                          {
                            amt: undefined,
                            typeName: undefined,
                            typeId: undefined,
                            recordId: '',
                            _key: Math.random(),
                          },
                        ])
                      }}
                    >
                      新增绩效
                    </Button>
                  </Space>
                </Space>
              }
              bottom={20}
            />
            <Table
              rowKey={'_key'}
              dataSource={orderSalaryList}
              pagination={false}
              columns={[
                {
                  title: '绩效名称',
                  dataIndex: 'typeName',
                  render: (_, record) => {
                    return (
                      <Select
                        placeholder={'请选择'}
                        disabled={!!record.recordId}
                        value={record.typeId}
                        style={{width: 200}}
                        filterOption={(inputValue, option) => {
                          return option?.children?.indexOf(inputValue as any) > -1
                        }}
                        showSearch
                        onSelect={value => {
                          const {amt, typeName} = salaryTypeList.find(item => item.typeId === value)
                          setOrderSalaryList(state =>
                            state.map(item =>
                              record._key === item._key ? {...item, amt, typeId: value, typeName} : item
                            )
                          )
                        }}
                      >
                        {salaryTypeList
                          .filter(item => item.category === '2')
                          .map(item => (
                            <Select.Option
                              disabled={orderSalaryList.map(_item => _item.typeId).includes(item.typeId)}
                              value={item.typeId}
                              key={item.typeId}
                            >
                              {item.typeName}
                            </Select.Option>
                          ))}
                      </Select>
                    )
                  },
                },
                {title: '金额（元）', dataIndex: 'amt', render: value => value ?? '/'},
                {
                  title: '',
                  dataIndex: 'typeId',
                  render: (value, record) => {
                    return (
                      <MinusCircleOutlined
                        style={{fontSize: 14, color: '#FF4D4D'}}
                        onClick={() => {
                          if (record.recordId) {
                            setDeleteList(state => [...state, record.recordId])
                          }
                          setOrderSalaryList(state => state.filter(item => item.typeId !== value))
                        }}
                      />
                    )
                  },
                },
              ]}
            />
          </div>
        </div>
      </Drawer>

      {/* 终审确认 */}
      <Modal
        visible={confirmVisible}
        width={438}
        title={'提示'}
        className={styles.confirm}
        centered
        footer={null}
        onCancel={() => setConfirmVisible(false)}
      >
        <Typography.Text>是否确认最终绩效方案！终审确认后，将不可更改。请慎重操作！</Typography.Text>
        <Row justify={'end'} className={styles.row}>
          <Space>
            <Button onClick={() => setConfirmVisible(false)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                setConfirmVisible(false)
                await stewardModel.stewardSalaryCheck({salaryId})
                history.goBack()
                await message.success('确认成功')
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      <Modal
        visible={violationVisible}
        title={'违规线设置'}
        afterClose={() => setViolationPercent(null)}
        confirmLoading={stewardModel.stewardSalarySetViolationPercent.useLoading()}
        onCancel={() => setViolationVisible(false)}
        onOk={async () => {
          if (!/^\d+$/.test(`${violationPercent}`)) {
            return message.error('百分比不正确！')
          }
          await stewardModel.stewardSalarySetViolationPercent({salaryId, percent: violationPercent})
          message.success('设置成功！')
          setViolationVisible(false)
        }}
        centered
      >
        <Form>
          <Form.Item label={'管家'} shouldUpdate>
            {() => salaryDetail.stewardName}
          </Form.Item>
          <Form.Item label={'有效单量'}>{salaryDetail.orderNumValid}</Form.Item>
          <Form.Item label={'百分比'}>
            <Space>
              <InputNumber
                min={0}
                max={100}
                placeholder={'请输入'}
                value={violationPercent}
                onChange={value => setViolationPercent(value)}
                onBlur={async value => {
                  if (/^\d*$/.test(`${value.target.value}`)) {
                    const nextPercent = Math.min(100, Math.max(0, +value.target.value))
                    setViolationPercent(nextPercent)
                    const {
                      response: {data},
                    } = await stewardModel.stewardSalaryCalculateViolationLine({
                      orderNumValid: salaryDetail.orderNumValid,
                      percent: nextPercent,
                    })
                    setViolation(data)
                  } else {
                    setViolationPercent(null)
                  }
                }}
              />
              %
            </Space>
          </Form.Item>
          <Form.Item label={'违规线'} shouldUpdate>
            {violation ?? '/'}
          </Form.Item>
        </Form>
      </Modal>

      {/* 终审撤回 */}
      <Modal
        visible={cancelVisible}
        title={'终审撤回'}
        centered
        onCancel={() => setCancelVisible(false)}
        onOk={async () => {
          await stewardModel.stewardSalaryRollback({salaryId: salaryDetail.salaryId, rollbackReason})
          querySalaryDetail()
          setCancelVisible(false)
        }}
        afterClose={() => setRollbackReason('')}
      >
        <Form.Item label={'撤回原因'}>
          <Input.TextArea
            rows={3}
            value={rollbackReason}
            onChange={e => setRollbackReason(e.target.value)}
            maxLength={200}
            showCount
            placeholder='请输入'
          />
        </Form.Item>
      </Modal>
    </Page>
  )
}
