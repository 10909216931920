import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Col, DatePicker, Form, FormInstance, Row, Select, Space, Table} from 'antd'
import moment from 'moment/moment'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {FORMAT_YEAR_MONTH} from '@/common/date'
import {stewardModel} from '@/store/models/Steward'
import {sysUserModel} from '@/store/models/SysUser'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {getImg} from '@/utils/util'
import {commonModel} from '@/store/models/Common'
import {PreviewButton} from './component/PreviewButton'

export default function ListView(props: {form: FormInstance}) {
  const form = props.form

  const [dataSource, setDataSource] = useState<StewardTransferMixedVO[]>([])
  const [total, setTotal] = useState(0)

  const [stewardList, setStewardList] = useState<SysUserVO[]>([])
  const stewardListWithMobile = useMemo(
    () => stewardList.map(value => ({...value, name: `${value.name}（${value.mobile}）`})),
    [stewardList]
  )

  useEffect(() => {
    sysUserModel
      .querySysUserList({roleId: 'steward', pageable: false})
      .then(({response: {data}}) => setStewardList(data))
  }, [])

  const [appList, setAppList] = useState<StewardOpenAppVO[]>([])
  useEffect(() => {
    stewardModel
      .stewardOpenAppList({pageNum: 1, pageSize: 10, pageable: false})
      .then(({response: {data}}) => setAppList(data))
  }, [])

  const [cityList, setCityList] = useState<CityDataProps[]>([])
  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  const loading = stewardModel.stewardAdminTransferListMixed.useLoading()
  const search = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardAdminTransferListMixed(values)
    setDataSource(data)
    setTotal(total)
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const days = dataSource?.[0]?.dayList?.length || 31

  return (
    <>
      <Form
        form={form}
        labelCol={{flex: '80px'}}
        initialValues={{month: moment().format(FORMAT_YEAR_MONTH), pageNum: 1, pageSize: 10, pageable: true}}
        onFinish={search}
        onReset={search}
      >
        <div className={commonStyles.filterSection}>
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item
                label={'管家'}
                name={'stewardIdList'}
                getValueFromEvent={e => (e ? [e] : e)}
                getValueProps={value => ({value: value?.[0]})}
              >
                <Select
                  placeholder={'请输入管家名称'}
                  options={stewardListWithMobile}
                  fieldNames={{label: 'name', value: 'userId'}}
                  showSearch
                  filterOption={(inputValue, option) => option.name?.indexOf(inputValue) > -1}
                  onChange={async (_, option) => {
                    if (!Array.isArray(option)) {
                      if (option && option.userId) {
                        const {
                          response: {data},
                        } = await sysModel.sysOrgMemberOrgPath({userId: option.userId})
                        form.setFieldsValue({_orgPath: data})
                      } else {
                        form.setFieldsValue({_orgPath: ''})
                      }
                    }
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {form1 => {
                  const _orgPath = form1.getFieldValue('_orgPath')
                  return (
                    !!_orgPath && (
                      <Form.Item label={' '} colon={false} style={{margin: '-24px 0 0'}}>
                        <span style={{color: '#f00'}}>{_orgPath}</span>
                      </Form.Item>
                    )
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'月份'}
                name={'month'}
                getValueFromEvent={event => (event ? event.format(FORMAT_YEAR_MONTH) : event)}
                getValueProps={value => ({value: value ? moment(value) : value})}
              >
                <DatePicker.MonthPicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'stewardStatus'}>
                <Select
                  allowClear
                  placeholder={'请选择'}
                  options={[
                    {label: '正常', value: '1'},
                    {label: '禁用', value: '0'},
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务标签'} name={'appIdList'}>
                <Select
                  placeholder={'请选择'}
                  options={appList}
                  fieldNames={{label: 'appName', value: 'appId'}}
                  allowClear
                  showArrow
                  showSearch
                  filterOption={(inputValue, option) => option.appName?.indexOf(inputValue) > -1}
                  mode={'multiple'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'城市'} name={'cityCodeList'}>
                <Select
                  placeholder={'请选择'}
                  options={cityList}
                  fieldNames={{label: 'name', value: 'code'}}
                  allowClear
                  showArrow
                  showSearch
                  filterOption={(inputValue, option) => option.name?.indexOf(inputValue) > -1}
                  mode={'multiple'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'离职日期'}
                name={'dimissionDateBegin'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue('dimissionDateEnd', formatString?.[1])
                  return formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('dimissionDateEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker placeholder={['请选择', '请选择']} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldValue('pageNum', 1)}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.filterSection}>
          <Table
            loading={loading}
            rowKey={'stewardId'}
            dataSource={dataSource}
            scroll={{x: 'max-content'}}
            columns={[
              {
                title: '管家',
                fixed: 'left',
                render: (_, record) => (
                  <Space direction={'vertical'}>
                    <span>{record.stewardName}</span>
                    <span>({record.stewardMobile})</span>
                  </Space>
                ),
              },
              {title: '服务标签', dataIndex: 'appName'},
              {
                title: '所属城市',
                render: (_, record) => {
                  const citys = record.cityName.split(',')
                  return (
                    <span title={record.cityName}>
                      {citys.length > 2 ? citys.slice(0, 2).join(',') + '...' : record.cityName}
                    </span>
                  )
                },
              },
              {title: '离职日期', dataIndex: 'dimissionDate'},
              ...Array.from({length: days}).map((_, index) => ({
                title: index + 1,
                dataIndex: 'dayList',
                width: 90,
                align: 'center' as const,
                render: (dayList: StewardTransferListDayVO[]) => {
                  const record = dayList?.[index]
                  return (
                    <Space>
                      <span>{record?.vacationTypeName}</span>
                      {record?.checkStatus === 1 && <PreviewButton src={getImg(record.checkPicUrl)} />}
                    </Space>
                  )
                },
              })),
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>
    </>
  )
}
