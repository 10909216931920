import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {
  Button,
  Calendar,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import DividerLine from '@/pages/property/component/DividerLine'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment/moment'
import {FORMAT_DATE} from '@/common/date'
import {APP_ID} from '@/common/constants'
import {useCodeSync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'

const middayMap = {
  AM: '上午',
  PM: '下午',
}

export default function LonghuPerformanceDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/performance-longhu/detail'>()

  const salaryId = location.state?.salaryId

  const [auth] = usePermission()

  const levelObject = useCodeSync('sys.user.steward.position.level')

  const [detail, setDetail] = useState({} as StewardSalaryLhVO)
  // const rewardList = useMemo(
  //   () => [
  //     {label: '迟到率', assessResult: '', assessScore: ''},
  //     {label: '提前沟通率', assessResult: '', assessScore: ''},
  //     {label: '一次性解决率', assessResult: '', assessScore: ''},
  //     {label: '二次性解决率', assessResult: '', assessScore: ''},
  //     {label: '好评率', assessResult: '', assessScore: ''},
  //     {label: '录音合格率', assessResult: '', assessScore: ''},
  //     {label: '执行力', assessResult: '', assessScore: ''},
  //   ],
  //   []
  // )

  const query = useCallback((id: string) => {
    stewardModel.stewardSalaryLhDetail({salaryId: id}).then(({response: {data}}) => {
      data && setDetail(data)
    })
  }, [])

  useEffect(() => {
    salaryId && query(salaryId)
  }, [query, salaryId])

  const [form] = Form.useForm()

  const [dataSource, setDataSource] = useState<StewardSalaryLhOrderVO[]>([])
  const [total, setTotal] = useState(0)
  const search = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardSalaryLhListOrder(values)
    setTotal(total)
    setDataSource(data)
  }, [form])
  const loading = stewardModel.stewardSalaryLhListOrder.useLoading()

  // 页面加载时触发
  useEffect(() => {
    if (detail.stewardId) {
      form.submit()
    }
  }, [detail.stewardId, form])

  const [modal, contextHolder] = Modal.useModal()

  const salaryConfirm = async (record: StewardSalaryLhVO) => {
    const statusText = record.status === 1 ? '撤回' : '确认'
    const instance = Modal.confirm({
      icon: null,
      title: `绩效${statusText}`,
      content: `确定要${statusText}${record.stewardName}、${record.salaryDate}月份绩效？`,
      onOk: async () => {
        try {
          instance.update({okButtonProps: {loading: true}})
          await stewardModel.stewardSalaryLhUpdateBatch({
            confirmOrCancel: record.status === 1 ? 0 : 1,
            idList: [record.salaryId],
          })
          query(salaryId)
          // form.submit()
        } catch (e) {
          return Promise.reject(e)
        } finally {
          instance.update({okButtonProps: {loading: false}})
        }
      },
    })
  }

  const [rewardModelDetail, setRewardModelDetail] = useState<StewardSalaryLhVO>(null)
  const [rewardForm] = Form.useForm()
  const [salaryTypeList, setSalaryTypeList] = useState<StewardSalaryTypeVO[]>([])

  useEffect(() => {
    if (rewardModelDetail && !salaryTypeList?.length) {
      stewardModel
        .stewardSalaryTypeList({
          appId: APP_ID.GUANYU,
          enable: 1,
          pageable: false,
          pageNum: 1,
          pageSize: 10,
        })
        .then(({response: {data}}) => data && setSalaryTypeList(data))
    }
  }, [rewardModelDetail, salaryTypeList?.length])

  // /steward/salary/type/list 额外奖惩方案

  if (!detail.stewardId) return <Spin />

  return (
    <Page header={<PageHeader ghost={false} title={'绩效详情'} onBack={() => history.goBack()} />}>
      {contextHolder}
      <Row gutter={20} wrap={false}>
        <Col flex={1}>
          <div className={commonStyles.filterSection}>
            <Descriptions
              column={4}
              title={<DividerLine title={'管家基本信息'} />}
              extra={
                <Space>
                  {auth('018183002') && (
                    <Button disabled={detail.status === 1} onClick={() => salaryConfirm(detail)}>
                      绩效确认
                    </Button>
                  )}
                  {auth('025183002') && (
                    <Button disabled={detail.status === 0} onClick={() => salaryConfirm(detail)}>
                      绩效确认撤回
                    </Button>
                  )}
                </Space>
              }
            >
              <Descriptions.Item label={'姓名'}>{detail.stewardName}</Descriptions.Item>
              <Descriptions.Item label={'手机号码'}>{detail.stewardMobile}</Descriptions.Item>
              <Descriptions.Item label={'所在站点'}>
                {detail.stewardWarehouseName?.split(',')?.length ? (
                  detail.stewardWarehouseName.split(',').length === 1 ? (
                    detail.stewardWarehouseName
                  ) : (
                    <Tooltip title={detail.stewardWarehouseName}>
                      {detail.stewardWarehouseName.split(',')[0] + '...'}
                    </Tooltip>
                  )
                ) : (
                  '/'
                )}
              </Descriptions.Item>
              <Descriptions.Item label={'所在组织'}>{detail.stewardOrgName}</Descriptions.Item>
              <Descriptions.Item label={'用户组'}>{detail.stewardRoleName}</Descriptions.Item>
              {/*<Descriptions.Item label={'师傅'}>{detail.stewardTeacherName}</Descriptions.Item>*/}
              <Descriptions.Item label={'虚拟号码'}>{detail.privateNum}</Descriptions.Item>
              <Descriptions.Item label={'入职时间'}>{detail.stewardEntryTime}</Descriptions.Item>
              <Descriptions.Item label={'岗位等级'}>{levelObject[detail.stewardPositionLevel]}</Descriptions.Item>
              <Descriptions.Item label={'管家底薪'}>{detail.basicSalary}</Descriptions.Item>
              <Descriptions.Item label={'管家绩效'}>{detail.performance}</Descriptions.Item>
              {/*<Descriptions.Item label={'团队成员'}>200</Descriptions.Item>*/}
              {/*<Descriptions.Item label={'上级领导'}>200</Descriptions.Item>*/}
              <Descriptions.Item label={'绩效方案'}>{detail.planName}</Descriptions.Item>
              <Descriptions.Item label={'适用时间'}>
                {detail.applyBeginDate} - {detail.applyEndDate}
              </Descriptions.Item>
              <Descriptions.Item label={'离职日期'}>
                {!!detail.stewardDimissionDate && moment(detail.stewardDimissionDate).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label={'绩效月份'}>{detail.salaryDate}</Descriptions.Item>
            </Descriptions>
          </div>
          <div className={commonStyles.filterSection}>
            <DividerLine title={'服务单统计'} bottom={20} />
            <Form
              form={form}
              onFinish={search}
              onReset={search}
              initialValues={{
                pageNum: 1,
                pageSize: 10,
                pageable: true,
                stewardId: detail.stewardId,
                timeFinishedBegin: moment().subtract(3, 'month').format(FORMAT_DATE),
                timeFinishedEnd: moment().format(FORMAT_DATE),
              }}
            >
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label={'服务类型'} name={'category'}>
                    <Input placeholder={'请输入下单类型'} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'服务单ID'} name={'orderId'}>
                    <Input placeholder={'请输入服务单ID'} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'下单时间'}
                    name={'timeMakeBegin'}
                    getValueFromEvent={(_, formatString) => {
                      form.setFieldValue('timeMakeEnd', formatString?.[1])
                      return formatString?.[0]
                    }}
                    getValueProps={value => ({
                      value: value ? [moment(value), moment(form.getFieldValue('timeMakeEnd'))] : value,
                    })}
                  >
                    <DatePicker.RangePicker format={FORMAT_DATE} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'预约时间'}
                    name={'timeAppointBegin'}
                    getValueFromEvent={(_, formatString) => {
                      form.setFieldValue('timeAppointEnd', formatString?.[1])
                      return formatString?.[0]
                    }}
                    getValueProps={value => ({
                      value: value ? [moment(value), moment(form.getFieldValue('timeAppointEnd'))] : value,
                    })}
                  >
                    <DatePicker.RangePicker format={FORMAT_DATE} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'到达时间'}
                    name={'timeArrivedBegin'}
                    getValueFromEvent={(_, formatString) => {
                      form.setFieldValue('timeArrivedEnd', formatString?.[1])
                      return formatString?.[0]
                    }}
                    getValueProps={value => ({
                      value: value ? [moment(value), moment(form.getFieldValue('timeArrivedEnd'))] : value,
                    })}
                  >
                    <DatePicker.RangePicker format={FORMAT_DATE} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'完成时间'}
                    name={'timeFinishedBegin'}
                    getValueFromEvent={(_, formatString) => {
                      form.setFieldValue('timeFinishedEnd', formatString?.[1])
                      return formatString?.[0]
                    }}
                    getValueProps={value => ({
                      value: value ? [moment(value), moment(form.getFieldValue('timeFinishedEnd'))] : value,
                    })}
                  >
                    <DatePicker.RangePicker format={FORMAT_DATE} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                  {auth('007183002') && (
                    <Button
                      type={'primary'}
                      onClick={() => {
                        const value = form.getFieldsValue(true)
                        downloadBlob({url: '/steward/salary/lh/list-order-excel', body: value})
                      }}
                    >
                      导出
                    </Button>
                  )}
                </Space>
              </Row>
            </Form>
          </div>

          <div className={commonStyles.tableSection}>
            <Table
              loading={loading}
              dataSource={dataSource}
              rowKey={'orderId'}
              scroll={{x: 'max-content'}}
              columns={[
                {title: '服务单ID', dataIndex: 'orderId'},
                {title: '渠道', dataIndex: 'appName'},
                {
                  title: '类别',
                  render: (_, record) =>
                    [record.categoryFirName, record.categorySecName, record.categoryThiName].join('-'),
                },
                {title: '详细地址', dataIndex: 'propertyAddr'},
                {
                  title: '第一接单人是否客服',
                  dataIndex: 'stewardIdFirstIsCustomerCenter',
                  render: value => (value ? '是' : '否'),
                },
                {title: '接单人', dataIndex: 'stewardName'},
                {title: '下单时间', dataIndex: 'timeMake'},
                {title: '接单时间', dataIndex: 'timeFirstContact'},
                {title: '预约上门时间', dataIndex: 'timeAppoint'},
                {title: '到达时间', dataIndex: 'timeArrived'},
                {title: '完成时间', dataIndex: 'timeFinished'},
                {title: '是否迟到', dataIndex: 'late', render: value => (value ? '是' : '否')},
                {title: '是否提前沟通', dataIndex: 'communication', render: value => (value ? '是' : '否')},
                {title: '解决次数', dataIndex: 'passTimes'},
                {
                  title: '录音是否合格',
                  dataIndex: 'recordPass',
                  render: value => (typeof value === 'boolean' ? (value ? '合格' : '不合格') : '未抽查'),
                },
                {title: '综合评价', dataIndex: 'remarkScore'},
                {title: '额外收费', dataIndex: 'feeTotal'},
                {title: '提成', dataIndex: 'feeCommission'},
                {
                  title: '操作',
                  render: (_, record) => (
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push({pathname: '/steward/detail', state: {orderId: record.orderId}})}
                    >
                      查看
                    </Button>
                  ),
                },
              ]}
              pagination={getPaginationProps(form, total)}
            />
          </div>
        </Col>
        <Col flex={'333px'}>
          <div className={commonStyles.filterSection}>
            <Row justify={'space-between'}>
              <Col>
                {auth('003183002') && (
                  <Button type={'link'} style={{padding: 0}} onClick={() => setRewardModelDetail(detail)}>
                    新增奖惩
                  </Button>
                )}
              </Col>
              <Col>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    modal.info({
                      icon: null,
                      width: 1200,
                      closable: true,
                      title: `${detail.stewardName}${detail.salaryDate}月的出勤记录`,
                      maskClosable: true,
                      content: (
                        <div>
                          <div>
                            出勤天数：
                            {detail.performanceBO?.mealAllowanceList?.filter(value => value.vacationTypeId === -3)
                              ?.length / 2}
                            天，金额合计：
                            {detail.mealAllowance}元
                          </div>
                          <Calendar
                            headerRender={() => null}
                            validRange={
                              [
                                moment(detail.salaryDate).startOf('month'),
                                moment(detail.salaryDate).endOf('month'),
                              ] as any
                            }
                            defaultValue={moment(detail.salaryDate) as any}
                            dateFullCellRender={date => {
                              const items = detail.performanceBO.mealAllowanceList.filter(value =>
                                date.isSame(value.beginDate, 'date')
                              )

                              return (
                                <div
                                  style={{
                                    height: 90,
                                    margin: 2,
                                    paddingRight: 10,
                                    textAlign: 'left',
                                    ...([-1, -2].includes(items[0]?.vacationTypeId) ? {backgroundColor: '#ccc'} : {}),
                                  }}
                                >
                                  <div style={{textAlign: 'right'}}>{date.date()}</div>
                                  {items.map((value, index) => (
                                    <div key={index} style={{fontSize: 12, whiteSpace: 'nowrap'}}>{`${
                                      middayMap[value.beginMidday]
                                    }：${value.vacationTypeName}`}</div>
                                  ))}
                                </div>
                              )
                            }}
                          />
                        </div>
                      ),
                    })
                  }}
                >
                  出勤记录
                </Button>
              </Col>
            </Row>

            <Table
              style={{marginTop: 12, marginBottom: 12}}
              rowKey={'name'}
              dataSource={detail.performanceBO?.detailList}
              pagination={false}
              columns={[
                {title: '综合评定', dataIndex: 'name'},
                {title: '考核结果', dataIndex: 'key'},
                {title: '考核分数', dataIndex: 'value'},
              ]}
            />
            <Descriptions column={1}>
              <Descriptions.Item label='综合薪资'>
                {detail.totalSalary}元（底薪{detail.basicSalary}元，绩效{detail.performance}元）
              </Descriptions.Item>
              <Descriptions.Item label='绩效系数'>{detail.ratingFactor}</Descriptions.Item>
              <Descriptions.Item label='评定绩效金额'>{detail.ratingPerformance}元</Descriptions.Item>
              <Descriptions.Item label='额外奖惩'>
                <Button
                  type={'link'}
                  style={{padding: 0, height: 'auto'}}
                  onClick={() => {
                    Modal.info({
                      icon: null,
                      width: 700,
                      title: '额外奖惩',
                      content: (
                        <div>
                          <div>合计金额：{detail.rewardAmount}元</div>
                          <Table
                            rowKey={(_, index) => index}
                            dataSource={detail.rewardDetailList}
                            columns={[
                              {title: '名称', dataIndex: 'typeName'},
                              {
                                title: '奖罚类型',
                                dataIndex: 'amt',
                                render: value => (value < 0 ? '罚' : value > 0 ? '奖' : ''),
                              },
                              {title: '金额', dataIndex: 'amt'},
                              {title: '新增人', dataIndex: 'insertBy'},
                              {title: '新增时间', dataIndex: 'insertTime'},
                            ]}
                          />
                        </div>
                      ),
                    })
                  }}
                >
                  {detail.rewardAmount}元
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label='餐补'>{detail.mealAllowance}元</Descriptions.Item>
              <Descriptions.Item label='提成'>{detail.commission}元</Descriptions.Item>
              <Descriptions.Item label='薪资合计'>{detail.salaryTotal}元</Descriptions.Item>
              <Descriptions.Item>
                <Divider style={{margin: 0}} />
              </Descriptions.Item>
              <Descriptions.Item label='最后确认人'>{detail.confirmName}</Descriptions.Item>
              <Descriptions.Item label='最后确认时间'>{detail.confirmTime}</Descriptions.Item>
            </Descriptions>
          </div>
        </Col>
      </Row>

      <Modal
        title={'新增奖惩绩效方案'}
        open={!!rewardModelDetail}
        destroyOnClose
        afterClose={() => rewardForm.resetFields()}
        onOk={async () => {
          await rewardForm.validateFields()
          const values = rewardForm.getFieldsValue(true)
          // console.log('values', values)
          await stewardModel.stewardSalaryLhTypeUse({...values, addOrDelete: 1, idList: [detail.salaryId]})
          setRewardModelDetail(null)
          query(salaryId)
        }}
        onCancel={() => setRewardModelDetail(null)}
      >
        <Form
          form={rewardForm}
          labelCol={{flex: '100px'}}
          onFinish={() => {
            // stewardModel.stewardSalaryLhTypeUse({})
          }}
        >
          <Form.Item label={'名称'}>{rewardModelDetail?.stewardName}</Form.Item>
          <Form.Item label={'手机号'}>{rewardModelDetail?.stewardMobile}</Form.Item>
          <Form.Item label={'组织'}>{rewardModelDetail?.stewardOrgName}</Form.Item>
          <Form.Item label={'所在用户组'}>{rewardModelDetail?.stewardRoleName}</Form.Item>
          <Form.Item label={'在职状态'}>{rewardModelDetail?.stewardName}</Form.Item>
          <Form.Item label={'岗位等级'}>{rewardModelDetail?.stewardPositionLevel}</Form.Item>
          <Form.Item
            label={'额外奖惩方案'}
            name={'typeId'}
            // rules={[{required: true}]}
            getValueFromEvent={(event, option) => {
              rewardForm.setFieldValue('amt', option?.amt)
              return event
            }}
          >
            <Select
              options={salaryTypeList}
              showSearch
              filterOption={(inputValue, option: any) =>
                option?.typeName?.toLowerCase()?.includes(inputValue?.toLowerCase())
              }
              fieldNames={{label: 'typeName', value: 'typeId'}}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={' '}
            colon={false}
            shouldUpdate={(prevValues, nextValues) => prevValues.typeId !== nextValues.typeId}
          >
            {f => {
              const amt = f.getFieldValue('amt')
              if (!amt) return null
              return amt > 0 ? (
                <Typography.Link>奖：{amt}元</Typography.Link>
              ) : (
                <Typography.Text type={'danger'}>罚：{Math.abs(amt)}元</Typography.Text>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
