import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import styles from '@/pages/client/ClientInfo/index.module.less'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import moment from 'moment/moment'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {lessorModel} from '@/store/models/Lessor'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {customerModel} from '@/store/models/Customer'
import {usePermission} from '@/components/Permission'
import EditBill from '@/pages/client/components/EditBill'
import ConfirmBill from '@/pages/client/components/ConfirmBill'
import DeleteServiceBill from '@/pages/client/components/DeleteServiceBill'
import AuditBill from '@/pages/client/components/AuditBill'
import {downloadBlob} from '@/utils/util'
import AddServiceBill from '@/pages/client/components/AddServiceBill'
import ServiceBillInfo from '@/pages/client/components/ServiceBillInfo'
import {userModel} from '@/store/models/User'
import {CloseCircleOutlined, DownCircleOutlined, UpCircleOutlined} from '@ant-design/icons'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

type Props = {
  userId?: string
  contractId?: string
}
function _ClientBillComponent({userId, contractId}: Props, ref) {
  const [auth] = usePermission()
  const [list, setList] = useState<LessorBillVO[]>([])
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const billStatusOptions = useCodeArraySync('lessor.bill')
  const billTypeOptions = useCodeArraySync('lessor.billType')
  const billTypeCode = useCodeSync('lessor.billType')
  const lessorBillSourceOptions = useCodeArraySync('lessor.bill.source')
  const lessorBillSourceCode = useCodeSync('lessor.bill.source')
  const billStatusCode = useCodeSync('lessor.bill')
  const invoiceCompanyArr = useCodeArraySync('invoice.company')
  const invoiceTypeArr = useCodeArraySync('invoice.type')
  const invoiceTitleTypeArr = useCodeArraySync('invoice.title.type')
  const loading = lessorModel.userLessorBillList.useLoading()
  const editRef = useRef(null)
  const confirmRef = useRef(null)
  const deleteRef = useRef(null)
  const auditRef = useRef(null)
  const addBillRef = useRef(null)
  const serviceInfoRef = useRef(null)
  const [confirmBill, setConfirmBill] = useState({visible: false, billId: '', billName: ''})

  const [selectedRows, setSelectedRows] = useState<LessorBillVO[]>([])
  // 开票
  const [invoiceForm] = Form.useForm()
  const [invoiceOpen, setInvoiceOpen] = useState(false)

  const search = useCallback(() => {
    setSelectedRows([])
    const formValues = form.getFieldsValue(true)
    lessorModel.userLessorBillList({...formValues}).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  const [customerList, setCustomerList] = useState<LessorCustomerListVO[]>([])
  useEffect(() => {
    customerModel
      .customerList({pageNum: 1, pageSize: 1, pageable: false} as LessorCustomerListDTO)
      .then(({response: {data}}) => {
        setCustomerList(data)
      })
  }, [])

  function billOperation(info: LessorBillVO) {
    const {
      lessorId,
      billId,
      billName,
      billStatus,
      billType,
      invoiceStatus,
      billAmtTotal,
      lessorName,
      lessorMobile,
    } = info
    const operate = [
      {
        when: auth('001003002'),
        title: '查看',
        onClick: () => {
          serviceInfoRef.current?.onDisplay()
          serviceInfoRef.current?.onSetInfo(info)
        },
      },
      {
        when: ['1', '4', '5'].includes(billStatus) && auth('002003002'),
        title: '编辑',
        onClick: () => {
          editRef.current?.onDisplay()
          editRef.current?.onSetInfo(info)
        },
      },
      {
        when: billStatus === '5' && auth('018003002') && !!userId,
        title: '确认账单',
        onClick: () => {
          setConfirmBill({billId, billName, visible: true})
        },
      },
      {
        when: (billStatus === '1' || (billStatus === '4' && billType === '2')) && auth('008003002'),
        title: '确认收款',
        onClick: () => {
          confirmRef.current?.onDisplay()
          confirmRef.current?.onSetInfo(info)
        },
      },
      {
        when: billStatus === '2',
        title: '审核',
        onClick: () => {
          auditRef.current?.onDisplay()
          auditRef.current?.onSetInfo(info)
        },
      },
      {
        when: auth('004003002') && billStatus !== '3',
        title: '删除',
        onClick: () => {
          deleteRef.current?.onDisplay()
          deleteRef.current?.onSetInfo(billId, billName)
        },
      },
      {
        when: auth('028003002') && invoiceStatus === '0' && !userId,
        title: '开票申请',
        onClick: () => {
          invoiceForm.setFieldsValue({
            list: [
              {
                billIdList: [billId],
                invoiceAmount: billAmtTotal,
                customerName: lessorName,
                customerId: lessorId,
                customerMobile: lessorMobile,
                show: true,
              },
            ],
          })
          setInvoiceOpen(true)
        },
      },
    ]

    return (
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {operate
          .filter(value => value.when)
          .map((item, index) => (
            <div key={`${item} - ${index}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {index !== 0 && <Divider type={'vertical'} />}
              <Button
                type={'link'}
                style={{padding: 0}}
                onClick={event => {
                  event.stopPropagation()
                  item.onClick()
                }}
              >
                {item.title}
              </Button>
            </div>
          ))}
      </div>
    )
  }

  useImperativeHandle(ref, () => ({
    // 确认收款
    billConfirmAll: async () => {
      let name = ''
      let total = 0
      let ids = []
      if (selectedRows.length) {
        // 勾选批量处理，校验走前端逻辑
        name = selectedRows[0].lessorName
        const lessorId = selectedRows[0].lessorId
        ids = selectedRows.map(item => item.billId)
        total = selectedRows.reduce((sum, item) => (sum += item.billAmtTotal), 0)
        if (!selectedRows.every(item => item.lessorId === lessorId))
          return message.warning('只有同客户才能批量确认账单！')
        if (
          // !(
          //   selectedRows.every(item => item.billStatus === '1') ||
          //   selectedRows.every(item => item.billStatus === '4' && item.billType === '2')
          // )
          !selectedRows.every(item => item.billStatus === '1' || (item.billStatus === '4' && item.billType === '2'))
        )
          return message.warning('只有待审批的额外费用或待付款状态才可以确认账单！')
      } else {
        const values = form.getFieldsValue(true)
        const {
          response: {
            data: {lessorName, totalAmt, billIdList},
          },
        } = await lessorModel.userLessorBillConfirmQuery(values)
        name = lessorName
        total = totalAmt
        ids = billIdList
      }

      Modal.confirm({
        title: '确认收款',
        icon: false,
        centered: true,
        content: (
          <>
            确认将客户“{name}”应收金额“{total}元”账单都确认？
          </>
        ),
        onOk: () => {
          confirmRef.current.onDisplay()
          confirmRef.current.onSetInfo(ids)
        },
      })
    },
    // 导出
    export: () => {
      downloadBlob({
        url: '/user/lessor/bill/list-excel',
        body: form.getFieldsValue(true),
      })
    },

    // 导入
    import: () => form.submit(),

    // 添加服务账单
    addBill: () => {
      addBillRef.current.onDisplay()
      addBillRef.current.onSetInfo(userId)
    },

    //开票
    invoice: () => {
      if (selectedRows.length === 0) return message.error('请选择开票账单')
      if (selectedRows.some(item => item.invoiceStatus === '1')) return message.error('请选择未开票的账单')
      let arr = []
      let key = ''
      const obj = selectedRows.reduce((res, item) => {
        key = item.lessorId
        arr = res[item.lessorId] ?? []
        res[key] = [...arr, item]
        return res
      }, {}) as {string: LessorBillVO[]}

      const list = Object.values(obj).map(values => {
        const invoiceAmount = values.reduce((res, item) => {
          return (res += item.billAmtTotal || 0)
        }, 0)
        return {
          billIdList: values.map(item => item.billId),
          invoiceAmount,
          customerName: values[0].lessorName,
          customerId: values[0].lessorId,
          customerMobile: values[0].lessorMobile,
          show: true,
        }
      })
      invoiceForm.setFieldsValue({list})
      setInvoiceOpen(true)
    },

    // 批量删除
    batchDelete: () => {
      if (selectedRows.length === 0) return message.error('请选择账单')
      if (selectedRows.some(item => item.billStatus === '3')) return message.error('请选择未付款的账单')
      Modal.confirm({
        title: '提示',
        icon: null,
        centered: true,
        closable: true,
        content: '是否确认删除？',
        onOk: async () => {
          await lessorModel.userLessorBillDeleteBatch(selectedRows.map(value => value.billId))
          form.submit()
          setSelectedRows([])
        },
      })
    },
  }))

  return (
    <>
      <div className={styles.form}>
        <Form
          form={form}
          onFinish={search}
          onReset={search}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, lessorId: userId, billContractId: contractId}}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'billName'} label={'账单名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'billId'} label={'账单ID'}>
                <Input placeholder={'请输入账单ID:账单ID1,账单ID2...'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'billStatus'} label={'账单状态'}>
                <Select placeholder={'请选择'} options={billStatusOptions} fieldNames={{value: 'id'}} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'billType'} label={'账单类型'}>
                <Select
                  placeholder={'请选择'}
                  options={billTypeOptions.filter(value => value.id !== '3')}
                  fieldNames={{value: 'id'}}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'_billDate'} label={'应收款日'}>
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      billDateBegin: formatString?.[0],
                      billDateEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            {/* todo: 新增公寓名称、房源地址筛选 */}
            <Col span={8}>
              <Form.Item name={'condoName'} label={'公寓名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'propertyAddr'} label={'房源地址'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'stewardOrderId'} label={'服务单号'}>
                <Input placeholder={'请输入服务单号:服务单号1,服务单号2...'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'billSource'} label={'生成方式'}>
                <Select
                  placeholder={'请选择'}
                  options={lessorBillSourceOptions}
                  fieldNames={{value: 'id'}}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'_time'} label={'账单周期'}>
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      billBegin: formatString?.[0],
                      billEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            {!userId && (
              <>
                <Col span={8}>
                  <Form.Item name={'lessorId'} label={'客户名称'}>
                    <Select
                      options={customerList}
                      fieldNames={{label: 'certName', value: 'userId'}}
                      placeholder={'请选择'}
                      showSearch
                      filterOption={(inputValue, option) => {
                        return option.certName?.indexOf(inputValue) > -1
                      }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={'lessorMobile'} label={'手机号码'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={8}>
              <Form.Item name={'_billCreateTimeBegin'} label={'生成时间'}>
                <DatePicker.RangePicker
                  allowClear
                  showTime
                  placeholder={['开始时间', '结束时间']}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      billCreateTimeBegin: formatString?.[0],
                      billCreateTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'_billPayTime'} label={'收款日期'}>
                <DatePicker.RangePicker
                  allowClear
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      billPayTimeBegin: formatString?.[0] ? formatString?.[0] + ' 00:00:00' : undefined,
                      billPayTimeEnd: formatString?.[1] ? formatString?.[1] + ' 23:59:59' : undefined,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            {/* 新增： 最后付款日、应收金额 */}
            <Col span={8}>
              <Form.Item
                name={'lastPayDateBegin'}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('lastPayDateEnd'))] : undefined,
                })}
                getValueFromEvent={(_, dateString) => {
                  form.setFieldValue('lastPayDateEnd', dateString?.[1] && moment(dateString[1]).format(FORMAT_DATE_END))
                  return dateString?.[0] && moment(dateString[0]).format(FORMAT_DATE_START)
                }}
                label={'最后付款日'}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label={'应收金额'} shouldUpdate style={{marginBottom: 0}}>
                {f => {
                  const {billAmtTotalMin, billAmtTotalMax} = f.getFieldsValue(['billAmtTotalMin', 'billAmtTotalMax'])
                  return (
                    <Space>
                      <Form.Item
                        name={'billAmtTotalMin'}
                        rules={[
                          {
                            validator: (_, value, callback) => {
                              if (value) {
                                if (billAmtTotalMax && value > billAmtTotalMax)
                                  return callback('最小金额不能大于最大金额')
                                return callback()
                              } else {
                                if (billAmtTotalMax) return callback('请输入最小金额')
                                return callback()
                              }
                            },
                          },
                        ]}
                      >
                        <InputNumber style={{width: 150}} placeholder={'最小金额'} />
                      </Form.Item>
                      <Form.Item
                        name={'billAmtTotalMax'}
                        rules={[
                          {
                            validator: (_, value, callback) => {
                              if (value) {
                                if (billAmtTotalMin && value < billAmtTotalMin)
                                  return callback('最大金额不能小于最小金额')
                                return callback()
                              } else {
                                if (billAmtTotalMin) return callback('请输入最大金额')
                                return callback()
                              }
                            },
                          },
                        ]}
                      >
                        <InputNumber style={{width: 150}} placeholder={'最大金额'} />
                      </Form.Item>
                    </Space>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'billId'}
          rowSelection={{
            columnWidth: 50,
            selectedRowKeys: selectedRows.map(item => item.billId),
            onChange: (_, rows) => {
              setSelectedRows(rows)
            },
          }}
          columns={[
            {
              title: '账单ID',
              dataIndex: 'billId',
            },
            {
              title: '账单周期/服务单号',
              dataIndex: 'billType',
              render: (text, record) =>
                text === '2'
                  ? record.stewardOrderId || '/'
                  : `${moment(record.billBegin).format('YYYY-MM-DD')}至${moment(record.billEnd).format('YYYY-MM-DD')}`,
            },
            {
              title: '应收金额/应收款日',
              dataIndex: 'billAmtTotal',
              render: (text, record) => (
                <Space direction={'vertical'} size={0}>
                  <span>{record.billAmtTotal}</span>
                  <span>{moment(record.billDate).format('YYYY-MM-DD')}</span>
                </Space>
              ),
            },
            ...(userId
              ? []
              : [
                  {title: '客户名称', dataIndex: 'lessorName'},
                  {title: '手机号码', dataIndex: 'lessorMobile'},
                  {title: 'BD', dataIndex: 'managerName'},
                ]),
            {
              title: '账单名称',
              dataIndex: 'billName',
            },
            // 新增：公寓名称 房源地址
            {
              title: '公寓名称',
              dataIndex: 'condoName',
              render: value => (
                <Tooltip title={value?.length > 15 ? value : null}>
                  {value ? (value.length > 15 ? value.slice(0, 15) + '......' : value) : '/'}
                </Tooltip>
              ),
            },
            {
              title: '房源地址',
              dataIndex: 'propertyAddr',
              render: value => (
                <Tooltip title={value?.length > 15 ? value : null}>
                  {value ? (value.length > 15 ? value.slice(0, 15) + '......' : value) : '/'}
                </Tooltip>
              ),
            },
            {
              title: '账单类型',
              dataIndex: 'billType',
              render: text => billTypeCode[text],
            },
            {
              title: '生成方式',
              dataIndex: 'billSource',
              render: billSource => lessorBillSourceCode[billSource],
            },
            {title: '账单生成时间', dataIndex: 'billCreateTime'},
            // 新增： 最后付款日
            {
              title: '最后付款日',
              dataIndex: 'lastPayDate',
              render: text => text ?? '/',
            },
            {
              title: '收款方式',
              dataIndex: 'billPayWayName',
              render: value => value ?? '/',
            },
            {
              title: '收款金额',
              dataIndex: 'billPayAmt',
            },
            {
              title: '收款时间',
              dataIndex: 'billPayTime',
              render: text => text ?? '/',
            },
            {
              title: '状态',
              dataIndex: 'billStatus',
              key: 'billStatus',
              render: (value, record) => (
                <span>
                  {record.overdueDays
                    ? `${billStatusCode[value]}（逾期${record.overdueDays}天）`
                    : billStatusCode[value]}
                </span>
              ),
            },
            {
              title: '操作',
              fixed: 'right',
              render: record => billOperation(record),
            },
          ]}
          dataSource={list}
          loading={loading}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 编辑 */}
      <EditBill ref={editRef} onSuccess={() => form.submit()} />

      {/* 确认收款 */}
      <ConfirmBill
        ref={confirmRef}
        onSuccess={() => {
          form.submit()
          setSelectedRows([])
        }}
      />

      {/* 删除账单 */}
      <DeleteServiceBill ref={deleteRef} onSuccess={() => form.submit()} />

      {/* 账单审核 */}
      <AuditBill ref={auditRef} onSuccess={() => form.submit()} />

      {/* 确认账单 */}
      <Modal
        title={'提示'}
        open={confirmBill.visible}
        footer={null}
        centered
        onCancel={() => setConfirmBill(state => ({...state, visible: false}))}
      >
        <div>
          <p>{`是否确认${confirmBill.billName}账单？`}</p>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  setConfirmBill(state => ({...state, visible: false}))
                }}
              >
                取消
              </Button>
              <Button
                onClick={async () => {
                  await lessorModel.userLessorBillPreConfirm({billId: confirmBill.billId} as LessorBillPreConfirmDTO)
                  search()
                  setConfirmBill(state => ({...state, visible: false}))
                }}
                type={'primary'}
              >
                确认
              </Button>
            </Space>
          </Row>
        </div>
      </Modal>

      {/* 查看详情 */}
      <ServiceBillInfo ref={serviceInfoRef} onSuccess={() => form.submit()} />

      {/*  添加服务账单 */}
      <AddServiceBill ref={addBillRef} onSuccess={() => form.submit()} />

      {/*  开票申请  */}
      <Modal
        open={invoiceOpen}
        title={'开票申请'}
        width={700}
        destroyOnClose
        onCancel={() => setInvoiceOpen(false)}
        afterClose={() => {
          invoiceForm.resetFields()
        }}
        onOk={async () => {
          await invoiceForm.validateFields()
          const list = invoiceForm.getFieldValue('list')
          await Promise.all(
            list.map(item => {
              const keys = Object.keys(item)
              const newKeys = keys.filter(item => item !== 'show')
              const value = {}
              for (const key of newKeys) {
                value[key] = item[key]
              }
              return userModel.userLessorBillInvoiceMake(value as UserLessorBillInvoiceMakeDTO)
            })
          )
          setSelectedRows([])
          setInvoiceOpen(false)
          form.submit()
        }}
        okText={'提交申请'}
      >
        <Form form={invoiceForm} labelCol={{flex: '120px'}} wrapperCol={{span: 18}}>
          <Form.List name={'list'}>
            {(fields, {remove}) =>
              fields.map(({key, name}) => (
                <Card
                  key={key}
                  size={'small'}
                  extra={
                    fields.length > 1 && (
                      <CloseCircleOutlined
                        style={{fontSize: 20}}
                        onClick={() => {
                          remove(name)
                        }}
                      />
                    )
                  }
                  style={{marginTop: 12}}
                >
                  <Form.Item
                    label={'开票公司'}
                    name={[name, 'invoiceCompany']}
                    rules={[{required: true, message: '请选择开票公司'}]}
                  >
                    <Radio.Group>
                      {invoiceCompanyArr.map(item => (
                        <Radio value={item.id} key={item.id}>
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label={'客户名称'} name={[name, 'customerName']}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label={'发票类型'}
                    name={[name, 'invoiceType']}
                    rules={[{required: true, message: '请选择发票类型'}]}
                  >
                    <Select options={invoiceTypeArr} fieldNames={{value: 'id'}} placeholder={'请选择'} />
                  </Form.Item>
                  <Form.Item
                    label={'开票金额'}
                    name={[name, 'invoiceAmount']}
                    rules={[{required: true, message: '请输入开票金额'}]}
                  >
                    <InputNumber style={{width: '100%'}} placeholder={'请输入'} min={0} step={0.01} />
                  </Form.Item>
                  <Form.Item
                    label={'抬头类型'}
                    name={[name, 'titleType']}
                    rules={[{required: true, message: '请选择抬头类型'}]}
                  >
                    <Radio.Group>
                      {invoiceTitleTypeArr.map(item => (
                        <Radio value={item.id} key={item.id}>
                          {item.label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={'发票抬头'}
                    name={[name, 'invoiceTitle']}
                    rules={[{required: true, message: '请输入发票抬头'}]}
                  >
                    <Input placeholder={'请输入'} allowClear maxLength={30} />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {f => {
                      const show = f.getFieldValue(['list', name, 'show'])
                      const titleType = f.getFieldValue(['list', name, 'titleType'])
                      return (
                        <>
                          <Space>
                            <Form.Item
                              label={'纳税人识别号'}
                              name={[name, 'taxpayerIdentificationNumber']}
                              rules={[{required: titleType === '1', message: '请输入纳税人识别号'}]}
                            >
                              <Input
                                placeholder={'抬头为企业单位时请输入 '}
                                allowClear
                                style={{width: 468}}
                                maxLength={30}
                              />
                            </Form.Item>
                            <Form.Item shouldUpdate label={' '} colon={false}>
                              {f => {
                                const show = f.getFieldValue(['list', name, 'show'])
                                return (
                                  <div style={{textAlign: 'center'}}>
                                    {show ? (
                                      <UpCircleOutlined
                                        style={{fontSize: 20, color: '#00B7AE'}}
                                        onClick={() => f.setFieldValue(['list', name, 'show'], false)}
                                      />
                                    ) : (
                                      <DownCircleOutlined
                                        style={{fontSize: 20, color: '#00B7AE'}}
                                        onClick={() => f.setFieldValue(['list', name, 'show'], true)}
                                      />
                                    )}
                                  </div>
                                )
                              }}
                            </Form.Item>
                          </Space>
                          {show && (
                            <>
                              <Form.Item label={'单位地址'} name={[name, 'companyAddress']}>
                                <Input placeholder={'请输入'} allowClear />
                              </Form.Item>
                              <Form.Item label={'单位电话'} name={[name, 'companyPhone']}>
                                <Input placeholder={'请输入'} allowClear />
                              </Form.Item>
                              <Form.Item label={'开户银行'} name={[name, 'bankName']}>
                                <Input placeholder={'请输入'} allowClear maxLength={30} />
                              </Form.Item>
                              <Form.Item label={'银行账户'} name={[name, 'bankAccount']}>
                                <Input placeholder={'请输入'} allowClear maxLength={30} />
                              </Form.Item>
                              <Form.Item
                                label={'收款时间'}
                                name={[name, 'paymentTime']}
                                getValueFromEvent={(_, value) => (value ? value : undefined)}
                                getValueProps={value => ({value: value ? moment(value) : undefined})}
                              >
                                <DatePicker style={{width: '100%'}} showTime />
                              </Form.Item>
                              <Form.Item label={'联系人'} name={[name, 'contactPerson']}>
                                <Input placeholder={'请输入'} allowClear maxLength={30} />
                              </Form.Item>
                              <Form.Item label={'联系电话'} name={[name, 'contactPhone']}>
                                <Input placeholder={'请输入'} allowClear />
                              </Form.Item>
                              <Form.Item label={'邮箱'} name={[name, 'email']}>
                                <Input placeholder={'请输入'} allowClear />
                              </Form.Item>
                              <Form.Item label={'备注'} name={[name, 'remark']}>
                                <Input.TextArea rows={3} maxLength={200} allowClear showCount placeholder={'请输入'} />
                              </Form.Item>
                            </>
                          )}
                        </>
                      )
                    }}
                  </Form.Item>
                </Card>
              ))
            }
          </Form.List>
        </Form>
      </Modal>
    </>
  )
}

export const ClientBillComponent = React.forwardRef(_ClientBillComponent)
